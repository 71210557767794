import styled from 'styled-components';

import { IContainerProps, IMessageContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 6.08rem;
  right: 0;
  top: ${props => (props.menuNavbarVisible ? '6.08rem' : '0')};
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  @media (max-width: 600px) {
    top: 5.7rem;
    left: 0;
  }
`;

export const MessageContainer = styled.div<IMessageContainerProps>`
  color: ${props => props.theme.text['color-20']};
  font-size: 1.6rem;
  margin-top: ${props => (props.menuNavbarVisible ? '-7.1rem' : '0')};
  user-select: none;
`;
