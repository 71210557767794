/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';

import { logApiRequestError } from '../../Services/Utils/Utils';
import { IErrorObject, SocialNetworks } from '../../Services/Utils/types';
import SkorrApi from '../../Services/SkorrApi/new';
import {
  GetPublicUserBasicInformationWithExternalProviderResponse,
  UpdateSyncUsersInput,
  UpdateUserPublicProfilesParams,
} from '../../Services/SkorrApi/new/User/types';

const { user } = SkorrApi;

type UseGetPublicUserBasicInformationWithExternalProviderInput = {
  enabled: boolean;
  params: { username: string; socialNetwork: SocialNetworks | undefined };
  setHasError: (hasError: boolean) => any;
  setMsgError: (errorMsg: string) => any;
  t: (arg: string) => string;
};

type UseGetFacebookAccountsToSyncInput = {
  enabled: boolean;
  params: { token: string; profileId: string };
};

type UseGetTiktokAccountsToSyncInput = {
  enabled: boolean;
  params: { code: string };
};

export const useGetPublicUserBasicInformationWithExternalProvider = ({
  enabled,
  params,
  setHasError,
  setMsgError,
  t,
}: UseGetPublicUserBasicInformationWithExternalProviderInput) => {
  return useQuery(
    [
      'get-public-user-basic-information-with-external-provider',
      params.username,
      params.socialNetwork,
    ],
    async () => {
      try {
        const {
          data,
        }: {
          data: GetPublicUserBasicInformationWithExternalProviderResponse;
        } = await user.getPublicUserBasicInformationWithExternalProvider(
          params as { username: string; socialNetwork: SocialNetworks },
        );
        return data;
      } catch (error) {
        setHasError(true);
        if ((error as any).response?.data?.errorCode === 'USER_0000020') {
          setMsgError(
            t('socialAccountManagement.could-not-find-account-information'),
          );
        } else {
          setMsgError(
            t('socialAccountManagement.error-getting-social-account-data'),
          );
        }

        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: enabled ?? false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useUpdateUserPublicProfiles = () => {
  return useMutation(
    async (params: UpdateUserPublicProfilesParams) => {
      try {
        await user.updateUserPublicProfiles(params);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useGetFacebookAccountsToSync = ({
  enabled,
  params,
}: UseGetFacebookAccountsToSyncInput) => {
  return useQuery(
    ['get-facebook-accounts-to-sync', params.token, params.profileId],
    async () => {
      try {
        const result = await user.getFacebookAccountsToSync({
          token: params.token,
          profileId: params.profileId,
        });
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: enabled ?? false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetTiktokAccountsToSync = ({
  enabled,
  params,
}: UseGetTiktokAccountsToSyncInput) => {
  return useQuery(
    ['get-tiktok-accounts-to-sync', params.code],
    async () => {
      try {
        const result = await user.getTiktokAccountsToSync({
          code: params.code,
        });
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: enabled ?? false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useUpdateSyncUsers = () => {
  return useMutation(
    async (params: UpdateSyncUsersInput) => {
      try {
        await user.updateSyncUsers(params);
      } catch (error) {
        logApiRequestError(error as IErrorObject);

        if (!((error as any).response?.data?.errorCode === 'USER_0000035')) {
          throw error;
        }
      }
    },
    {
      retry: 0,
    },
  );
};
