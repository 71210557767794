import { useCallback, useState } from 'react';

import { useGetAllBenchmarks, useGetTotalBenchmarks } from '../api';

export const useBenchmarksList = () => {
  const [page, setPage] = useState(0);

  const { data, isLoading: isLoadingBenchmarks } = useGetAllBenchmarks({
    page,
    limit: 20,
  });

  const { data: totalBenchmarks } = useGetTotalBenchmarks();

  const benchmarks =
    data?.filter(benchmark => !benchmark.benchmarkInfo.deleted) ?? [];

  const hasBenchmarks = (benchmarks ?? []).length > 0;

  const handleChangePage = useCallback((pageNumber: number) => {
    setPage(pageNumber);
  }, []);

  return {
    page,
    benchmarks,
    hasBenchmarks,
    totalBenchmarks,
    handleChangePage,
    isLoadingBenchmarks,
  };
};
