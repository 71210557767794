import { ApexOptions } from 'apexcharts';
import { benchmarkColors } from 'features/Benchmark/data/constants';
import { FilteredKpisOverview } from 'features/Benchmark/types';
import { useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';

type Kpis = {
  posts: number;
  followers: number;
  engRate: number;
};

type Props = {
  title: string;
  keyValue: string;
  data: FilteredKpisOverview[];
};

const BenchmarkCompetitorsChart = ({ title, data, keyValue }: Props) => {
  const noPercentage = keyValue === 'posts';

  const options = {
    colors: benchmarkColors,
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 5,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [''],
    },
    markers: {
      size: 3,
      opacity: 0.9,
      strokeWidth: 1,
      strokeColor: '#fff',
      hover: {
        size: 7,
      },
    },
    yaxis: {
      tickAmount: 7,
      decimalsInFloat: 2,
      labels: {
        show: true,
        offsetX: 7,
        formatter(value: any) {
          return noPercentage ? value : `${value.toFixed(2)} %`;
        },
      },
    },
  };

  const stackedBarData = useCallback(() => {
    return (data || []).map(user => {
      const value =
        keyValue === 'growth'
          ? Number(user.kpis.growth_perc.followers)
          : Number(user.kpis.total[keyValue as keyof Kpis]);

      return {
        name: user.name,
        data: [value],
      };
    });
  }, [data]);

  return (
    <S.CardContainer>
      <S.CardInnerContainer>
        <S.CardTitle>{title}</S.CardTitle>
        <ReactApexChart
          options={options as ApexOptions}
          series={stackedBarData()}
          type="bar"
          height="100%"
        />
      </S.CardInnerContainer>
    </S.CardContainer>
  );
};

export default withTranslation()(BenchmarkCompetitorsChart);
