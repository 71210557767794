import styled from 'styled-components';
import { TiTimes } from 'react-icons/ti';

import { ITitleProps, ITopicsInterestContainerProps } from './types';

export const OuterContainer = styled.div``;

export const Container = styled.div`
  width: 100%;
  min-height: calc(
    100vh - ${props => props.theme['back-navigation-button-navbar'].height}
  );
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const InnerContainer = styled.div`
  width: 75%;
  border-radius: 2.4rem;
  min-height: 63rem;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  padding-bottom: 2.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 1450px) {
    width: 95%;
  }
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const HeaderDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-content-divider-color']};
`;

export const CardInnerContainer = styled.div`
  margin-top: 5rem;
  padding: 0 5.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const Title = styled.div<ITitleProps>`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  text-align: left;
`;

export const BusinessProfileCard = styled.div``;

export const BPDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${props => props.theme.text['color-18']};
`;

export const BPDetailsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
`;

export const BPDetailsInnerFirstRowContainer = styled.div`
  display: flex;

  @media (max-width: 1180px) {
    flex-direction: column;
  }
`;

export const BPTopicsInterestCountryContainer = styled.div`
  display: flex;
`;

export const BPImagePickerContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 26rem;
  margin-right: 7rem;

  @media (max-width: 1180px) {
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
  }
`;

export const BPImageContainer = styled.div`
  width: 26rem;
  height: 16rem;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export const BPImage = styled.img`
  width: 90%;
  height: 100%;
  object-fit: cover;
`;

export const BPImagePickerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BPImageCrossContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BPImageCross = styled(TiTimes)``;

export const BPNameAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: calc(100% - 33rem);

  @media (max-width: 1180px) {
    width: 100%;
  }
`;

export const BPNameAndDescriptionInnerContainer = styled.div`
  width: 100%;
`;

export const BPNameInputTitleContainer = styled.div``;

export const BPNameInputControlContainer = styled.div`
  width: 41rem;
`;

export const LegalDetailsContainer = styled.div`
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${props => props.theme.text['color-18']};
`;

export const LegalDetailsInnerContainer = styled.div`
  width: 55rem;

  @media (max-width: 930px) {
    width: 100%;
  }
`;

export const TopicsInterestContainer = styled.div<ITopicsInterestContainerProps>`
  margin-bottom: 2.7rem;
  width: ${props => props.width ?? 'unset'};
`;

export const TopicsInterestTitleContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
`;

export const BusinessProfileFooter = styled.div`
  display: flex;
  justify-content: end;
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const GenderAgeContainer = styled.div`
  display: flex;

  @media (max-width: 1335px) {
    flex-direction: column;
  }
`;

export const DateOfBirthPickerContainer = styled.div`
  padding-top: 0.6rem;
  margin-bottom: 2.6rem;
  position: relative;

  &:hover > div:first-child {
    color: #292929;
  }

  @media (max-width: 1335px) {
    margin-left: 0;
  }
`;

export const DateOfBirthPickerTitle = styled.div`
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: #676767;

  &:hover {
    color: #292929;
  }
`;

export const DateOfBirthPickerInputContainer = styled.div`
  display: inline-flex;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const DateOfBirthInvalidValueWarning = styled.div`
  position: absolute;
  top: calc(100% + 0.1rem);
  left: 0.2rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: rgb(176, 0, 32);
`;

export const DateOfBirthInputSeparator = styled.div`
  display: flex;
  align-items: center;
  width: 0.6rem;
  margin: 0 0.5rem;
`;

export const DateOfBirthInputSeparatorContent = styled.div`
  border-color: #000000;
  width: 100%;
`;

export const CountryGenderContainer = styled.div`
  display: flex;

  @media (max-width: 730px) {
    flex-direction: column;
  }
`;

export const CountryContainer = styled.div`
  width: 50%;
  padding-right: 2rem;
  margin-bottom: 2.7rem;

  &:hover > div:first-child {
    color: #292929;
  }

  @media (max-width: 730px) {
    padding-right: 0;
    width: 100%;
  }
`;

export const GenderContainer = styled.div`
  width: 50%;
  padding-left: 2rem;
  margin-bottom: 2.7rem;

  &:hover > div:first-child {
    color: #292929;
  }

  @media (max-width: 730px) {
    padding-left: 0;
    width: 100%;
  }
`;
