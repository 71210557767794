import styled from 'styled-components';

export const ListeningContent = styled.div``;

export const ListeningTable = styled.div`
  width: 100%;
  display: table;
  text-align: left;
  border-spacing: 0;
  background: #ffffff;
  border-radius: 1.2rem;
  border: solid 1px #e0e5ea;

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        opacity: 50%;
      }
    }
  }

  tr {
    height: 7.6rem;
  }

  th {
    padding: 2rem;
    color: #676767;
    font-weight: 400;
    font-size: 1.2rem;

    &:first-child {
      width: 8rem;
      text-align: center;
    }

    &:nth-child(2) {
      width: 34rem;
      max-width: 20vw;
    }
  }

  td {
    padding: 2rem;
    border-top: solid 1px #e0e5ea;

    &:first-child {
      width: 8rem;
      text-align: center;
    }

    &:nth-child(2) {
      width: 34rem;
      max-width: 20vw;
      font-weight: 600;
    }
  }
`;

export const MoreRow = styled.div`
  display: flex;
  padding: 3.2rem 0;
  align-items: center;
  justify-content: center;
`;

export const LoadMore = styled.div`
  display: flex;
  height: 4.4rem;
  padding: 0 2rem;
  color: #8492a6;
  font-weight: 500;
  font-size: 1.4rem;
  align-items: center;
  background: #eff1f6;
  border-radius: 2.2rem;
  cursor: pointer;
`;
