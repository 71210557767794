import styled from 'styled-components';

export const ListeningSearch = styled.div`
  height: 8rem;
  display: flex;
  margin: 2rem 0;
  padding: 0 3.2rem;
  position: relative;
  align-items: center;
  border-radius: 5rem;
  background-color: white;

  input {
    border: none;
    height: 4rem;
    outline: none;
    line-height: 18;
    color: #292929;
    font-weight: 400;
    font-size: 1.8rem;
    background: transparent;
    width: calc(100% - 3rem);
    transition: width 0.3s, color 0.3s;

    &::placeholder {
      color: #8492a6;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #8492a6;
      -webkit-box-shadow: 0 0 0px 40rem white inset;
    }
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }
`;
