import { ApexOptions } from 'apexcharts';
import ReactWordcloud from 'react-wordcloud';
import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
import {
  useListeningTerm,
  ListeningTermPosts,
  ListeningTermOverview,
  ListeningTermCreators,
  ListeningTermEmptyState,
} from 'features/Listening';

import * as S from './Styles';

const ListeningTerm = () => {
  const listeningTermHook = useListeningTerm();

  const {
    term,
    top5Users,
    chartData,
    chartOptions,
    handleGoBack,
    relatedTopics,
    noHashtagsData,
    wordCloudOptions,
    hashtagsByFilter,
    publicPostsByFilter,
  } = listeningTermHook;

  if (hashtagsByFilter === undefined) return <></>;

  return (
    <>
      {noHashtagsData ? (
        <ListeningTermEmptyState term={term!} />
      ) : (
        <S.MainContainer>
          <S.ContainerWrap>
            <S.TopNavbarContainer>
              <S.TopNavbarInnerContainer>
                <S.TopNavbarButton onClick={handleGoBack}>
                  <svg viewBox="0 0 20 20">
                    <path
                      d="M17.9999 10.0003C17.9999 10.5522 17.5531 11.0003 16.9999 11.0003H5.4137L10.7068 16.2934C11.0981 16.6847 11.0981 17.3166 10.7068 17.7072C10.5118 17.9022 10.2556 18.0003 9.99995 18.0003C9.74432 18.0003 9.48807 17.9022 9.29307 17.7072L2.29307 10.7072C1.90182 10.3159 1.90182 9.68406 2.29307 9.29344L9.29307 2.29344C9.68432 1.90219 10.3162 1.90219 10.7068 2.29344C11.0974 2.68469 11.0981 3.31656 10.7068 3.70719L5.4137 9.00031H16.9999C17.5531 9.00031 17.9999 9.44844 17.9999 10.0003Z"
                      fill="#292929"
                    />
                  </svg>
                </S.TopNavbarButton>
              </S.TopNavbarInnerContainer>
            </S.TopNavbarContainer>

            <ListeningTermOverview customHook={listeningTermHook} />

            <S.ChartsRowContainer>
              <S.ChartContentContainer>
                <S.ChartMetricLabel>Engagements</S.ChartMetricLabel>

                <ReactApexChart
                  type="line"
                  height="100%"
                  series={chartData().lineData}
                  options={chartOptions as ApexOptions}
                />
              </S.ChartContentContainer>
            </S.ChartsRowContainer>

            <S.FullWidthRow>
              <ListeningTermCreators users={top5Users} />

              <S.ChartContentContainer customWidth={100}>
                <S.ChartMetricLabel>Related Topics</S.ChartMetricLabel>

                <S.WordcloudContainer>
                  <ReactWordcloud
                    words={relatedTopics ?? []}
                    options={wordCloudOptions as any}
                  />
                </S.WordcloudContainer>
              </S.ChartContentContainer>
            </S.FullWidthRow>

            <S.FullWidthRow>
              <S.FilterOptionsContainer>
                <S.FilterTagsContainer>
                  <S.Tag active={true}>Posts</S.Tag>
                </S.FilterTagsContainer>
              </S.FilterOptionsContainer>
            </S.FullWidthRow>

            <ListeningTermPosts posts={publicPostsByFilter?.posts ?? []} />
          </S.ContainerWrap>
        </S.MainContainer>
      )}
    </>
  );
};

export default withTranslation()(ListeningTerm);
