/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  useState,
  useEffect,
  useMemo,
  useContext,
  useLayoutEffect,
} from 'react';
import { useTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from 'react-query';

import * as S from './Styles';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import StyledButton from '../../components/shared/Button/Button';
import { getThemeStyles } from '../../css/ThemeGlobal';
import {
  useCreateBusinessProfile,
  useUpdateBusinessProfile,
  useDeleteBusinessProfile,
} from './RQCustomHooks';
import {
  useActivateBrandProfile,
  useGetBusinessProfile,
  useGetToken,
} from '../../Services/Utils/CustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { valueEmpty } from '../../validations/validationFunctions';
import { IBusinessProfileProps } from './types';
import { isAuthenticated } from '../../Services/Utils/Utils';
import { IFile } from '../../components/shared/FilePickerV2/types';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import { FileSourceEnum } from '../../Services/SkorrApi/new/Files/types';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { OuterContext } from '../../components/MenuNavbarV2/MenuNavbarV2';
import { useIsOperatorOrPartnerAdmin } from '../SignIn/RQCustomHooks';
import NoPermissionToAccessPageWarning from '../../components/shared/NoPermissionToAccessPageWarning/NoPermissionToAccessPageWarning';

const BusinessProfile = (props: IBusinessProfileProps) => {
  const [bpImageSrc, setBPImageSrc] = useState('');
  const [bpName, setBPName] = useState('');
  const [isBpNameInputFocused, setIsBpNameInputFocused] =
    useState<boolean>(false);
  const [hasBpNameBeenChanged, setHasBpNameBeenChanged] =
    useState<boolean>(false);
  const [forceBpNameErrorDisplay, setForceBpNameErrorDisplay] = useState<
    string | undefined
  >();
  const [bpDescription, setBPDescription] = useState('');
  const [isGetBusinessProfileEnabled, setIsGetBusinessProfileEnabled] =
    useState<boolean>(false);
  const [isGetTokenEnabled, setIsGetTokenEnabled] = useState(false);
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [hasSaveButtonBeenClicked, setHasSaveButtonBeenClicked] =
    useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [files, setFiles] = useState<IFile[]>([]);
  const { id: businessProfileId } = useParams();
  const getBusinessProfileQueryKey = useMemo(() => new Date().getTime(), []);
  const [filePickerComponenKey, setFilePickerComponentKey] = useState(
    new Date().getTime(),
  );
  const {
    isLoading: isCreateBusinessProfileLoading,
    isSuccess: isCreateBusinessProfileSuccess,
    isError: isCreateBusinessProfileError,
    mutate: createBusinessProfile,
  } = useCreateBusinessProfile();
  const {
    isLoading: isUpdateBusinessProfileLoading,
    isSuccess: isUpdateBusinessProfileSuccess,
    isError: isUpdateBusinessProfileError,
    mutate: updateBusinessProfile,
  } = useUpdateBusinessProfile();
  const {
    isLoading: isActivateBusinessProfileLoading,
    isSuccess: isActivateBusinessProfileSuccess,
    isError: isActivateBusinessProfileError,
    mutate: activateBusinessProfile,
  } = useActivateBrandProfile();
  const {
    isLoading: isDeleteBusinessProfileLoading,
    isSuccess: isDeleteBusinessProfileSuccess,
    isError: isDeleteBusinessProfileError,
    mutate: deleteBusinessProfile,
  } = useDeleteBusinessProfile();
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const {
    data: partnerBusinessProfile,
    isFetching: isGetPartnerBusinessProfileFetching,
    isSuccess: isGetPartnerBusinessProfileSuccess,
    isError: isGetPartnerBusinessProfileError,
    refetch: refetchGetBusinessProfile,
  } = useGetBusinessProfile(
    isGetBusinessProfileEnabled &&
      isSuccessIsOperatorOrPartnerAdmin &&
      isOperatorOrPartnerAdmin,
    businessProfileId,
    String(getBusinessProfileQueryKey),
  );
  const {
    isFetching: isFetchingGetToken,
    isSuccess: isSuccessGetToken,
    refetch: refetchGetToken,
  } = useGetToken(isGetTokenEnabled, isAuthenticated()?.agentEmail ?? '');
  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const themeGlobal = useTheme();
  const queryClient = useQueryClient();
  const context = useContext(OuterContext);
  const initialBusinessProfileImageUrl = useMemo(
    () => [bpImageSrc],
    [bpImageSrc, filePickerComponenKey],
  );

  useLayoutEffect(() => {
    if (location.pathname.includes('/business-profile/create')) {
      setBPName('');
      setBPDescription('');
      setBPImageSrc('');
      setFiles([]);
      setFilePickerComponentKey(new Date().getTime());
    } else if (location.pathname.includes('/business-profile/edit')) {
      setBPImageSrc('');
      setFilePickerComponentKey(new Date().getTime());
      setFiles([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (businessProfileId) {
      refetchGetBusinessProfile();
    }
  }, [businessProfileId]);

  useEffect(() => {
    if (isGetPartnerBusinessProfileFetching) {
      setBPImageSrc('');
      setFilePickerComponentKey(new Date().getTime());
    }
  }, [isGetPartnerBusinessProfileFetching]);

  useEffect(() => {
    if (
      businessProfileId &&
      partnerBusinessProfile &&
      isGetPartnerBusinessProfileSuccess
    ) {
      setFilePickerComponentKey(new Date().getTime());
      setBPName(partnerBusinessProfile.name);
      setBPDescription(partnerBusinessProfile.description);
      if (partnerBusinessProfile.displayPicture) {
        setBPImageSrc(partnerBusinessProfile.displayPicture);
      }
      setIsGetBusinessProfileEnabled(false);
    }
  }, [
    isGetPartnerBusinessProfileSuccess,
    businessProfileId,
    partnerBusinessProfile,
  ]);

  useEffect(() => {
    if (isBpNameInputFocused) {
      setForceBpNameErrorDisplay('');
    }
  }, [isBpNameInputFocused]);

  useEffect(() => {
    if (isCreateBusinessProfileSuccess) {
      setSuccessfulActionNotificationMessage(
        props.t('businessProfile.brandProfileCreated'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
        navigate(`/business-profiles`);
      }, 2000);
      context.refetchBusinessProfilesByPartnerId();
    }
  }, [isCreateBusinessProfileSuccess]);

  useEffect(() => {
    if (isUpdateBusinessProfileSuccess) {
      if (isAuthenticated()?.businessProfileId === businessProfileId) {
        const invalidateTokenData = async () => {
          await queryClient.invalidateQueries({ queryKey: ['tokenData'] });
        };

        invalidateTokenData();

        setTimeout(() => {
          refetchGetToken();
        }, 2000);
      }

      context.refetchBusinessProfilesByPartnerId();
      setSuccessfulActionNotificationMessage(
        props.t('businessProfile.brandProfileUpdated'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
    }
  }, [isUpdateBusinessProfileSuccess]);

  useEffect(() => {
    if (isSuccessGetToken && !isFetchingGetToken) {
      setIsGetTokenEnabled(false);
      setTimeout(() => context.refreshNavbar(), 1000);
    }
  }, [isSuccessGetToken, isFetchingGetToken]);

  useEffect(() => {
    if (isDeleteBusinessProfileSuccess) {
      setSuccessfulActionNotificationMessage(
        props.t('businessProfile.brandProfileDeleted'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      context.refetchBusinessProfilesByPartnerId();
      setTimeout(() => navigate('/business-profiles'), 2000);
    }
  }, [isDeleteBusinessProfileSuccess]);

  useEffect(() => {
    if (isActivateBusinessProfileSuccess) {
      setSuccessfulActionNotificationMessage(
        props.t('businessProfile.brandProfileActivated'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      setTimeout(() => context.refreshNavbar(), 1000);
    }
  }, [isActivateBusinessProfileSuccess]);

  useEffect(() => {
    if (isGetPartnerBusinessProfileError) {
      setHasError(true);
      setMsgError(props.t('businessProfile.error-get-business-profile-data'));
      setIsGetBusinessProfileEnabled(false);
    }
  }, [isGetPartnerBusinessProfileError]);

  useEffect(() => {
    if (isCreateBusinessProfileError) {
      setHasError(true);
      setMsgError(props.t('businessProfile.couldNotCreateBrandProfile'));
    }
  }, [isCreateBusinessProfileError]);

  useEffect(() => {
    if (isUpdateBusinessProfileError) {
      setHasError(true);
      setMsgError(props.t('businessProfile.couldNotUpdateBrandProfile'));
    }
  }, [isUpdateBusinessProfileError]);

  useEffect(() => {
    if (isActivateBusinessProfileError) {
      setHasError(true);
      setMsgError(props.t('businessProfile.couldNotActivateBrandProfile'));
    }
  }, [isActivateBusinessProfileError]);

  useEffect(() => {
    if (isDeleteBusinessProfileError) {
      setHasError(true);
      setMsgError(props.t('businessProfile.couldNotDeleteBrandProfile'));
    }
  }, [isDeleteBusinessProfileError]);

  useEffect(() => {
    if (isErrorIsOperatorOrPartnerAdmin) {
      setHasError(true);
      setMsgError(props.t('businessProfile.error-get-business-profile-data'));
      setIsOperatorOrPartnerAdminEnabled(false);
    }
  }, [isErrorIsOperatorOrPartnerAdmin]);

  const saveButtonClicked = () => {
    setLoadingActionNotificationMessage(
      !businessProfileId
        ? props.t('businessProfile.creatingBrandProfile')
        : props.t('businessProfile.savingChanges'),
    );

    if (!isSaveButtonEnabled()) {
      setForceBpNameErrorDisplay(
        props.t('businessProfile.businessProfileNameIsRequired'),
      );
    } else {
      const args: any = {};
      args.name = bpName;
      args.description = bpDescription;
      args.displayPicture = bpImageSrc;
      args.email = `dummyEmail-${uuidv4()}@email.com`;
      args.socialTokens = [];
      args.partnerId = isAuthenticated()?.partnerId;
      let businessProfilePictureFormData;
      if (files[0]?.data) {
        businessProfilePictureFormData = new FormData();
        businessProfilePictureFormData.append('file', files[0]!.data);
        businessProfilePictureFormData.append(
          'source',
          FileSourceEnum.BRAND_PICTURE,
        );
        businessProfilePictureFormData.append(
          'id',
          isAuthenticated()!.businessProfileId,
        );
      }
      if (businessProfileId) {
        args.businessProfileId = businessProfileId;
        args.userId = partnerBusinessProfile!.userId;
        updateBusinessProfile({
          businessProfileUpdateData: args,
          businessProfilePictureData: businessProfilePictureFormData,
          businessProfileInitialPicture: bpImageSrc,
        });
      } else {
        createBusinessProfile({
          businessProfileCreationData: args,
          businessProfilePictureData: businessProfilePictureFormData,
        });
      }
    }

    setHasSaveButtonBeenClicked(true);
  };

  const cancelButtonClicked = () => {
    navigate(-1);
  };

  const getLoaderMessage = () => {
    if (isGetPartnerBusinessProfileFetching) {
      return props.t('businessProfile.loading-business-profile-data');
    }
    return '';
  };

  const isSaveButtonEnabled = () => {
    if (!valueEmpty(bpName)) return true;
    return false;
  };

  const errorModalCloseButtonClicked = () => {
    if (isGetPartnerBusinessProfileError) {
      setIsGetBusinessProfileEnabled(true);
    }
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(true);
    }
    setHasError(false);
  };

  const getPageTitle = () => {
    if (location.pathname.includes('business-profile/edit')) {
      return props.t('businessProfile.editBrandProfile');
    }

    return props.t('businessProfile.createBrandProfile');
  };

  return (
    <S.OuterContainer>
      <Loader
        show={
          isGetPartnerBusinessProfileFetching ||
          isFetchingIsOperatorOrPartnerAdmin
        }
        message={getLoaderMessage()}
      />

      <S.Container>
        <S.InnerContainer>
          <S.BusinessProfileCard>
            <S.HeaderOuterContainer>
              <S.HeaderContainer>
                <S.HeaderBackArrowContainer
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <BackArrowIcon width={'4rem'} height={'4rem'} />
                </S.HeaderBackArrowContainer>
                <S.HeaderTitleContainer>
                  {getPageTitle()}
                </S.HeaderTitleContainer>
                <S.HiddenHeaderElement />
              </S.HeaderContainer>
              <S.HeaderDivider />
            </S.HeaderOuterContainer>

            {isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin ? (
              <S.CardInnerContainer>
                <S.BPDetailsContainer>
                  <S.BPDetailsInnerContainer>
                    <S.BPDetailsInnerFirstRowContainer>
                      <S.BPImagePickerContainer>
                        <S.BPImagePickerButtonContainer>
                          <FilePickerV2
                            handleChange={(files: IFile[]) => {
                              setFiles(files);
                              if (!files.length) {
                                setBPImageSrc('');
                              } else {
                                setBPImageSrc(files[0] as unknown as string);
                              }
                            }}
                            text={props.t('additionalDataStep.selectPicture')}
                            showSelectedFiles={false}
                            single={true}
                            type={'images'}
                            marginTop={'1rem'}
                            initialImages={initialBusinessProfileImageUrl}
                            imageContainerWidth={'16.8rem'}
                            imageContainerHeight={'13.8rem'}
                            key={filePickerComponenKey}
                          />
                        </S.BPImagePickerButtonContainer>
                      </S.BPImagePickerContainer>

                      <S.BPNameAndDescriptionContainer>
                        <S.BPNameAndDescriptionInnerContainer>
                          <InputControlV2
                            value={bpName}
                            onChange={(inputValue: string) => {
                              setBPName(inputValue);
                              setHasBpNameBeenChanged(true);
                            }}
                            title={props.t('generic.name')}
                            inputFieldContainerWithTitleWidth={'100%'}
                            placeholder={props.t(
                              'businessProfile.insertBusinessProfileName',
                            )}
                            setFocused={focused =>
                              setIsBpNameInputFocused(focused)
                            }
                            setBlurred={blurred => {
                              setIsBpNameInputFocused(!blurred);
                              if (
                                !bpName.length &&
                                (hasBpNameBeenChanged ||
                                  hasSaveButtonBeenClicked)
                              ) {
                                setForceBpNameErrorDisplay(
                                  props.t(
                                    'businessProfile.businessProfileNameIsRequired',
                                  ),
                                );
                              }
                            }}
                            inputTitleMarginBottom={'1.2rem'}
                            inputFieldContainerMarginBottom={'2.7rem'}
                            forceErrorDisplay={forceBpNameErrorDisplay}
                            forceBorderColorRed={Boolean(
                              forceBpNameErrorDisplay,
                            )}
                          />

                          <InputControlV2
                            value={bpDescription}
                            onChange={(inputValue: string) =>
                              setBPDescription(inputValue)
                            }
                            title={props.t('generic.description')}
                            inputFieldContainerWithTitleWidth={'100%'}
                            placeholder={props.t(
                              'placeholder.insertDescription',
                            )}
                            type={'textarea'}
                            inputFieldWidth={'100%'}
                            inputFieldHeight={'15rem'}
                            inputFieldLineHeight={'2.5rem'}
                            inputTitleMarginBottom={'1.2rem'}
                            inputFieldContainerMarginBottom={'2.7rem'}
                          />
                        </S.BPNameAndDescriptionInnerContainer>
                      </S.BPNameAndDescriptionContainer>
                    </S.BPDetailsInnerFirstRowContainer>

                    {businessProfileId &&
                      isAuthenticated()?.businessProfileId !==
                        businessProfileId && (
                        <S.BPDetailsInnerSecondRowContainer>
                          <StyledButton
                            onClick={() => {
                              setLoadingActionNotificationMessage(
                                props.t(
                                  'businessProfile.activatingBrandProfile',
                                ),
                              );
                              activateBusinessProfile(businessProfileId);
                            }}
                          >
                            {props.t('generic.activate')}
                          </StyledButton>

                          <StyledButton
                            onClick={() => {
                              setLoadingActionNotificationMessage(
                                props.t('businessProfile.deletingBrandProfile'),
                              );
                              deleteBusinessProfile(businessProfileId);
                            }}
                            themeStyles={getThemeStyles(
                              ['button', 'danger'],
                              themeGlobal,
                            )}
                          >
                            {props.t('generic.delete')}
                          </StyledButton>
                        </S.BPDetailsInnerSecondRowContainer>
                      )}
                  </S.BPDetailsInnerContainer>
                </S.BPDetailsContainer>
              </S.CardInnerContainer>
            ) : isSuccessIsOperatorOrPartnerAdmin &&
              !isOperatorOrPartnerAdmin ? (
              <NoPermissionToAccessPageWarning containerHeight="auto" />
            ) : null}

            {isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin && (
              <S.BusinessProfileFooter>
                <StyledButton
                  themeStyles={getThemeStyles(
                    ['button', 'secondary-1'],
                    themeGlobal,
                  )}
                  onClick={() => cancelButtonClicked()}
                >
                  {props.t('generic.cancel')}
                </StyledButton>

                <StyledButton
                  onClick={() => saveButtonClicked()}
                  cursorOnDisable={'default'}
                  blockHoverStylesOnDisable={true}
                  pointerEventsOnDisable={'all'}
                  disabledNotNative={!isSaveButtonEnabled()}
                >
                  {businessProfileId
                    ? props.t('generic.save')
                    : props.t('generic.create')}
                </StyledButton>
              </S.BusinessProfileFooter>
            )}
          </S.BusinessProfileCard>
        </S.InnerContainer>
      </S.Container>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={
          isCreateBusinessProfileLoading ||
          isUpdateBusinessProfileLoading ||
          isDeleteBusinessProfileLoading ||
          isActivateBusinessProfileLoading
        }
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.OuterContainer>
  );
};

export default withTranslation()(BusinessProfile);
