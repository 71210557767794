import styled from 'styled-components';

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 1rem 4rem;
  align-items: center;
  flex-direction: column;
  transition: all 0.08s ease;
`;

export const ContainerWrap = styled.div`
  height: 100%;
  width: 120rem;
  max-width: 100%;
`;

export const TopNavbarContainer = styled.div`
  gap: 1.6rem;
  display: flex;
  margin: 1.6rem 0;
`;

export const TopNavbarInnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TopNavbarButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const EmptyState = styled.div`
  height: 80%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyStateIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;

  svg {
    width: 10rem;
    height: 10rem;
    vertical-align: middle;
  }
`;

export const EmptyStateTitle = styled.h2`
  color: #292929;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const EmptyStateDescription = styled.p`
  display: flex;
  color: #676767;
  font-weight: 400;
`;
