import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const UserCardContainer = styled.div<{
  borderColor: string;
  isLastItem: boolean;
}>`
  width: 25rem;
  padding: 1.2rem;
  border-radius: 0.6rem;
  background-color: white;
  border: 0.1rem solid #dbdbdb;
  border-left: 1rem solid ${({ borderColor }) => borderColor};
  margin-right: ${({ isLastItem }) => (isLastItem ? 0 : '1.2rem')};
`;

export const TopSectionContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  margin-right: 1rem;
  align-items: center;
`;

export const UserAvatarInfoContainer = styled.div`
  display: flex;
  border-radius: 50%;
  align-items: flex-end;

  div {
    padding: 0.2rem;
    margin-left: -1rem;
    border-radius: 0.6rem;
    margin-bottom: -0.4rem;
    background-color: white;
  }
`;

export const UserAvatarInfoImg = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
`;

export const UserInfoContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
`;

export const UserInfoName = styled.h4`
  width: 15rem;
  color: #292929;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserInfoNetwork = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
`;

export const DividerLine = styled.div`
  opacity: 50%;
  height: 0.1rem;
  margin: 1.2rem 0;
  background-color: #dbdbdb;
`;

export const UserMetricsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const UserMetricContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const UserMetricValue = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
`;

export const UserMetricLabel = styled.span`
  color: #676767;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.2rem;
`;
