import * as S from './Styles';

type Props = {
  customHook: any;
};

export function ListeningSearch({ customHook }: Props) {
  const { searchWord, handleChangeSearchWord, handleGoToTermAnalytics } =
    customHook;

  return (
    <S.ListeningSearch>
      <input
        type="text"
        name="searchWord"
        value={searchWord}
        placeholder="Search anything..."
        onChange={e => handleChangeSearchWord(e.target.value)}
        onKeyDown={event => {
          if (event.code === 'Enter') {
            handleGoToTermAnalytics();
          }
        }}
      />

      <svg viewBox="0 0 20 20">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.2369 17.2128L14.8131 13.789C15.878 12.4962 16.5178 10.84 16.5178 9.03428C16.5178 4.90128 13.1674 1.55078 9.03434 1.55078C4.90128 1.55078 1.5509 4.90128 1.5509 9.03434C1.5509 13.1674 4.90134 16.5178 9.0344 16.5178C10.8401 16.5178 12.4963 15.878 13.7891 14.8131L17.2129 18.2369C17.4957 18.5197 17.9542 18.5197 18.237 18.2369C18.5198 17.9541 18.5198 17.4955 18.2369 17.2128ZM2.99928 9.03434C2.99928 5.70128 5.70128 2.99928 9.03434 2.99928C12.3674 2.99928 15.0694 5.70128 15.0694 9.03434C15.0694 12.3674 12.3674 15.0694 9.03434 15.0694C5.70128 15.0694 2.99928 12.3674 2.99928 9.03434Z"
          fill="#8492A6"
        />
      </svg>
    </S.ListeningSearch>
  );
}
