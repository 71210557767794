import { SearchUserBenchmarkResponse } from 'features/Benchmark/types';
import { withTranslation } from 'react-i18next';
import { getSocialNetworkIcon } from 'Services/Utils/Utils';

import * as S from './Styles';

type Props = {
  isLoadingUsers: boolean;
  selectedUsers: SearchUserBenchmarkResponse[];
  users: SearchUserBenchmarkResponse[] | undefined;
  handleSelectUsers: (user: SearchUserBenchmarkResponse) => void;
};

const SubmitBenchmarkUsersList = ({
  users,
  selectedUsers,
  isLoadingUsers,
  handleSelectUsers,
}: Props) => {
  return (
    <S.UsersListContainer>
      {selectedUsers.length > 0 && (
        <>
          <S.SelectedUsersText>
            {selectedUsers.length} profiles <p>selected</p>
          </S.SelectedUsersText>

          {selectedUsers.map(user => (
            <S.ListItemContainer onClick={() => handleSelectUsers(user)}>
              <S.ListItemLeftSide>
                <S.ListItemAvatarContainer>
                  <S.ListItemAvatarImg src={user.displayPicture} />
                  {getSocialNetworkIcon({
                    socialNetwork: user.socialNetwork ?? '',
                    width: '2rem',
                    height: '2rem',
                    gradientColored: true,
                  })}
                </S.ListItemAvatarContainer>

                <S.ListItemUserInfo>
                  <S.ListItemTitle>@{user.username}</S.ListItemTitle>
                  <S.ListItemDate>{user.socialNetwork}</S.ListItemDate>
                </S.ListItemUserInfo>
              </S.ListItemLeftSide>

              <S.ListItemRightSide>
                <svg viewBox="0 0 32 32">
                  <path
                    d="M16 2.667c-7.364 0-13.333 5.969-13.333 13.333s5.969 13.333 13.333 13.333 13.333-5.969 13.333-13.333-5.969-13.333-13.333-13.333zM16 27.333c-6.259 0-11.333-5.075-11.333-11.333s5.075-11.333 11.333-11.333 11.333 5.075 11.333 11.333-5.075 11.333-11.333 11.333zM8.457 17c-0.552 0-1-0.448-1-1s0.448-1 1-1h15.085c0.552 0 1 0.448 1 1s-0.448 1-1 1h-15.085z"
                    fill="#292929"
                  />
                </svg>
              </S.ListItemRightSide>
            </S.ListItemContainer>
          ))}
        </>
      )}

      {selectedUsers.length > 0 && <S.ListItemDivider />}

      {isLoadingUsers ? (
        <S.UsersListLoadingText>Loading users...</S.UsersListLoadingText>
      ) : (
        <>
          {users !== undefined && (
            <>
              <S.FoundUsersText>
                {users.length > 0 ? 'Search results' : 'No results found'}
              </S.FoundUsersText>

              {users.map(user => {
                let diffNetwork;

                const isChecked = selectedUsers.some(
                  item => item.publicUserId === user.publicUserId,
                );

                if (selectedUsers.length > 0) {
                  diffNetwork =
                    user.socialNetwork !== selectedUsers[0].socialNetwork;
                } else {
                  diffNetwork = false;
                }

                return (
                  <S.ListItemOutContainer
                    disable={
                      (!isChecked && selectedUsers.length >= 5) || diffNetwork
                    }
                  >
                    <S.ListItemContainer
                      onClick={() => handleSelectUsers(user)}
                      disable={
                        (!isChecked && selectedUsers.length >= 5) || diffNetwork
                      }
                    >
                      <S.ListItemLeftSide>
                        <S.ListItemAvatarContainer>
                          <S.ListItemAvatarImg src={user.displayPicture} />
                          {getSocialNetworkIcon({
                            socialNetwork: user.socialNetwork ?? '',
                            width: '2rem',
                            height: '2rem',
                            gradientColored: true,
                          })}
                        </S.ListItemAvatarContainer>

                        <S.ListItemUserInfo>
                          <S.ListItemTitle>@{user.username}</S.ListItemTitle>
                          <S.ListItemDate>{user.socialNetwork}</S.ListItemDate>
                        </S.ListItemUserInfo>
                      </S.ListItemLeftSide>

                      <S.ListItemRightSide>
                        {isChecked ? (
                          <svg viewBox="0 0 32 32">
                            <path
                              d="M16 2.667c-7.364 0-13.333 5.969-13.333 13.333s5.969 13.333 13.333 13.333 13.333-5.969 13.333-13.333-5.969-13.333-13.333-13.333zM23.363 12.717l-8.251 8c-0.388 0.376-1.004 0.376-1.392 0l-3.749-3.636c-0.396-0.384-0.407-1.017-0.021-1.413 0.384-0.396 1.017-0.407 1.413-0.021l3.053 2.961 7.553-7.325c0.396-0.384 1.029-0.375 1.413 0.021 0.387 0.396 0.377 1.029-0.020 1.413z"
                              fill="#5870F6"
                            />
                          </svg>
                        ) : (
                          <svg viewBox="0 0 32 32">
                            <path
                              d="M16 2.667c-7.364 0-13.333 5.969-13.333 13.333s5.969 13.333 13.333 13.333 13.333-5.969 13.333-13.333-5.969-13.333-13.333-13.333zM16 27.333c-6.259 0-11.333-5.075-11.333-11.333s5.075-11.333 11.333-11.333 11.333 5.075 11.333 11.333-5.075 11.333-11.333 11.333zM24.543 16c0 0.552-0.448 1-1 1h-6.543v6.543c0 0.552-0.448 1-1 1s-1-0.448-1-1v-6.543h-6.543c-0.552 0-1-0.448-1-1s0.448-1 1-1h6.543v-6.543c0-0.552 0.448-1 1-1s1 0.448 1 1v6.543h6.543c0.552 0 1 0.448 1 1z"
                              fill={
                                selectedUsers.length < 5 && !diffNetwork
                                  ? '#292929'
                                  : 'transparent'
                              }
                            />
                          </svg>
                        )}
                      </S.ListItemRightSide>
                    </S.ListItemContainer>
                  </S.ListItemOutContainer>
                );
              })}
            </>
          )}
        </>
      )}
    </S.UsersListContainer>
  );
};

export default withTranslation()(SubmitBenchmarkUsersList);
