import CheckMarkIcon from 'assets/iconComponents/CheckMarkIcon';
import Button from 'components/shared/Button/Button';
import GenericModal from 'components/shared/GenericModal/GenericModal';
import NotifyCustom from 'components/shared/NotifyCustom/NotifyCustom';
import { NotifyInnerContainer } from 'components/shared/NotifyCustom/Styles';
import {
  BenchmarkCompetitorsChart,
  BenchmarkOverviewCompetitors,
  BenchmarkPostsFeed,
  useViewBenchmark,
} from 'features/Benchmark';
import { BenchmarkOutput } from 'features/Benchmark/types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import * as S from './Styles';

const ViewBenchmark = () => {
  const navigate = useNavigate();
  const themeGlobal = useTheme();

  const location = useLocation();
  const { state } = location || {};
  const { benchmarkData }: { benchmarkData: BenchmarkOutput } = state;

  const {
    handleDeleteBenchmark,
    showConfirmDeleteModal,
    handleConfirmDeleteModal,
    isSuccessDeleteBenchmark,
    handleGoToUpdateBenchmark,
  } = useViewBenchmark();

  const startDate = moment(benchmarkData.benchmarkInfo.createdAt)
    .subtract(1, 'month')
    .utc()
    .format('ll');
  const endDate = moment(benchmarkData.benchmarkInfo.createdAt)
    .utc()
    .format('ll');
  const numDays = moment(endDate).diff(moment(startDate), 'days');

  return (
    <S.MainContainer>
      <S.TopContainer>
        <S.GoBackButton onClick={() => navigate(-1)}>
          <svg viewBox="0 0 20 20">
            <path
              d="M17.9999 10.0003C17.9999 10.5522 17.5531 11.0003 16.9999 11.0003H5.4137L10.7068 16.2934C11.0981 16.6847 11.0981 17.3166 10.7068 17.7072C10.5118 17.9022 10.2556 18.0003 9.99995 18.0003C9.74432 18.0003 9.48807 17.9022 9.29307 17.7072L2.29307 10.7072C1.90182 10.3159 1.90182 9.68406 2.29307 9.29344L9.29307 2.29344C9.68432 1.90219 10.3162 1.90219 10.7068 2.29344C11.0974 2.68469 11.0981 3.31656 10.7068 3.70719L5.4137 9.00031H16.9999C17.5531 9.00031 17.9999 9.44844 17.9999 10.0003Z"
              fill="#292929"
            />
          </svg>
        </S.GoBackButton>
        <S.MainTitle>{benchmarkData.benchmarkInfo.name}</S.MainTitle>

        <div>
          <Button
            marginRight="1rem"
            borderRadius="0.6rem"
            onClick={() => handleGoToUpdateBenchmark(benchmarkData)}
          >
            Edit
          </Button>

          <Button
            borderRadius="0.6rem"
            backgroundColor="#B00020"
            hoverBackgroundColor="#B00"
            onClick={handleConfirmDeleteModal}
          >
            Delete
          </Button>
        </div>
      </S.TopContainer>

      <BenchmarkOverviewCompetitors benchmarkConfig={benchmarkData} />

      <S.Timeframe>
        <p>
          {startDate} - {endDate} ({numDays} days)
        </p>
      </S.Timeframe>

      <BenchmarkCompetitorsChart
        keyValue="growth"
        title="Followers growth"
        data={benchmarkData.kpisOverview}
      />

      <BenchmarkCompetitorsChart
        keyValue="engRate"
        title="Engagement rate"
        data={benchmarkData.kpisOverview}
      />

      <BenchmarkCompetitorsChart
        keyValue="posts"
        title="Posts"
        data={benchmarkData.kpisOverview}
      />

      <BenchmarkPostsFeed posts={benchmarkData.postsFeed} />

      <GenericModal
        modalWidth={'40%'}
        isCancelable={true}
        show={showConfirmDeleteModal}
        title={'Confirm delete benchmark'}
        subtitle="Are you sure you want to delete this benchmark?"
        primaryButtonText={'Delete'}
        primaryButtonLeftMargin="1rem"
        primaryButtonAction={() => {
          handleConfirmDeleteModal();
          handleDeleteBenchmark(benchmarkData.benchmarkInfo.id);
        }}
        cancelAction={handleConfirmDeleteModal}
      />

      <NotifyCustom
        location={'top'}
        show={isSuccessDeleteBenchmark}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          Benchmark deleted successfully
        </NotifyInnerContainer>
      </NotifyCustom>
    </S.MainContainer>
  );
};

export default withTranslation()(ViewBenchmark);
