/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable no-empty-function */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable no-param-reassign */
/* eslint-disable no-self-assign */
/* eslint-disable vars-on-top */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable import/extensions */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { Calendar } from 'react-date-range';
import { useQueryClient } from 'react-query';

import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import Button from '../../components/shared/Button/Button';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import LoaderLocal from '../../components/shared/LoaderLocal/LoaderLocal';
import * as S from './Styles';
import {
  CustomPartnerCreated,
  GeneratePortfolioUrlCustomHookMediaKit,
  GeneratePortfolioUrlCustomHookParams,
  ICreatePortfolioConfigurationProps,
  MediaKitCustomizableView,
  portfolioColorValues,
  PortfolioLayoutTypes,
  PortfolioSettings,
} from './types';
import { getThemeStyles } from '../../css/ThemeGlobal';
import InfoV2Icon from '../../assets/iconComponents/InfoIconV2';
import { useGetExternalMediaKitConfigurationsByUserId } from '../MediaKitConfigurationsList/RQCustomHooks';
import {
  getSocialNetworkIcon,
  isAuthenticated,
  isUrlValid,
} from '../../Services/Utils/Utils';
import VerifiedIconV1 from '../../assets/iconComponents/VerifiedIconV1';
import {
  useGetAllBrandPictures,
  useGetLocations,
  useGetPublicUserById,
  useGetSelfUser,
  useGetTagsByKey,
} from '../../Services/Utils/CustomHooks';
import {
  ExternalMediaKitConfiguration,
  ExternalMediaKitConfigurationTypes,
} from '../../Services/SkorrApi/new/ExternalMediaKit/types';
import PlusIconV1 from '../../assets/iconComponents/PlusIconV1';
import {
  getShortenedMonthResourceKey,
  getTimePeriodAgoLabel,
} from '../../Services/Utils/dateUtils';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import EmptyContentIconV1 from '../../assets/iconComponents/EmptyContentIconV1';
import CrossIcon_V2 from '../../assets/iconComponents/CrossIcon_V2';
import EditIconV2 from '../../assets/iconComponents/EditIconV2';
import TrashCanIconV1 from '../../assets/iconComponents/TrashCanIconV1';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import { IFile } from '../../components/shared/FilePickerV2/types';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import ToggleButton from '../../components/shared/ToggleButton/ToggleButton';
import CheckBox from '../../components/shared/Checkbox/CheckBox';
import UploadIconV1 from '../../assets/iconComponents/UploadIconV1';
import {
  DEFAULT_USER_AVATAR,
  TCountriesAvailableValues,
} from '../../Services/Utils/types';
import PortfolioHorizontalLayoutTypeIcon from '../../assets/iconComponents/PortfolioHorizontalLayoutTypeIcon';
import PortfolioVerticalLayoutTypeIcon from '../../assets/iconComponents/PortfolioVerticalLayoutTypeIcon';
import { TagTopic } from '../../Services/SkorrApi/new/Generic/types';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import CopyIcon from '../../assets/iconComponents/CopyIcon';
import MagnifyingGlassIcon from '../../assets/iconComponents/MagnifyingGlassIcon';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import {
  useDeleteUserBrandPicture,
  useGeneratePortfolioUrl,
  useGetExternalMediaKitConfigurationById,
  useGetPortfolioByBrandPicture,
  useGetUserBrandPictures,
  useUpdatePortfolioById,
  useUploadUserBrandPicture,
} from './RQCustomHooks';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import PlusIconV2 from '../../assets/iconComponents/PlusIconV2';

const ClickOutHandler = require('react-onclickout');
const CopyToClipboard = require('react-copy-to-clipboard');

const BROWSER_LANGUAGE = (window.navigator.language || 'en').split('-')[0];

export const copySmartLinkButtonClicked = (event: any) => {
  event.stopPropagation();
  const notification = event.currentTarget.querySelector('span');
  notification.style.opacity = '1';
  setTimeout(() => {
    notification.style.opacity = '0';
  }, 600);
};

const CreatePortfolioConfiguration: React.FC<
  ICreatePortfolioConfigurationProps
> = (props: ICreatePortfolioConfigurationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const editMode = useMemo(() => location.pathname.includes('edit'), []);
  const themeGlobal = useTheme();
  const queryClient = useQueryClient();
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] = useState(true);
  const [
    isGetConfigurationsByUserIdEnabled,
    setIsGetConfigurationsByUserIdEnabled,
  ] = useState(true);
  const [isGetPublicUsersByIdEnabled, setIsGetPublicUsersByIdEnabled] =
    useState(false);
  const [isGetTagsByKeyEnabled, setIsGetTagsByKeyEnabled] = useState(true);
  const [isGetLocationsEnabled, setIsGetLocationsEnabled] = useState(true);
  const [isGetBrandsEnabled, setIsGetBrandsEnabled] = useState(true);
  const [
    isGetPortfoliosByBrandPictureEnabled,
    setIsGetPortfoliosByBrandPictureEnabled,
  ] = useState(false);
  const [isGetUserBrandPicturesEnabled, setIsGetUserBrandPicturesEnabled] =
    useState(true);
  const [isGetPortfolioByIdEnabled, setIsGetPortfolioByIdEnabled] = useState(
    !!editMode,
  );
  const [publicUserIds, setPublicUserIds] = useState<string[]>([]);
  const [showAddMediaKitPanel, setShowAddMediaKitPanel] = useState(false);
  const [addedMediaKitIds, setAddedMediaKitIds] = useState<string[]>([]);
  const [addedMediaKitsCustomizableView, setAddedMediaKitsCustomizableView] =
    useState<MediaKitCustomizableView>({});
  const [configurationBeingCustomizedId, setConfigurationBeingCustomizedId] =
    useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [mediaKitConfigurations, setMediaKitConfigurations] = useState<
    ExternalMediaKitConfiguration[]
  >([]);
  const [customizedMediaKitName, setCustomizedMediaKitName] = useState('');
  const [
    customizedMediaKitDisplayPictureFile,
    setCustomizedMediaKitDisplayPictureFile,
  ] = useState<IFile | string | undefined>();
  const [showCustomizedMediaKitPicture, setShowCustomizedMediaKitPicture] =
    useState(false);
  const [showCustomizedMediaKitIcon, setShowCustomizedMediaKitIcon] =
    useState(false);
  const [showCustomizedMediaKitTitle, setShowCustomizedMediaKitTitle] =
    useState(false);
  const [showCustomizedMediaKitUsername, setShowCustomizedMediaKitUsername] =
    useState(false);
  const [
    showMediaKitNameMandatoryFieldMessage,
    setShowMediaKitNameMandatoryFieldMessage,
  ] = useState(false);
  const [showExpirationDatePicker, setShowExpirationDatePicker] =
    useState(false);
  const [expirationDate, setExpirationDate] = useState<Date>(
    new Date(new Date().setMonth(new Date().getMonth() + 1)),
  );
  const [expirationDateSelected, setExpirationDateSelected] = useState(false);
  const [portfolioName, setPortfolioName] = useState(() => {
    const date = new Date();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${props.t(
      'generic.portfolio',
    )} ${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  });
  const [
    showPortfolioNameMandatoryFieldMessage,
    setShowPortfolioNameMandatoryFieldMessage,
  ] = useState(false);
  const [portfolioSettings, setPortfolioSettings] = useState<PortfolioSettings>(
    {
      profilePictureFile: null,
      backgroundPictureFile: null,
      themeColor: portfolioColorValues.white.toLowerCase(),
      buttonsUIColor: portfolioColorValues.skorr.toLowerCase(),
      layout: PortfolioLayoutTypes.HORIZONTAL_LAYOUT,
      portfolioAuthorName: isAuthenticated()!.user,
      tags: [],
      countryId: null,
      sectionTitle: '',
      sectionDescription: '',
      partnersSectionTitle: '',
      partners: [],
    },
  );
  const [showPortfolioUploadImagesModal, setShowPortfolioUploadImagesModal] =
    useState(false);
  const [
    showPortfolioAuthorNameMandatoryFieldMessage,
    setShowPortfolioAuthorNameMandatoryFieldMessage,
  ] = useState(false);
  const [
    showPartnerBeingCreatedNameMandatoryFieldMessage,
    setShowPartnerBeingCreatedNameMandatoryFieldMessage,
  ] = useState(false);
  const [
    showPartnerBeingCreatedWebsiteMandatoryFieldMessage,
    setShowPartnerBeingCreatedWebsiteMandatoryFieldMessage,
  ] = useState(false);
  const [
    showPartnerBeingCreatedImageMandatoryFieldMessage,
    setShowPartnerBeingCreatedImageMandatoryFieldMessage,
  ] = useState(false);
  const {
    data: configurations,
    isFetching: isFetchingGetConfigurations,
    isSuccess: isSuccessGetConfigurations,
    isError: isErrorGetConfigurations,
    refetch: refetchGetExternalMediaKitConfigurationsByUserId,
  } = useGetExternalMediaKitConfigurationsByUserId(
    isGetConfigurationsByUserIdEnabled,
    isAuthenticated()?.userId!,
  );
  const [tags, setTags] = useState<{ [key: string]: string }>({});
  const [tagsAvailableValues, setTagsAvailableValues] = useState<
    { value: string; label: any }[]
  >([]);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [partnersSearchValue, setPartnersSearchValue] = useState('');
  const [customPartnersCreated, setCustomPartnersCreated] = useState<
    CustomPartnerCreated[]
  >([]);
  const [customPartnerBeingCreated, setCustomPartnerBeingCreated] = useState<{
    name: string;
    website: string;
    pictureFile: IFile | null;
    image: string;
  }>({ name: '', website: '', image: '', pictureFile: null });
  const [showCustomPartnerCreationModal, setShowCustomPartnerCreationModal] =
    useState(false);
  const [
    isCustomPartnerBeingCreatedNameValid,
    setIsCustomPartnerBeingCreatedNameValid,
  ] = useState(false);
  const [
    isCustomPartnerBeingCreatedWebsiteValid,
    setIsCustomPartnerBeingCreatedWebsiteValid,
  ] = useState(false);
  const [
    showCustomPartnerCreatedSuccessfully,
    setShowCustomPartnerCreatedSuccessfully,
  ] = useState(false);
  const [
    actionExecutedSuccessfullyTooltipMessage,
    setActionExecutedSuccessfullyTooltipMessage,
  ] = useState('');
  const [actionExecutingTooltipMessage, setActionExecutingTooltipMessage] =
    useState('');
  const [
    showPortfolioCreatedSuccessfully,
    setShowPortfolioCreatedSuccessfully,
  ] = useState(false);
  const [
    showPortfolioUpdatedSuccessfully,
    setShowPortfolioUpdatedSuccessfully,
  ] = useState(false);
  const [
    partnerImageToCheckDeletionPermission,
    setPartnerImageToCheckDeletionPermission,
  ] = useState('');
  const [partnerNameToBeDeleted, setPartnerNameToBeDeleted] = useState('');
  const [partnerImagesDeleted, setPartnerImagesDeleted] = useState<string[]>(
    [],
  );
  const themeColorContainerRef = useRef();
  const buttonUIColorContainerRef = useRef();
  const [isLoadingPortfolioToBeUpdated, setIsLoadingPortfolioToBeUpdated] =
    useState(editMode);
  const [isLoading, setIsLoading] = useState(true);
  const setIsLoadingToFalseTimerId = useRef<NodeJS.Timeout>();
  const {
    data: portfolioConfiguration,
    isFetching: isFetchingGetPortfolioById,
    isSuccess: isSuccessGetPortfolioById,
    isError: isErrorGetPortfolioById,
    refetch: refetchGetPortfolioById,
  } = useGetExternalMediaKitConfigurationById(
    isGetPortfolioByIdEnabled,
    params.id!,
  );
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: publicUsers,
    isFetching: isFetchingGetPublicUserById,
    isSuccess: isSuccessGetPublicUserById,
    isError: isErrorGetPublicUserById,
  } = useGetPublicUserById(isGetPublicUsersByIdEnabled, publicUserIds);
  const {
    data: tagsByKey,
    isFetching: isFetchingGetTagsByKey,
    isSuccess: isSuccessGetTagsByKey,
    isError: isErrorGetTagsByKey,
  } = useGetTagsByKey(isGetTagsByKeyEnabled, {
    language: BROWSER_LANGUAGE,
    keys: [],
  });
  const {
    data: locations,
    isFetching: isFetchingGetLocations,
    isSuccess: isSuccessGetLocations,
    isError: isErrorGetLocations,
  } = useGetLocations(isGetLocationsEnabled);
  const {
    data: brands,
    isFetching: isFetchingGetBrands,
    isError: isErrorGetBrands,
  } = useGetAllBrandPictures(isGetBrandsEnabled);
  const {
    data: userBrandPictures,
    isFetching: isFetchingGetUserBrandPictures,
    isSuccess: isSuccessGetUserBrandPictures,
    isError: isErrorGetUserBrandPictures,
  } = useGetUserBrandPictures(isGetUserBrandPicturesEnabled);
  const {
    isSuccess: isSuccessUploadUserBrandPicture,
    isError: isErrorUploadUserBrandPicture,
    data: uploadedUserBrandPicture,
    mutate: mutateUploadUserBrandPicture,
  } = useUploadUserBrandPicture();
  const {
    data: portfoliosByBrandPicture,
    isFetching: isFetchingGetPortfoliosByBrandPicture,
    isSuccess: isSuccessGetPortfoliosByBrandPicture,
    isError: isErrorGetPortfoliosByBrandPicture,
  } = useGetPortfolioByBrandPicture(isGetPortfoliosByBrandPictureEnabled, {
    brandPicture: partnerImageToCheckDeletionPermission,
  });
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const [isUpdatingPortfolio, setIsUpdatingPortfolio] = useState(false);
  const {
    isSuccess: isSuccessGeneratePortfolioUrl,
    isLoading: isLoadingGeneratePortfolioUrl,
    isError: isErrorGeneratePortfolioUrl,
    mutate: mutateGeneratePortfolioUrl,
  } = useGeneratePortfolioUrl();
  const {
    isSuccess: isSuccessUpdatePortfolio,
    isLoading: isLoadingUpdatePortfolio,
    isError: isErrorUpdatePortfolio,
    mutate: mutateUpdatePortfolio,
  } = useUpdatePortfolioById();
  const { mutate: mutateDeleteUserBrandPicture } = useDeleteUserBrandPicture();
  const [
    disableCreateUpdateButtonAfterCreationUpdate,
    setDisableCreateUpdateButtonAfterCreationUpdate,
  ] = useState(false);
  const [
    isSavingPartnerCreatedManuallyData,
    setIsSavingPartnerCreatedManuallyData,
  ] = useState(false);
  const verifiedPublicUsers = useMemo(() => {
    if (isSuccessGetSelfUser || isSuccessGetPublicUserById) {
      const verifiedIds = new Set<string>();

      publicUsers?.forEach(publicUser => {
        if (publicUser.socialUserId) {
          if (
            selfUser?.publicProfiles?.find(
              publicProfile => publicProfile.publicUserId === publicUser._id,
            )
          ) {
            verifiedIds.add(publicUser._id);
          }
        }
      });

      return verifiedIds;
    }
    return new Set();
  }, [selfUser, publicUsers]);
  const errorModalCloseButtonClicked = useCallback(() => {
    setHasError(false);
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }
    if (isErrorGetConfigurations) {
      setIsGetConfigurationsByUserIdEnabled(true);
    }
    if (isErrorGetPublicUserById) {
      setIsGetPublicUsersByIdEnabled(true);
    }
    if (isErrorGetTagsByKey) {
      setIsGetTagsByKeyEnabled(true);
    }
    if (isErrorGetLocations) {
      setIsGetLocationsEnabled(true);
    }
    if (isErrorGetBrands) {
      setIsGetBrandsEnabled(true);
    }
    if (isErrorGetUserBrandPictures) {
      setIsGetUserBrandPicturesEnabled(true);
    }
    if (isErrorUploadUserBrandPicture) {
      setIsSavingPartnerCreatedManuallyData(false);
    }
    if (isErrorGeneratePortfolioUrl) {
      setIsCreatingPortfolio(false);
    }
    if (isErrorGetPortfolioById) {
      setIsGetPortfolioByIdEnabled(true);
      setIsLoadingPortfolioToBeUpdated(true);
    }
    if (isErrorUpdatePortfolio) {
      setIsUpdatingPortfolio(false);
    }
  }, [
    isErrorGetSelfUser,
    isErrorGetConfigurations,
    isErrorGetPublicUserById,
    isErrorGetTagsByKey,
    isErrorGetLocations,
    isErrorGetBrands,
    isErrorGetUserBrandPictures,
    isErrorUploadUserBrandPicture,
    isErrorGeneratePortfolioUrl,
    isErrorGetPortfolioById,
    isErrorUpdatePortfolio,
  ]);
  const addMediaKitButtonClicked = useCallback(
    (mediaKitId: string) => {
      if (!addedMediaKitIds.includes(mediaKitId)) {
        setAddedMediaKitIds(prevState => [...prevState, mediaKitId]);
      } else {
        const newAddedMediaKitIds: string[] = [];

        addedMediaKitIds.forEach(addedMediaKitId => {
          if (addedMediaKitId !== mediaKitId) {
            newAddedMediaKitIds.push(addedMediaKitId);
          }
        });

        setAddedMediaKitIds(newAddedMediaKitIds);
      }
    },
    [addedMediaKitIds],
  );
  const removeMediaKitButtonClicked = useCallback(
    (mediaKitId: string) => {
      const newAddedMediaKitIds: string[] = [];

      addedMediaKitIds.forEach(addedMediaKitId => {
        if (addedMediaKitId !== mediaKitId) {
          newAddedMediaKitIds.push(addedMediaKitId);
        }
      });

      setAddedMediaKitIds(newAddedMediaKitIds);

      if (addedMediaKitsCustomizableView[mediaKitId]) {
        const { [mediaKitId]: any, ...newAddedMediaKitsCustomizableView } =
          addedMediaKitsCustomizableView;

        setAddedMediaKitsCustomizableView(newAddedMediaKitsCustomizableView);
      }
    },
    [addedMediaKitIds, addedMediaKitsCustomizableView],
  );
  const configurationUnderCustomization = useMemo(() => {
    return (
      mediaKitConfigurations.find(
        configuration => configuration.id === configurationBeingCustomizedId,
      ) ?? null
    );
  }, [configurationBeingCustomizedId, mediaKitConfigurations]);
  const configurationUnderCustomizationShownFieldsCount = useMemo(() => {
    return (
      Number(showCustomizedMediaKitPicture) +
      Number(showCustomizedMediaKitIcon) +
      Number(showCustomizedMediaKitTitle) +
      Number(showCustomizedMediaKitUsername)
    );
  }, [
    showCustomizedMediaKitPicture,
    showCustomizedMediaKitIcon,
    showCustomizedMediaKitTitle,
    showCustomizedMediaKitUsername,
  ]);
  const configurationCustomizeViewToggleClicked = useCallback(
    (
      newCheckedValue: boolean,
      stateVariableSetter: (param: boolean) => void,
    ) => {
      if (!newCheckedValue) {
        if (configurationUnderCustomizationShownFieldsCount > 1) {
          stateVariableSetter(newCheckedValue);
        }
      } else {
        stateVariableSetter(newCheckedValue);
      }
    },
    [configurationUnderCustomizationShownFieldsCount],
  );
  const getDateLabel = useCallback((date: Date) => {
    return `${props.t(
      getShortenedMonthResourceKey(date),
    )} ${date.getDate()}${`, ${date.getFullYear()}`}`;
  }, []);

  useEffect(() => {
    const invalidateGetExternalMediaKitByUserIdCache = async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'get-external-media-kit-configurations-by-user-id',
          isAuthenticated()?.userId!,
        ],
      });
    };

    invalidateGetExternalMediaKitByUserIdCache();
    refetchGetExternalMediaKitConfigurationsByUserId();
  }, []);

  useEffect(() => {
    if (isSuccessGetConfigurations && !isFetchingGetConfigurations) {
      const localPublicUserIds: string[] = [];

      configurations.forEach(configuration => {
        if (
          configuration.type ===
            ExternalMediaKitConfigurationTypes.EMK_PUBLIC_USER &&
          !localPublicUserIds.find(
            publicUserId => publicUserId === configuration.targetId,
          )
        ) {
          localPublicUserIds.push(configuration.targetId!);
        }
      });

      if (localPublicUserIds.length) {
        setPublicUserIds(localPublicUserIds);
        setIsGetPublicUsersByIdEnabled(true);
        setMediaKitConfigurations(
          configurations.filter(
            configuration =>
              configuration.type ===
              ExternalMediaKitConfigurationTypes.EMK_PUBLIC_USER,
          ),
        );
      }
    }
  }, [isSuccessGetConfigurations, isFetchingGetConfigurations]);

  useEffect(() => {
    if (isSuccessGetTagsByKey) {
      const tagsLocal = {};

      tagsByKey.forEach((item: TagTopic) => {
        (tagsLocal as any)[item.key] = item.topic;
      });

      setTags(tagsLocal);
    }
  }, [isSuccessGetTagsByKey]);

  useEffect(() => {
    if (Object.keys(tags).length) {
      const tagsAvailableValues: { value: string; label: any }[] = [];

      Object.keys(tags).forEach(tagKey => {
        tagsAvailableValues.push({
          value: tags[tagKey],
          label: <div>{tags[tagKey]}</div>,
        });
      });

      setTagsAvailableValues(tagsAvailableValues);
    }
  }, [tags]);

  useEffect(() => {
    if (locations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];
      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: entry.countryId,
          label: entry.country,
        });
      });
      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [isSuccessGetLocations]);

  useEffect(() => {
    if (isSuccessGetUserBrandPictures) {
      const localCustomPartnersCreated: CustomPartnerCreated[] = [];

      userBrandPictures.forEach(({ name, url, website }) => {
        localCustomPartnersCreated.push({ name, url, website: website ?? '' });
      });

      setCustomPartnersCreated(localCustomPartnersCreated);
    }
  }, [isSuccessGetUserBrandPictures]);

  useEffect(() => {
    if (
      isSuccessGetPortfolioById &&
      isSuccessGetTagsByKey &&
      !isFetchingGetPortfolioById
    ) {
      const portfolioSettingsLocal: PortfolioSettings = {
        profilePictureFile: portfolioConfiguration.details?.picture!,
        backgroundPictureFile:
          portfolioConfiguration.details?.pictureBackground!,
        themeColor: portfolioConfiguration.details?.themeColor!,
        buttonsUIColor: portfolioConfiguration.details?.buttonsUIColor!,
        layout: portfolioConfiguration.details?.layout!,
        portfolioAuthorName: portfolioConfiguration.details?.name!,
        tags: (portfolioConfiguration.details?.tags ?? []).map(
          tagKey => tagsByKey.find(item => item.key === tagKey)!.topic!,
        ),
        countryId: portfolioConfiguration.details?.location
          ? { value: portfolioConfiguration.details.location.countryId! }
          : null,
        sectionTitle: portfolioConfiguration.details?.about?.title!,
        sectionDescription: portfolioConfiguration.details?.about?.content!,
        partnersSectionTitle: portfolioConfiguration.details?.partnersTitle!,
        partners: portfolioConfiguration.details!.partners!.map(item => ({
          name: item.name!,
          url: item.image!,
        })),
      };

      setPortfolioName(portfolioConfiguration.name!);
      setExpirationDateSelected(Boolean(portfolioConfiguration.expiredAt));
      if (portfolioConfiguration.expiredAt) {
        setExpirationDate(
          new Date(
            Date.parse(portfolioConfiguration.expiredAt as unknown as string),
          ),
        );
      }
      setPortfolioSettings(portfolioSettingsLocal);

      if (portfolioConfiguration.details?.mediaKits?.length) {
        const addedMediaKitsCustomizableViewLocal: MediaKitCustomizableView =
          {};

        portfolioConfiguration.details.mediaKits.forEach(mediaKit => {
          addedMediaKitsCustomizableViewLocal[mediaKit.id] = {
            name: mediaKit.title!,
            displayPictureFile: mediaKit.picture ?? DEFAULT_USER_AVATAR,
            showPicture: mediaKit.showPicture!,
            showIcon: mediaKit.showIcon!,
            showTitle: mediaKit.showTitle!,
            showUsername: mediaKit.showSubtitle!,
          };
        });

        setAddedMediaKitsCustomizableView(addedMediaKitsCustomizableViewLocal);
        setAddedMediaKitIds(
          portfolioConfiguration.details.mediaKits.map(mediaKit => mediaKit.id),
        );
      }

      setIsLoadingPortfolioToBeUpdated(false);
    }
  }, [
    isSuccessGetPortfolioById,
    isSuccessGetTagsByKey,
    isFetchingGetPortfolioById,
  ]);

  useEffect(() => {
    if (configurationBeingCustomizedId) {
      const name =
        addedMediaKitsCustomizableView[configurationBeingCustomizedId]?.name ??
        configurationUnderCustomization!.name!;
      const displayPictureFile =
        addedMediaKitsCustomizableView[configurationBeingCustomizedId]
          ?.displayPictureFile;
      const showPicture =
        addedMediaKitsCustomizableView[configurationBeingCustomizedId]
          ?.showPicture ?? true;
      const showIcon =
        addedMediaKitsCustomizableView[configurationBeingCustomizedId]
          ?.showIcon ?? true;
      const showTitle =
        addedMediaKitsCustomizableView[configurationBeingCustomizedId]
          ?.showTitle ?? true;
      const showUsername =
        addedMediaKitsCustomizableView[configurationBeingCustomizedId]
          ?.showUsername ?? true;

      setCustomizedMediaKitName(name);
      setCustomizedMediaKitDisplayPictureFile(displayPictureFile);
      setShowCustomizedMediaKitPicture(showPicture);
      setShowCustomizedMediaKitIcon(showIcon);
      setShowCustomizedMediaKitTitle(showTitle);
      setShowCustomizedMediaKitUsername(showUsername);
    }
  }, [configurationBeingCustomizedId]);

  useEffect(() => {
    if (
      customPartnerBeingCreated.pictureFile?.imageBase64 &&
      showPartnerBeingCreatedImageMandatoryFieldMessage
    ) {
      setShowPartnerBeingCreatedImageMandatoryFieldMessage(false);
    }
  }, [customPartnerBeingCreated.pictureFile?.imageBase64]);

  useEffect(() => {
    if (isSuccessUploadUserBrandPicture && isSavingPartnerCreatedManuallyData) {
      setCustomPartnersCreated(prevState => [
        ...prevState,
        {
          name: customPartnerBeingCreated.name,
          website: customPartnerBeingCreated.website,
          url: uploadedUserBrandPicture,
        },
      ]);

      setCustomPartnerBeingCreated({
        name: '',
        website: '',
        pictureFile: null,
        image: '',
      });

      setIsSavingPartnerCreatedManuallyData(false);
      setActionExecutedSuccessfullyTooltipMessage(
        props.t('createPortfolioConfiguration.partnerCreatedSuccessfully'),
      );
      setShowCustomPartnerCreatedSuccessfully(true);
      setTimeout(() => setShowCustomPartnerCreatedSuccessfully(false), 2500);

      if (partnerImagesDeleted.includes(uploadedUserBrandPicture)) {
        setPartnerImagesDeleted(prevState =>
          prevState.filter(item => item !== uploadedUserBrandPicture),
        );
      }
    }
  }, [isSuccessUploadUserBrandPicture]);

  useEffect(() => {
    if (isSuccessGetPortfoliosByBrandPicture) {
      setIsGetPortfoliosByBrandPictureEnabled(false);
    }
  }, [isSuccessGetPortfoliosByBrandPicture]);

  useEffect(() => {
    if (editMode) {
      const invalidateGetExternalMediaKitByIdCache = async () => {
        await queryClient.invalidateQueries({
          queryKey: ['get-external-media-kit-configuration-by-id', params.id],
        });
      };

      invalidateGetExternalMediaKitByIdCache();
      refetchGetPortfolioById();
    }
  }, [editMode]);

  useEffect(() => {
    if (editMode) {
      if (themeColorContainerRef.current) {
        const selectedColor = Array.from(
          (themeColorContainerRef.current as any).children,
        ).find(
          (childElement: any) => childElement.dataset.colorSelected == 'true',
        );

        if (selectedColor) {
          setTimeout(() => {
            if (themeColorContainerRef.current) {
              (themeColorContainerRef.current as any).scrollLeft = (
                selectedColor as any
              ).offsetLeft;
            }
          }, 400);
        }
      }

      if (buttonUIColorContainerRef.current) {
        const selectedColor = Array.from(
          (buttonUIColorContainerRef.current as any).children,
        ).find(
          (childElement: any) => childElement.dataset.colorSelected == 'true',
        );

        if (selectedColor) {
          setTimeout(() => {
            if (buttonUIColorContainerRef.current) {
              (buttonUIColorContainerRef.current as any).scrollLeft = (
                selectedColor as any
              ).offsetLeft;
            }
          }, 400);
        }
      }
    }
  }, [
    themeColorContainerRef.current,
    buttonUIColorContainerRef.current,
    editMode,
  ]);

  useEffect(() => {
    if (isSuccessGeneratePortfolioUrl && isCreatingPortfolio) {
      setIsCreatingPortfolio(false);

      setActionExecutedSuccessfullyTooltipMessage(
        props.t('createPortfolioConfiguration.portfolioCreatedSuccessfully'),
      );
      setShowPortfolioCreatedSuccessfully(true);
      setTimeout(() => {
        setShowPortfolioCreatedSuccessfully(false);
        navigate('/view-media-kit-configurations-list', {
          state: { fromPortfolioCreated: true },
        });
      }, 2000);

      if (partnerImagesDeleted.length) {
        mutateDeleteUserBrandPicture({ url: partnerImagesDeleted });
      }
    }
  }, [isSuccessGeneratePortfolioUrl]);

  useEffect(() => {
    if (isSuccessUpdatePortfolio && isUpdatingPortfolio) {
      setIsUpdatingPortfolio(false);

      setActionExecutedSuccessfullyTooltipMessage(
        props.t('createPortfolioConfiguration.portfolioUpdatedSuccessfully'),
      );
      setShowPortfolioUpdatedSuccessfully(true);
      setTimeout(() => {
        setShowPortfolioUpdatedSuccessfully(false);
        navigate('/view-media-kit-configurations-list', {
          state: { fromPortfolioUpdated: true },
        });
      }, 2000);

      if (partnerImagesDeleted.length) {
        mutateDeleteUserBrandPicture({ url: partnerImagesDeleted });
      }
    }
  }, [isSuccessUpdatePortfolio]);

  useEffect(() => {
    if (
      isSuccessGeneratePortfolioUrl ||
      isSuccessUpdatePortfolio ||
      isLoadingGeneratePortfolioUrl ||
      isLoadingUpdatePortfolio
    ) {
      setDisableCreateUpdateButtonAfterCreationUpdate(true);
    }
  }, [
    isSuccessGeneratePortfolioUrl,
    isSuccessUpdatePortfolio,
    isLoadingGeneratePortfolioUrl,
    isLoadingUpdatePortfolio,
  ]);

  useEffect(() => {
    if (
      isErrorGetSelfUser ||
      isErrorGetConfigurations ||
      isErrorGetPublicUserById ||
      isErrorGetTagsByKey ||
      isErrorGetLocations ||
      isErrorGetBrands ||
      isErrorUploadUserBrandPicture ||
      isErrorGetPortfoliosByBrandPicture ||
      isErrorGeneratePortfolioUrl ||
      isErrorGetPortfolioById ||
      isErrorUpdatePortfolio
    ) {
      setHasError(true);

      if (isErrorGetSelfUser) {
        setIsGetSelfUserEnabled(false);
      }
      if (isErrorGetConfigurations) {
        setIsGetConfigurationsByUserIdEnabled(false);
      }
      if (isErrorGetPublicUserById) {
        setIsGetPublicUsersByIdEnabled(false);
      }
      if (isErrorGetTagsByKey) {
        setIsGetTagsByKeyEnabled(false);
      }
      if (isErrorGetLocations) {
        setIsGetLocationsEnabled(false);
      }
      if (isErrorGetBrands) {
        setIsGetBrandsEnabled(false);
      }
      if (isErrorGetUserBrandPictures) {
        setIsGetUserBrandPicturesEnabled(false);
      }
      if (isErrorGetPortfoliosByBrandPicture) {
        setIsGetPortfoliosByBrandPictureEnabled(false);
      }
      if (isErrorGetPortfolioById) {
        setIsGetPortfolioByIdEnabled(false);
        setIsLoadingPortfolioToBeUpdated(false);
      }

      if (isErrorUploadUserBrandPicture) {
        setMsgError(
          props.t('createPortfolioConfiguration.couldNotUploadBrandImage'),
        );
      } else if (isErrorGetPortfoliosByBrandPicture) {
        setMsgError(
          props.t(
            'createPortfolioConfiguration.errorGetPortfoliosByBrandPicture',
          ),
        );
      } else if (isErrorGeneratePortfolioUrl) {
        setIsCreatingPortfolio(false);

        setMsgError(
          props.t('createPortfolioConfiguration.couldNotCreatePortfolio'),
        );
      } else if (isErrorUpdatePortfolio) {
        setIsUpdatingPortfolio(false);

        setMsgError(
          props.t('createPortfolioConfiguration.couldNotUpdatePortfolio'),
        );
      } else {
        setMsgError(props.t('generic.couldNotLoadData'));
      }
    }
  }, [
    isErrorGetSelfUser,
    isErrorGetConfigurations,
    isErrorGetPublicUserById,
    isErrorGetTagsByKey,
    isErrorGetLocations,
    isErrorGetBrands,
    isErrorGetUserBrandPictures,
    isErrorUploadUserBrandPicture,
    isErrorGetPortfoliosByBrandPicture,
    isErrorGeneratePortfolioUrl,
    isErrorGetPortfolioById,
    isErrorUpdatePortfolio,
  ]);

  useEffect(() => {
    if (
      isFetchingGetSelfUser ||
      isFetchingGetConfigurations ||
      isFetchingGetPublicUserById ||
      isFetchingGetTagsByKey ||
      isFetchingGetLocations ||
      isFetchingGetBrands ||
      isFetchingGetUserBrandPictures ||
      isFetchingGetPortfolioById
    ) {
      setIsLoading(true);

      if (setIsLoadingToFalseTimerId.current) {
        clearTimeout(setIsLoadingToFalseTimerId.current);
      }
    } else {
      if (setIsLoadingToFalseTimerId.current) {
        clearTimeout(setIsLoadingToFalseTimerId.current);
      }

      setIsLoadingToFalseTimerId.current = setTimeout(
        () => setIsLoading(false),
        200,
      );
    }
  }, [
    isFetchingGetSelfUser,
    isFetchingGetConfigurations,
    isFetchingGetPublicUserById,
    isFetchingGetTagsByKey,
    isFetchingGetLocations,
    isFetchingGetBrands,
    isFetchingGetUserBrandPictures,
    isFetchingGetPortfolioById,
  ]);

  const createUpdatePortfolioActionInvoked = () => {
    if (!editMode) {
      setIsCreatingPortfolio(true);
      setActionExecutingTooltipMessage(
        props.t('createPortfolioConfiguration.creatingPortfolio'),
      );
    } else {
      setIsUpdatingPortfolio(true);
      setActionExecutingTooltipMessage(
        props.t('createPortfolioConfiguration.updatingPortfolio'),
      );
    }

    const mediaKits: GeneratePortfolioUrlCustomHookMediaKit[] = [];

    addedMediaKitIds.forEach(addedMediaKitId => {
      const configuration = configurations!.find(
        config => config.id === addedMediaKitId,
      );

      const mediaKit: GeneratePortfolioUrlCustomHookMediaKit = {
        id: addedMediaKitId,
        createdAt: configuration!.createdAt! as unknown as string,
        socialNetwork: configuration!.socialNetwork!,
        targetId: configuration!.targetId!,
        username: publicUsers?.find(
          publicUser => publicUser._id === configuration!.targetId,
        )?.socialNetwork.username!,
        title: configuration!.name!,
        picture: configuration!.displayPicture ?? '',
        showTitle: true,
        showPicture: true,
        showIcon: true,
        showSubtitle: true,
      };

      if (addedMediaKitId === configurationBeingCustomizedId) {
        if (customizedMediaKitName) {
          mediaKit.title = customizedMediaKitName;
        } else if (addedMediaKitsCustomizableView[addedMediaKitId]) {
          mediaKit.title =
            addedMediaKitsCustomizableView[addedMediaKitId]!.name;
        }
        if (customizedMediaKitDisplayPictureFile) {
          mediaKit.picture = customizedMediaKitDisplayPictureFile;
        } else if (
          addedMediaKitsCustomizableView[addedMediaKitId]?.displayPictureFile
        ) {
          mediaKit.picture =
            addedMediaKitsCustomizableView[
              addedMediaKitId
            ]!.displayPictureFile!;
        }
        mediaKit.showPicture = showCustomizedMediaKitPicture;
        mediaKit.showIcon = showCustomizedMediaKitIcon;
        mediaKit.showTitle = showCustomizedMediaKitTitle;
        mediaKit.showSubtitle = showCustomizedMediaKitUsername;
      } else if (addedMediaKitsCustomizableView[addedMediaKitId]) {
        if (addedMediaKitsCustomizableView[addedMediaKitId]!.name) {
          mediaKit.title =
            addedMediaKitsCustomizableView[addedMediaKitId]!.name!;
        }
        if (
          addedMediaKitsCustomizableView[addedMediaKitId]?.displayPictureFile
        ) {
          mediaKit.picture =
            addedMediaKitsCustomizableView[
              addedMediaKitId
            ]!.displayPictureFile!;
        }
        mediaKit.showPicture =
          addedMediaKitsCustomizableView[addedMediaKitId]!.showPicture;
        mediaKit.showIcon =
          addedMediaKitsCustomizableView[addedMediaKitId]!.showIcon;
        mediaKit.showTitle =
          addedMediaKitsCustomizableView[addedMediaKitId]!.showTitle;
        mediaKit.showSubtitle =
          addedMediaKitsCustomizableView[addedMediaKitId]!.showUsername;
      }

      mediaKits.push(mediaKit);
    });

    const tagKeys: string[] = [];

    portfolioSettings.tags.forEach(tagTopic => {
      const correspondingTag = tagsByKey?.find(item => item.topic === tagTopic);
      tagKeys.push(correspondingTag!.key);
    });

    const input: GeneratePortfolioUrlCustomHookParams = {
      name: portfolioName,
      ...(expirationDateSelected && { expiredAt: expirationDate.getTime() }),
      ...(portfolioSettings.profilePictureFile && {
        picture: portfolioSettings.profilePictureFile as IFile,
      }),
      ...(portfolioSettings.backgroundPictureFile && {
        pictureBackground: portfolioSettings.backgroundPictureFile as IFile,
      }),
      mediaKits,
      layout: portfolioSettings.layout,
      themeColor: portfolioSettings.themeColor,
      buttonsUIColor: portfolioSettings.buttonsUIColor,
      contextName: portfolioSettings.portfolioAuthorName,
      tags: tagKeys,
      ...(portfolioSettings.countryId?.value && {
        countryId: portfolioSettings.countryId.value,
      }),
      ...((portfolioSettings.sectionTitle ||
        portfolioSettings.sectionDescription) && {
        about: {
          ...(portfolioSettings.sectionTitle && {
            title: portfolioSettings.sectionTitle,
          }),
          ...(portfolioSettings.sectionDescription && {
            content: portfolioSettings.sectionDescription,
          }),
        },
      }),
      ...(portfolioSettings.partnersSectionTitle && {
        partnersTitle: portfolioSettings.partnersSectionTitle,
      }),
      partners: portfolioSettings.partners.map(({ name, url }) => ({
        name,
        image: url,
      })),
    };

    if (!editMode) {
      mutateGeneratePortfolioUrl(input);
    } else {
      mutateUpdatePortfolio({ ...input, id: params.id! });
    }
  };

  return (
    <S.Container>
      <Loader show={isLoading} message={props.t('generic.loadingData')} />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitlesContainer>
                <S.HeaderTitleContainer>
                  <S.HeaderTitleTextContainer>
                    <span>
                      {props.t(
                        `${
                          editMode
                            ? 'updatePortfolioConfiguration'
                            : 'createPortfolioConfiguration'
                        }.titleFirstPart`,
                      )}
                    </span>
                    <span>
                      {props.t('createPortfolioConfiguration.titleSecondPart')}
                    </span>
                  </S.HeaderTitleTextContainer>
                </S.HeaderTitleContainer>
                <S.HeaderSubtitleContainer>
                  {props.t(
                    `${
                      editMode
                        ? 'updatePortfolioConfiguration'
                        : 'createPortfolioConfiguration'
                    }.subtitle`,
                  )}
                </S.HeaderSubtitleContainer>
              </S.HeaderTitlesContainer>
              <S.PageCrossIconContainer
                onClick={() =>
                  navigate('/view-media-kit-configurations-list', {
                    state: {},
                  })
                }
              >
                <CrossIcon_V2
                  containerWidth="3.3rem"
                  containerHeight="3.3rem"
                />
              </S.PageCrossIconContainer>
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.CardInnerContainer>
            <S.MainSectionsOuterMostContainer>
              <S.MainSectionsOuterContainer>
                <S.MainSectionsContainer
                  visible={
                    !isLoadingPortfolioToBeUpdated &&
                    !isFetchingGetPortfolioById
                  }
                >
                  <S.MediaKitSectionContainer>
                    <>
                      <S.AddMediaKitButtonContainer
                        mediaKitsPanelVisible={showAddMediaKitPanel}
                      >
                        <Button
                          onClick={() => {
                            setShowAddMediaKitPanel(prevState => !prevState);
                          }}
                        >
                          {props.t('createPortfolioConfiguration.addAMediaKit')}
                        </Button>
                      </S.AddMediaKitButtonContainer>
                      {!showAddMediaKitPanel && addedMediaKitIds.length > 0 && (
                        <S.AddedMediaKitsContainer>
                          <S.AddedMediaKitsInnerContainer
                            mediaKitCount={addedMediaKitIds.length}
                          >
                            {mediaKitConfigurations
                              .filter(configuration =>
                                addedMediaKitIds.includes(configuration.id),
                              )
                              .map(configuration => (
                                <S.AddedMediaKitOuterMostContainer>
                                  <S.AddedMediaKitOuterContainer>
                                    <S.AddedMediaKitContainer>
                                      <S.SocialAccountLeftContainer>
                                        <S.SocialAccountImage
                                          src={
                                            (
                                              addedMediaKitsCustomizableView[
                                                configuration.id
                                              ]?.displayPictureFile as any
                                            )?.imageBase64 ??
                                            addedMediaKitsCustomizableView[
                                              configuration.id
                                            ]?.displayPictureFile ??
                                            configuration.displayPicture
                                          }
                                        />
                                        <S.SocialAccountNetworkIconContainer>
                                          {getSocialNetworkIcon({
                                            socialNetwork:
                                              configuration.socialNetwork!,
                                            width: '2rem',
                                            height: '2rem',
                                            gradientColored: true,
                                          })}
                                        </S.SocialAccountNetworkIconContainer>
                                      </S.SocialAccountLeftContainer>

                                      <S.SocialAccountRightContainer>
                                        <S.SocialAccountUsernameContainer>
                                          <S.SocialAccountUsername>
                                            {addedMediaKitsCustomizableView[
                                              configuration.id
                                            ]?.name ?? configuration.name}
                                          </S.SocialAccountUsername>
                                          {verifiedPublicUsers.has(
                                            configuration.targetId,
                                          ) && (
                                            <S.VerifiedIconContainer>
                                              <VerifiedIconV1
                                                containerWidth="1.7rem"
                                                containerHeight="1.7rem"
                                                containerMarginLeft="0.3rem"
                                                containerMarginTop="0.2rem"
                                                containerMarginBottom="0.5rem"
                                              />
                                            </S.VerifiedIconContainer>
                                          )}
                                        </S.SocialAccountUsernameContainer>
                                        <S.SocialAccountNetworkName>
                                          {`${props.t(
                                            'generic.created',
                                          )} ${getTimePeriodAgoLabel(
                                            new Date(
                                              configuration.createdAt!,
                                            ).getTime(),
                                            props.t,
                                          )}`}
                                        </S.SocialAccountNetworkName>
                                      </S.SocialAccountRightContainer>
                                      <S.AddedMediaKitActionsContainer>
                                        <S.AddedMediaKitAction
                                          onClick={() =>
                                            setConfigurationBeingCustomizedId(
                                              configuration.id,
                                            )
                                          }
                                        >
                                          <EditIconV2
                                            containerWidth="2rem"
                                            containerHeight="2rem"
                                          />
                                        </S.AddedMediaKitAction>

                                        <S.AddedMediaKitAction
                                          onClick={() =>
                                            removeMediaKitButtonClicked(
                                              configuration.id,
                                            )
                                          }
                                        >
                                          <TrashCanIconV1
                                            containerWidth="2rem"
                                            containerHeight="2rem"
                                          />
                                        </S.AddedMediaKitAction>
                                      </S.AddedMediaKitActionsContainer>
                                    </S.AddedMediaKitContainer>
                                  </S.AddedMediaKitOuterContainer>
                                </S.AddedMediaKitOuterMostContainer>
                              ))}
                          </S.AddedMediaKitsInnerContainer>
                        </S.AddedMediaKitsContainer>
                      )}

                      {showAddMediaKitPanel && (
                        <ClickOutHandler
                          onClickOut={() => setShowAddMediaKitPanel(false)}
                        >
                          <S.AddMediaKitPanel>
                            <S.AddMediaKitPanelTitle>
                              {props.t(
                                'editMediaKitConfiguration.selectMediaKits',
                              )}
                            </S.AddMediaKitPanelTitle>
                            <S.AddMediaKitPanelOuterContainer>
                              <S.AddMediaKitPanelInnerContainer
                                mediaKitCount={mediaKitConfigurations.length}
                              >
                                {mediaKitConfigurations.length === 0 ? (
                                  <S.EmptyMediaKitsDataContainer>
                                    <EmptyContentIconV1
                                      containerWidth="8rem"
                                      containerHeight="8rem"
                                    />
                                    <S.EmptyMediaKitsMessage>
                                      <div>
                                        {props.t(
                                          'createPortfolioConfiguration.emptyMediaKitsTitle',
                                        )}
                                      </div>
                                      <div>
                                        {props.t(
                                          'createPortfolioConfiguration.emptyMediaKitsSubtitle',
                                        )}
                                      </div>
                                    </S.EmptyMediaKitsMessage>
                                  </S.EmptyMediaKitsDataContainer>
                                ) : (
                                  mediaKitConfigurations?.map(configuration => (
                                    <S.SocialAccountOuterMostContainer>
                                      <S.SocialAccountOuterContainer
                                        onClick={() =>
                                          addMediaKitButtonClicked(
                                            configuration.id,
                                          )
                                        }
                                      >
                                        <S.SocialAccountContainer>
                                          <S.SocialAccountAddButton>
                                            {addedMediaKitIds.includes(
                                              configuration.id,
                                            ) ? (
                                              <S.SocialAccountAddButtonInner>
                                                <S.MediaKitAddedCheckMark>
                                                  <CheckMarkIcon
                                                    containerWidth="1.5rem"
                                                    containerHeight="1.5rem"
                                                  />
                                                </S.MediaKitAddedCheckMark>
                                              </S.SocialAccountAddButtonInner>
                                            ) : (
                                              <S.SocialAccountAddButtonInner>
                                                <PlusIconV1
                                                  containerWidth="2.7rem"
                                                  containerHeight="2.7rem"
                                                />
                                              </S.SocialAccountAddButtonInner>
                                            )}
                                          </S.SocialAccountAddButton>
                                          <S.SocialAccountLeftContainer>
                                            <S.SocialAccountImage
                                              src={configuration.displayPicture}
                                            />
                                            <S.SocialAccountNetworkIconContainer>
                                              {getSocialNetworkIcon({
                                                socialNetwork:
                                                  configuration.socialNetwork!,
                                                width: '2rem',
                                                height: '2rem',
                                                gradientColored: true,
                                              })}
                                            </S.SocialAccountNetworkIconContainer>
                                          </S.SocialAccountLeftContainer>

                                          <S.SocialAccountRightContainer>
                                            <S.SocialAccountUsernameContainer>
                                              <S.SocialAccountUsername>
                                                {configuration.name}
                                              </S.SocialAccountUsername>
                                              {verifiedPublicUsers.has(
                                                configuration.targetId,
                                              ) && (
                                                <S.VerifiedIconContainer>
                                                  <VerifiedIconV1
                                                    containerWidth="1.7rem"
                                                    containerHeight="1.7rem"
                                                    containerMarginLeft="0.3rem"
                                                    containerMarginTop="0.2rem"
                                                    containerMarginBottom="0.5rem"
                                                  />
                                                </S.VerifiedIconContainer>
                                              )}
                                            </S.SocialAccountUsernameContainer>
                                            <S.SocialAccountNetworkName>
                                              {`${props.t(
                                                'generic.created',
                                              )} ${getTimePeriodAgoLabel(
                                                new Date(
                                                  configuration.createdAt!,
                                                ).getTime(),
                                                props.t,
                                              )}`}
                                            </S.SocialAccountNetworkName>
                                          </S.SocialAccountRightContainer>
                                        </S.SocialAccountContainer>
                                      </S.SocialAccountOuterContainer>
                                    </S.SocialAccountOuterMostContainer>
                                  ))
                                )}
                              </S.AddMediaKitPanelInnerContainer>
                            </S.AddMediaKitPanelOuterContainer>
                          </S.AddMediaKitPanel>
                        </ClickOutHandler>
                      )}
                    </>
                  </S.MediaKitSectionContainer>

                  <S.CustomizePortfolioOuterContainer>
                    <S.CustomizePortfolioInnerContainer>
                      <S.CustomizePortfolioHeader>
                        <S.CustomizePortfolioHeaderColored
                          layoutType={portfolioSettings.layout}
                          themeColor={portfolioSettings.themeColor}
                          backgroundCoverImage={
                            (portfolioSettings.backgroundPictureFile as any)
                              ?.imageBase64 ??
                            portfolioSettings.backgroundPictureFile
                          }
                        >
                          <S.CustomizePortfolioTitle
                            defaultThemeColor={
                              portfolioSettings.themeColor === '#ffffff'
                            }
                            backgroundCoverImageSelected={Boolean(
                              (portfolioSettings.backgroundPictureFile as any)
                                ?.imageBase64 ??
                                portfolioSettings.backgroundPictureFile,
                            )}
                          >
                            {props.t(
                              'createPortfolioConfiguration.customizePortfolioTitle',
                            )}
                          </S.CustomizePortfolioTitle>
                          {((portfolioSettings.backgroundPictureFile as any)
                            ?.imageBase64 ??
                            portfolioSettings.backgroundPictureFile) && (
                            <S.PortfolioHeaderBackgroundPicture
                              src={
                                (portfolioSettings.backgroundPictureFile as any)
                                  ?.imageBase64 ??
                                portfolioSettings.backgroundPictureFile
                              }
                            />
                          )}
                          <S.PortfolioHeaderProfilePicture
                            src={
                              (portfolioSettings.profilePictureFile as any)
                                ?.imageBase64 ??
                              portfolioSettings.profilePictureFile ??
                              DEFAULT_USER_AVATAR
                            }
                          />
                        </S.CustomizePortfolioHeaderColored>
                        <S.PortfolioHeaderBottomContainer>
                          <S.PortfolioHeaderUploadImagesButton
                            onClick={() =>
                              setShowPortfolioUploadImagesModal(true)
                            }
                          >
                            <span>
                              {props.t(
                                'createPortfolioConfiguration.uploadImages',
                              )}
                            </span>
                            <UploadIconV1
                              containerWidth="2.3rem"
                              containerHeight="2.3rem"
                              containerMarginLeft="0.8rem"
                            />
                          </S.PortfolioHeaderUploadImagesButton>
                        </S.PortfolioHeaderBottomContainer>
                      </S.CustomizePortfolioHeader>

                      <S.SelectLayoutContainer>
                        <S.SelectPortfolioLayoutTitle>
                          {props.t('createPortfolioConfiguration.selectLayout')}
                        </S.SelectPortfolioLayoutTitle>
                        <S.SelectPortfolioLayoutContainer>
                          <S.PortfolioLayoutTypeOption
                            active={
                              portfolioSettings.layout ===
                              PortfolioLayoutTypes.HORIZONTAL_LAYOUT
                            }
                            onClick={() => {
                              if (
                                portfolioSettings.layout !==
                                PortfolioLayoutTypes.HORIZONTAL_LAYOUT
                              )
                                setPortfolioSettings(prevState => ({
                                  ...prevState,
                                  layout:
                                    PortfolioLayoutTypes.HORIZONTAL_LAYOUT,
                                }));
                            }}
                          >
                            <S.PortfolioLayoutTypeOptionLeft>
                              <div>
                                {props.t(
                                  'createPortfolioConfiguration.horizontalLayoutTitle',
                                )}
                              </div>
                              <div>
                                {props.t(
                                  'createPortfolioConfiguration.horizontalLayoutDescription',
                                )}
                              </div>
                            </S.PortfolioLayoutTypeOptionLeft>
                            <S.PortfolioLayoutTypeOptionRight>
                              <PortfolioHorizontalLayoutTypeIcon
                                containerWidth="100%"
                                containerHeight="100%"
                              />
                            </S.PortfolioLayoutTypeOptionRight>
                            {portfolioSettings.layout ===
                              PortfolioLayoutTypes.HORIZONTAL_LAYOUT && (
                              <S.PortfolioLayoutTypeActiveCheckmark>
                                <CheckMarkIcon
                                  containerWidth={'2rem'}
                                  containerHeight={'2rem'}
                                />
                              </S.PortfolioLayoutTypeActiveCheckmark>
                            )}
                          </S.PortfolioLayoutTypeOption>
                          <S.PortfolioLayoutTypeOption
                            active={
                              portfolioSettings.layout ===
                              PortfolioLayoutTypes.VERTICAL_LAYOUT
                            }
                            onClick={() => {
                              if (
                                portfolioSettings.layout !==
                                PortfolioLayoutTypes.VERTICAL_LAYOUT
                              ) {
                                setPortfolioSettings(prevState => ({
                                  ...prevState,
                                  layout: PortfolioLayoutTypes.VERTICAL_LAYOUT,
                                }));
                              }
                            }}
                          >
                            <S.PortfolioLayoutTypeOptionLeft>
                              <div>
                                {props.t(
                                  'createPortfolioConfiguration.verticalLayoutTitle',
                                )}
                              </div>
                              <div>
                                {props.t(
                                  'createPortfolioConfiguration.verticalLayoutDescription',
                                )}
                              </div>
                            </S.PortfolioLayoutTypeOptionLeft>
                            <S.PortfolioLayoutTypeOptionRight>
                              {portfolioSettings.layout ===
                                PortfolioLayoutTypes.VERTICAL_LAYOUT && (
                                <S.PortfolioLayoutTypeActiveCheckmark>
                                  <CheckMarkIcon
                                    containerWidth={'2rem'}
                                    containerHeight={'2rem'}
                                  />
                                </S.PortfolioLayoutTypeActiveCheckmark>
                              )}
                              <PortfolioVerticalLayoutTypeIcon
                                containerWidth="100%"
                                containerHeight="100%"
                              />
                            </S.PortfolioLayoutTypeOptionRight>
                          </S.PortfolioLayoutTypeOption>
                        </S.SelectPortfolioLayoutContainer>
                      </S.SelectLayoutContainer>

                      <S.CustomizePortfolioColorsOuterContainer>
                        <S.CustomizePortfolioColorsContainer>
                          <S.CustomizePortfolioColorsTitle>
                            {props.t('createPortfolioConfiguration.themeColor')}
                          </S.CustomizePortfolioColorsTitle>
                          <S.CustomizePortfolioColorsInner
                            ref={themeColorContainerRef}
                          >
                            {Object.keys(portfolioColorValues).map(colorKey => (
                              <S.CustomizePortfolioColor
                                white={
                                  portfolioColorValues[
                                    colorKey as keyof typeof portfolioColorValues
                                  ].toLowerCase() === '#ffffff'
                                }
                                color={
                                  portfolioColorValues[
                                    colorKey as keyof typeof portfolioColorValues
                                  ]
                                }
                                active={
                                  portfolioSettings.themeColor ===
                                  portfolioColorValues[
                                    colorKey as keyof typeof portfolioColorValues
                                  ].toLowerCase()
                                }
                                data-color-selected={
                                  portfolioSettings.themeColor ===
                                  portfolioColorValues[
                                    colorKey as keyof typeof portfolioColorValues
                                  ].toLowerCase()
                                }
                                onClick={() =>
                                  setPortfolioSettings(prevState => ({
                                    ...prevState,
                                    themeColor:
                                      portfolioColorValues[
                                        colorKey as keyof typeof portfolioColorValues
                                      ].toLowerCase(),
                                  }))
                                }
                              >
                                {portfolioSettings.themeColor ===
                                  portfolioColorValues[
                                    colorKey as keyof typeof portfolioColorValues
                                  ].toLowerCase() && (
                                  <S.PortfolioColorActiveCheckMark
                                    activeColor={portfolioSettings.themeColor}
                                  >
                                    <CheckMarkIcon
                                      containerWidth="1.3rem"
                                      containerHeight="1.3rem"
                                    />
                                  </S.PortfolioColorActiveCheckMark>
                                )}
                              </S.CustomizePortfolioColor>
                            ))}
                          </S.CustomizePortfolioColorsInner>
                        </S.CustomizePortfolioColorsContainer>

                        {portfolioSettings.themeColor === '#ffffff' && (
                          <S.CustomizePortfolioColorsContainer>
                            <S.CustomizePortfolioColorsTitle>
                              {props.t(
                                'createPortfolioConfiguration.buttonsUIColor',
                              )}
                            </S.CustomizePortfolioColorsTitle>
                            <S.CustomizePortfolioColorsInner
                              ref={buttonUIColorContainerRef}
                            >
                              {Object.keys(portfolioColorValues)
                                .slice(1)
                                .map(colorKey => (
                                  <S.CustomizePortfolioColor
                                    white={false}
                                    color={
                                      portfolioColorValues[
                                        colorKey as keyof typeof portfolioColorValues
                                      ]
                                    }
                                    active={
                                      portfolioSettings.buttonsUIColor ===
                                      portfolioColorValues[
                                        colorKey as keyof typeof portfolioColorValues
                                      ].toLowerCase()
                                    }
                                    data-color-selected={
                                      portfolioSettings.buttonsUIColor ===
                                      portfolioColorValues[
                                        colorKey as keyof typeof portfolioColorValues
                                      ].toLowerCase()
                                    }
                                    onClick={() =>
                                      setPortfolioSettings(prevState => ({
                                        ...prevState,
                                        buttonsUIColor:
                                          portfolioColorValues[
                                            colorKey as keyof typeof portfolioColorValues
                                          ].toLowerCase(),
                                      }))
                                    }
                                  >
                                    {portfolioSettings.buttonsUIColor ===
                                      portfolioColorValues[
                                        colorKey as keyof typeof portfolioColorValues
                                      ].toLowerCase() && (
                                      <S.PortfolioColorActiveCheckMark
                                        activeColor={
                                          portfolioSettings.buttonsUIColor
                                        }
                                      >
                                        <CheckMarkIcon
                                          containerWidth="1.3rem"
                                          containerHeight="1.3rem"
                                        />
                                      </S.PortfolioColorActiveCheckMark>
                                    )}
                                  </S.CustomizePortfolioColor>
                                ))}
                            </S.CustomizePortfolioColorsInner>
                          </S.CustomizePortfolioColorsContainer>
                        )}
                      </S.CustomizePortfolioColorsOuterContainer>

                      <S.PortfolioSettingsNameContainer>
                        <S.PortfolioSettingsNameInnerContainer>
                          <S.PortfolioSettingsNameTitle>
                            {props.t('createPortfolioConfiguration.name')}
                          </S.PortfolioSettingsNameTitle>

                          <InputControlV2
                            value={portfolioSettings.portfolioAuthorName}
                            onChange={(inputValue: string) => {
                              setPortfolioSettings(prevState => ({
                                ...prevState,
                                portfolioAuthorName: inputValue,
                              }));
                            }}
                            inputFieldContainerWidth={'100%'}
                            placeholder={props.t(
                              'createPortfolioConfiguration.portfolioAuthorNameInputPlaceholder',
                            )}
                            forceErrorDisplay={
                              showPortfolioAuthorNameMandatoryFieldMessage
                                ? props.t('inputControl.mandatoryField')
                                : ''
                            }
                            forceBorderColorRed={
                              showPortfolioAuthorNameMandatoryFieldMessage
                            }
                            onFocus={() =>
                              setShowPortfolioAuthorNameMandatoryFieldMessage(
                                false,
                              )
                            }
                            onBlur={() => {
                              if (!portfolioSettings.portfolioAuthorName.length)
                                setShowPortfolioAuthorNameMandatoryFieldMessage(
                                  true,
                                );
                            }}
                            warningMessageContainerPositionAbsolute={true}
                            warningMessageContainerTopOffset={'0.1rem'}
                            warningMessageContainerLeftOffset={'0.2rem'}
                          />
                        </S.PortfolioSettingsNameInnerContainer>
                      </S.PortfolioSettingsNameContainer>

                      <S.PortfolioSettingsTagsContainer>
                        <S.PortfolioSettingsTagsInnerContainer>
                          <S.PortfolioSettingsTagsTitle>
                            {props.t('createPortfolioConfiguration.tags')}
                          </S.PortfolioSettingsTagsTitle>

                          <S.PortfolioSettingsTagsComboBoxContainer>
                            <ComboBox
                              valueKey={'tagsSelected'}
                              value={portfolioSettings.tags}
                              type={'multi'}
                              width={'100%'}
                              marginTop={'0.5rem'}
                              borderRadius={'0.6rem'}
                              valuesAvailable={tagsAvailableValues}
                              isFilterable={true}
                              isClearable={true}
                              placeholder={props.t(
                                'createPortfolioConfiguration.selectNoMoreThanFiveTags',
                              )}
                              onChange={(obj: {
                                tagsSelected: Array<string>;
                              }) => {
                                setPortfolioSettings(prevState => ({
                                  ...prevState,
                                  tags: obj.tagsSelected,
                                }));
                              }}
                              preventOptionSelection={
                                portfolioSettings.tags.length === 5
                              }
                              height={'7rem'}
                              availableOptionsContainerBorderRadius={'0.6rem'}
                              optionContainerBorderRadius={'0.6rem'}
                              innerOptionsContainerMarginTop={'1.2rem'}
                              innerOptionsContainerMarginBottom={'1.2rem'}
                              innerOptionsContainerPaddingLeft={'1.2rem'}
                              innerOptionsContainerPaddingRight={'1.2rem'}
                              optionContainerPaddingTop={'2rem'}
                              optionContainerPaddingBottom={'2rem'}
                              optionContainerPaddingLeft={'2rem'}
                              optionContainerPaddingRight={'2rem'}
                              optionSelectedContainerBorderRadius={'2rem'}
                              themeStyles={(themeGlobal as any).comboBoxTags}
                              changeBackgroundColorOnHover={false}
                              crossIconV2={true}
                              growWithoutScroll={true}
                            />
                          </S.PortfolioSettingsTagsComboBoxContainer>
                        </S.PortfolioSettingsTagsInnerContainer>
                      </S.PortfolioSettingsTagsContainer>

                      <S.PortfolioSettingsCountryContainer>
                        <S.PortfolioSettingsCountryInnerContainer>
                          <S.PortfolioSettingsCountryTitle>
                            {props.t('createPortfolioConfiguration.country')}
                          </S.PortfolioSettingsCountryTitle>

                          <ComboBox
                            valueKey={'countrySelected'}
                            value={portfolioSettings.countryId?.value}
                            type={'single'}
                            width={'100%'}
                            borderRadius={'0.6rem'}
                            valuesAvailable={countriesAvailableValues}
                            isFilterable={false}
                            isClearable={true}
                            onChange={(obj: { countrySelected: string }) => {
                              setPortfolioSettings(prevState => ({
                                ...prevState,
                                countryId: {
                                  value: obj.countrySelected,
                                },
                              }));
                            }}
                            placeholder={props.t(
                              'createPortfolioConfiguration.countryInputPlaceholder',
                            )}
                            height={'4.4rem'}
                            availableOptionsContainerBorderRadius={'0.6rem'}
                            optionContainerBorderRadius={'0.6rem'}
                            innerOptionsContainerMarginTop={'1.2rem'}
                            innerOptionsContainerMarginBottom={'1.2rem'}
                            innerOptionsContainerPaddingLeft={'1.2rem'}
                            innerOptionsContainerPaddingRight={'1.2rem'}
                            optionContainerPaddingTop={'2rem'}
                            optionContainerPaddingBottom={'2rem'}
                            optionContainerPaddingLeft={'2rem'}
                            optionContainerPaddingRight={'2rem'}
                            themeStyles={
                              (themeGlobal as any).comboBoxTopicsOfInterest
                            }
                            changeBackgroundColorOnHover={false}
                            innerOptionsContainerMaxHeight={'25rem'}
                          />
                        </S.PortfolioSettingsCountryInnerContainer>
                      </S.PortfolioSettingsCountryContainer>

                      <S.PortfolioSettingsSectionContainer>
                        <S.PortfolioSettingsSectionInnerContainer>
                          <S.PortfolioSettingsSectionTitle>
                            {props.t('createPortfolioConfiguration.section')}
                          </S.PortfolioSettingsSectionTitle>

                          <S.PortfolioSettingsSectionTitleInput>
                            <InputControlV2
                              value={portfolioSettings.sectionTitle}
                              onChange={(inputValue: string) => {
                                setPortfolioSettings(prevState => ({
                                  ...prevState,
                                  sectionTitle: inputValue,
                                }));
                              }}
                              inputFieldContainerWidth={'100%'}
                              placeholder={props.t(
                                'createPortfolioConfiguration.portfolioSectionTitleInputPlaceholder',
                              )}
                            />
                          </S.PortfolioSettingsSectionTitleInput>

                          <S.PortfolioSettingsSectionDescriptionInput>
                            <InputControlV2
                              value={portfolioSettings.sectionDescription}
                              onChange={(inputValue: string) => {
                                setPortfolioSettings(prevState => ({
                                  ...prevState,
                                  sectionDescription: inputValue,
                                }));
                              }}
                              inputFieldContainerWidth={'100%'}
                              placeholder={props.t(
                                'createPortfolioConfiguration.portfolioSectionDescriptionInputPlaceholder',
                              )}
                              type={'textarea'}
                              inputFieldHeight={'15rem'}
                              inputFieldLineHeight={'2.5rem'}
                              inputFieldPaddingTop={'1rem'}
                              inputFieldPaddingBottom={'1rem'}
                            />
                            <S.PortfolioSettingsSectionDescriptionCopyContainer>
                              <S.PortfolioSettingsSectionDescriptionCopy
                                onClick={event =>
                                  copySmartLinkButtonClicked(event)
                                }
                              >
                                <S.CopiedToClipboard>
                                  {props.t('generic.copied')}
                                </S.CopiedToClipboard>
                                <CopyToClipboard
                                  text={portfolioSettings.sectionDescription}
                                >
                                  <S.PortfolioSettingsSectionDescriptionCopyInner>
                                    <span>{props.t('generic.copy')}</span>
                                    <CopyIcon
                                      containerWidth="2rem"
                                      containerHeight="2rem"
                                      containerMarginLeft={'1rem'}
                                    />
                                  </S.PortfolioSettingsSectionDescriptionCopyInner>
                                </CopyToClipboard>
                              </S.PortfolioSettingsSectionDescriptionCopy>
                            </S.PortfolioSettingsSectionDescriptionCopyContainer>
                          </S.PortfolioSettingsSectionDescriptionInput>
                        </S.PortfolioSettingsSectionInnerContainer>
                      </S.PortfolioSettingsSectionContainer>

                      <S.PortfolioSettingsPartnersSectionContainer>
                        <S.PortfolioSettingsPartnersSectionInnerContainer>
                          <S.PortfolioSettingsPartnersSectionTitle>
                            {props.t(
                              'createPortfolioConfiguration.partnersSection',
                            )}
                          </S.PortfolioSettingsPartnersSectionTitle>

                          <S.PortfolioSettingsPartnersSectionTitleInputContainer>
                            <S.PortfolioSettingsPartnersSectionTitleInput>
                              <InputControlV2
                                value={portfolioSettings.partnersSectionTitle}
                                onChange={(inputValue: string) => {
                                  setPortfolioSettings(prevState => ({
                                    ...prevState,
                                    partnersSectionTitle: inputValue,
                                  }));
                                }}
                                inputFieldContainerWidth={'100%'}
                                placeholder={props.t(
                                  'createPortfolioConfiguration.portfolioPartnersSectionTitleInputPlaceholder',
                                )}
                              />
                            </S.PortfolioSettingsPartnersSectionTitleInput>
                          </S.PortfolioSettingsPartnersSectionTitleInputContainer>
                          <>
                            <S.SelectPortfolioPartnersTitle>
                              {props.t(
                                'createPortfolioConfiguration.selectPartners',
                              )}
                            </S.SelectPortfolioPartnersTitle>
                            <S.SelectPortfolioPartnersSelectionContainer>
                              <S.SelectPortfolioPartnersFirstRow>
                                <S.SelectPortfolioPartnersSearchInput>
                                  <InputControlV2
                                    value={partnersSearchValue}
                                    onChange={(inputValue: string) =>
                                      setPartnersSearchValue(inputValue)
                                    }
                                    inputFieldContainerWidth={'100%'}
                                    placeholder={props.t(
                                      'createPortfolioConfiguration.partnersSearchInputPlaceholder',
                                    )}
                                    inputFieldLineHeight={'2.5rem'}
                                    inputFieldPaddingTop={'1rem'}
                                    inputFieldPaddingBottom={'1rem'}
                                    inputFieldPaddingRight={'5rem'}
                                  />
                                  <S.MagnifyingGlassContainer>
                                    <MagnifyingGlassIcon
                                      containerWidth="3rem"
                                      containerHeight="3rem"
                                    />
                                  </S.MagnifyingGlassContainer>
                                </S.SelectPortfolioPartnersSearchInput>
                                <S.SelectPortfolioPartnersTotalSelectedCount>
                                  {props.t(
                                    'createPortfolioConfiguration.partnersSelectedCountPart1',
                                  )}
                                  <span>
                                    {portfolioSettings.partners.length}
                                  </span>
                                  {props.t(
                                    'createPortfolioConfiguration.partnersSelectedCountPart2',
                                  )}
                                </S.SelectPortfolioPartnersTotalSelectedCount>
                              </S.SelectPortfolioPartnersFirstRow>

                              <S.SelectPortfolioPartnersSelectionBrandsContainer>
                                <S.PortfolioPartner
                                  onClick={() =>
                                    setShowCustomPartnerCreationModal(true)
                                  }
                                >
                                  <S.PortfolioAddCustomPartnerIcon>
                                    <UploadIconV1
                                      containerWidth="3rem"
                                      containerHeight="3rem"
                                    />
                                  </S.PortfolioAddCustomPartnerIcon>
                                  <S.PortfolioPartnerName>
                                    {props.t(
                                      'createPortfolioConfiguration.addCustomPartner',
                                    )}
                                  </S.PortfolioPartnerName>
                                </S.PortfolioPartner>
                                {customPartnersCreated
                                  ?.filter(brand =>
                                    brand.name
                                      .toLowerCase()
                                      .includes(
                                        partnersSearchValue.toLowerCase(),
                                      ),
                                  )
                                  .map(brand => (
                                    <S.PortfolioPartner>
                                      <S.PortfolioPartnerImage
                                        src={brand.url}
                                        clickable={
                                          Boolean(
                                            portfolioSettings.partners.find(
                                              partner =>
                                                partner.name === brand.name &&
                                                partner.url === brand.url,
                                            ),
                                          ) ||
                                          portfolioSettings.partners.length < 12
                                        }
                                        onClick={() => {
                                          if (
                                            !portfolioSettings.partners.find(
                                              partner =>
                                                partner.name === brand.name &&
                                                partner.url === brand.url,
                                            ) &&
                                            portfolioSettings.partners.length <
                                              12
                                          ) {
                                            setPortfolioSettings(prevState => ({
                                              ...prevState,
                                              partners: [
                                                ...prevState.partners,
                                                brand,
                                              ],
                                            }));
                                          } else {
                                            setPortfolioSettings(prevState => ({
                                              ...prevState,
                                              partners:
                                                prevState.partners.filter(
                                                  partner =>
                                                    !(
                                                      partner.name ===
                                                        brand.name &&
                                                      partner.url === brand.url
                                                    ),
                                                ),
                                            }));
                                          }
                                        }}
                                      />
                                      <S.PortfolioPartnerName>
                                        {brand.name}
                                      </S.PortfolioPartnerName>
                                      {portfolioSettings.partners.find(
                                        partner =>
                                          partner.name === brand.name &&
                                          partner.url === brand.url,
                                      ) && (
                                        <S.PartnerAddedCheckMark>
                                          <CheckMarkIcon
                                            containerWidth="1.5rem"
                                            containerHeight="1.5rem"
                                          />
                                        </S.PartnerAddedCheckMark>
                                      )}
                                      <S.CustomPartnerRemoveContainer
                                        onClick={(event: any) => {
                                          event.stopPropagation();
                                          setPartnerImageToCheckDeletionPermission(
                                            brand.url,
                                          );
                                          setPartnerNameToBeDeleted(brand.name);
                                          setIsGetPortfoliosByBrandPictureEnabled(
                                            true,
                                          );
                                        }}
                                      >
                                        <TrashCanIconV1
                                          containerWidth="1.5rem"
                                          containerHeight="1.5rem"
                                        />
                                      </S.CustomPartnerRemoveContainer>
                                    </S.PortfolioPartner>
                                  ))}

                                {customPartnersCreated.findIndex(brand =>
                                  brand.name
                                    .toLowerCase()
                                    .includes(
                                      partnersSearchValue.toLowerCase(),
                                    ),
                                ) !== -1 && (
                                  <S.PartnersCreatedManuallyAlreadyExistingDivider />
                                )}

                                {brands
                                  ?.filter(brand =>
                                    brand.name
                                      .toLowerCase()
                                      .includes(
                                        partnersSearchValue.toLowerCase(),
                                      ),
                                  )
                                  .map(brand => (
                                    <S.PortfolioPartner>
                                      <S.PortfolioPartnerImage
                                        src={brand.url}
                                        clickable={
                                          Boolean(
                                            portfolioSettings.partners.find(
                                              partner =>
                                                partner.name === brand.name,
                                            ),
                                          ) ||
                                          portfolioSettings.partners.length < 12
                                        }
                                        onClick={() => {
                                          if (
                                            !portfolioSettings.partners.find(
                                              partner =>
                                                partner.name === brand.name,
                                            ) &&
                                            portfolioSettings.partners.length <
                                              12
                                          ) {
                                            setPortfolioSettings(prevState => ({
                                              ...prevState,
                                              partners: [
                                                ...prevState.partners,
                                                brand,
                                              ],
                                            }));
                                          } else {
                                            setPortfolioSettings(prevState => ({
                                              ...prevState,
                                              partners:
                                                prevState.partners.filter(
                                                  partner =>
                                                    partner.name != brand.name,
                                                ),
                                            }));
                                          }
                                        }}
                                      />
                                      <S.PortfolioPartnerName>
                                        {brand.name}
                                      </S.PortfolioPartnerName>
                                      {portfolioSettings.partners.find(
                                        partner => partner.name === brand.name,
                                      ) && (
                                        <S.PartnerAddedCheckMark>
                                          <CheckMarkIcon
                                            containerWidth="1.5rem"
                                            containerHeight="1.5rem"
                                          />
                                        </S.PartnerAddedCheckMark>
                                      )}
                                    </S.PortfolioPartner>
                                  ))}
                              </S.SelectPortfolioPartnersSelectionBrandsContainer>
                            </S.SelectPortfolioPartnersSelectionContainer>
                          </>

                          {portfolioSettings.partners.length > 0 && (
                            <S.PortfolioSettingsPartnersOuterContainer>
                              <S.PortfolioSettingsSelectedPartnersTitle>
                                {props.t(
                                  'createPortfolioConfiguration.selectedPartners',
                                )}
                              </S.PortfolioSettingsSelectedPartnersTitle>

                              <S.PortfolioSettingsPartnersContainer>
                                {portfolioSettings.partners.map(partner => (
                                  <S.PortfolioPartnerSelected
                                    cursor={
                                      customPartnersCreated.find(
                                        customPartner =>
                                          customPartner.name === partner.name,
                                      )
                                        ? 'pointer'
                                        : 'default'
                                    }
                                    onClick={() => {
                                      const websiteUrl =
                                        customPartnersCreated.find(
                                          customPartner =>
                                            customPartner.name === partner.name,
                                        )?.website;
                                      if (websiteUrl) {
                                        window.open(
                                          `http://${websiteUrl}`,
                                          '_blank',
                                        );
                                      }
                                    }}
                                  >
                                    <S.PortfolioPartnerSelectedImage
                                      src={
                                        (partner as any).url ??
                                        (partner as any).pictureFile.imageBase64
                                      }
                                    />
                                    <S.AddedPartnerCross
                                      onClick={(event: any) => {
                                        event.stopPropagation();

                                        setPortfolioSettings(prevState => ({
                                          ...prevState,
                                          partners:
                                            portfolioSettings.partners.filter(
                                              item =>
                                                item.name !== partner.name,
                                            ),
                                        }));
                                      }}
                                    >
                                      <CrossIcon_V2
                                        containerWidth="100%"
                                        containerHeight="100%"
                                      />
                                    </S.AddedPartnerCross>
                                  </S.PortfolioPartnerSelected>
                                ))}
                              </S.PortfolioSettingsPartnersContainer>
                            </S.PortfolioSettingsPartnersOuterContainer>
                          )}
                        </S.PortfolioSettingsPartnersSectionInnerContainer>
                      </S.PortfolioSettingsPartnersSectionContainer>

                      <ClickOutHandler
                        onClickOut={() => setShowExpirationDatePicker(false)}
                      >
                        <S.ExpirationDateContainer>
                          <S.ExpirationDateTitle>
                            {props.t(
                              'createPortfolioConfiguration.expirationDateTitle',
                            )}
                          </S.ExpirationDateTitle>
                          <S.ExpirationDateInnerContainer
                            active={expirationDateSelected}
                            onClick={() => {
                              if (expirationDateSelected) {
                                setShowExpirationDatePicker(
                                  prevState => !prevState,
                                );
                              }
                            }}
                          >
                            <S.ExpirationDateLabel>
                              {!expirationDateSelected
                                ? props.t(
                                    'createPortfolioConfiguration.neverExpires',
                                  )
                                : getDateLabel(expirationDate)}
                            </S.ExpirationDateLabel>
                            {showExpirationDatePicker && (
                              <S.ExpirationDatePickerContainer
                                onClick={(event: any) =>
                                  event.stopPropagation()
                                }
                              >
                                <Calendar
                                  date={expirationDate}
                                  onChange={setExpirationDate}
                                />
                              </S.ExpirationDatePickerContainer>
                            )}

                            <S.ExpirationDateArrowContainer
                              disabled={!expirationDateSelected}
                            >
                              {showExpirationDatePicker === false ? (
                                <RiArrowDownSLine size={'2.5rem'} />
                              ) : (
                                <RiArrowUpSLine size={'2.5rem'} />
                              )}
                            </S.ExpirationDateArrowContainer>

                            <span
                              onClick={(event: any) => event.stopPropagation()}
                            >
                              <CheckBox
                                checked={expirationDateSelected}
                                onChange={() =>
                                  setExpirationDateSelected(prevState => {
                                    if (prevState) {
                                      setShowExpirationDatePicker(false);
                                    }
                                    return !prevState;
                                  })
                                }
                                marginLeft={'0.5rem'}
                                marginRight={'0.5rem'}
                                checkBoxContainerWidth={'1.8rem'}
                                checkBoxContainerHeight={'1.8rem'}
                                borderWidth={'0.25rem'}
                              />
                            </span>
                          </S.ExpirationDateInnerContainer>
                        </S.ExpirationDateContainer>
                      </ClickOutHandler>

                      <S.PortfolioNameInputContainer>
                        <S.PortfolioNameInputTitle>
                          {props.t(
                            'createPortfolioConfiguration.portfolioNameTitle',
                          )}
                        </S.PortfolioNameInputTitle>

                        <InputControlV2
                          value={portfolioName}
                          onChange={(inputValue: string) =>
                            setPortfolioName(inputValue)
                          }
                          inputFieldContainerWidth={'100%'}
                          placeholder={props.t(
                            'createPortfolioConfiguration.portfolioNameInputPlaceholder',
                          )}
                          forceErrorDisplay={
                            showPortfolioNameMandatoryFieldMessage
                              ? props.t('inputControl.mandatoryField')
                              : ''
                          }
                          forceBorderColorRed={
                            showPortfolioNameMandatoryFieldMessage
                          }
                          onFocus={() =>
                            setShowPortfolioNameMandatoryFieldMessage(false)
                          }
                          onBlur={() => {
                            if (!portfolioName.length)
                              setShowPortfolioNameMandatoryFieldMessage(true);
                          }}
                          warningMessageContainerPositionAbsolute={true}
                          warningMessageContainerTopOffset={'0.2rem'}
                          warningMessageContainerLeftOffset={'0.2rem'}
                        />
                      </S.PortfolioNameInputContainer>
                    </S.CustomizePortfolioInnerContainer>
                  </S.CustomizePortfolioOuterContainer>
                </S.MainSectionsContainer>
              </S.MainSectionsOuterContainer>
            </S.MainSectionsOuterMostContainer>
          </S.CardInnerContainer>
          <S.FooterContainer>
            <Button
              borderRadius={'0.6rem'}
              borderWidth={'0rem'}
              height={'4rem'}
              fontSize={'1.6rem'}
              textAlign={'center'}
              fontWeight={'500'}
              paddingRight={'1rem'}
              marginLeft={'auto'}
              marginRight={'1.5rem'}
              onClick={() => {
                navigate('/view-media-kit-configurations-list', {
                  state: {},
                });
              }}
              themeStyles={getThemeStyles(
                ['button', 'secondary-1'],
                themeGlobal,
              )}
            >
              {props.t('generic.cancel')}
            </Button>

            <Button
              borderRadius={'0.6rem'}
              borderWidth={'0rem'}
              height={'4rem'}
              fontSize={'1.6rem'}
              textAlign={'center'}
              fontWeight={'500'}
              disabledNotNative={
                !portfolioName.length ||
                disableCreateUpdateButtonAfterCreationUpdate
              }
              cursorOnDisable={
                disableCreateUpdateButtonAfterCreationUpdate
                  ? 'default'
                  : 'pointer'
              }
              pointerEventsOnDisable={
                disableCreateUpdateButtonAfterCreationUpdate ? 'none' : 'all'
              }
              themeStyles={
                (themeGlobal as any).createExternalMediaKitConfiguration
                  .createButton
              }
              onClick={() => createUpdatePortfolioActionInvoked()}
            >
              {props.t(`generic.${editMode ? 'update' : 'create'}`)}
            </Button>
          </S.FooterContainer>
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={showPortfolioUploadImagesModal}
        title={props.t('createPortfolioConfiguration.uploadImagesModalTitle')}
        body={
          <S.UploadPortfolioImagesContainer>
            <S.UploadPortfolioImagesOptionOuter>
              {!(
                (portfolioSettings.profilePictureFile as any)?.imageBase64 ??
                portfolioSettings.profilePictureFile
              ) ? (
                <FilePickerV2
                  handleChange={(files: IFile[]) =>
                    setPortfolioSettings(prevState => ({
                      ...prevState,
                      profilePictureFile: files[0],
                    }))
                  }
                  text={''}
                  showSelectedFiles={false}
                  single={true}
                  type={'images'}
                  buttonBorderRadius={'0.6rem'}
                  withImagePreview={false}
                  customButton={
                    <S.UploadPortfolioImagesOption>
                      <UploadIconV1
                        containerWidth="2.3rem"
                        containerHeight="2.3rem"
                      />

                      <span>
                        {props.t(
                          'createPortfolioConfiguration.uploadCustomProfilePicture',
                        )}
                      </span>
                    </S.UploadPortfolioImagesOption>
                  }
                />
              ) : (
                <S.UploadPortfolioImagesOption
                  onClick={() =>
                    setPortfolioSettings(prevState => ({
                      ...prevState,
                      profilePictureFile: null,
                    }))
                  }
                >
                  <CrossIcon_V2
                    containerWidth="2.3rem"
                    containerHeight="2.3rem"
                  />

                  <span>
                    {props.t(
                      'createPortfolioConfiguration.removeCustomProfilePicture',
                    )}
                  </span>
                </S.UploadPortfolioImagesOption>
              )}

              {!(
                (portfolioSettings.backgroundPictureFile as any)?.imageBase64 ??
                portfolioSettings.backgroundPictureFile
              ) ? (
                <FilePickerV2
                  handleChange={(files: IFile[]) =>
                    setPortfolioSettings(prevState => ({
                      ...prevState,
                      backgroundPictureFile: files[0],
                    }))
                  }
                  text={''}
                  showSelectedFiles={false}
                  single={true}
                  type={'images'}
                  buttonBorderRadius={'0.6rem'}
                  withImagePreview={false}
                  customButton={
                    <S.UploadPortfolioImagesOption>
                      <UploadIconV1
                        containerWidth="2.3rem"
                        containerHeight="2.3rem"
                      />

                      <span>
                        {props.t(
                          'createPortfolioConfiguration.uploadBackgroundCoverImage',
                        )}
                      </span>
                    </S.UploadPortfolioImagesOption>
                  }
                />
              ) : (
                <S.UploadPortfolioImagesOption
                  onClick={() =>
                    setPortfolioSettings(prevState => ({
                      ...prevState,
                      backgroundPictureFile: null,
                    }))
                  }
                >
                  <CrossIcon_V2
                    containerWidth="2.3rem"
                    containerHeight="2.3rem"
                  />

                  <span>
                    {props.t(
                      'createPortfolioConfiguration.removeBackgroundCoverImage',
                    )}
                  </span>
                </S.UploadPortfolioImagesOption>
              )}
            </S.UploadPortfolioImagesOptionOuter>
          </S.UploadPortfolioImagesContainer>
        }
        modalWidth={'60rem'}
        isCancelable={false}
        primaryButtonAction={() => setShowPortfolioUploadImagesModal(false)}
        primaryButtonText={props.t('generic.close')}
      />

      <GenericModal
        show={showCustomPartnerCreationModal}
        title={props.t('createPortfolioConfiguration.createPartnerManually')}
        body={
          <LoaderLocal
            show={isSavingPartnerCreatedManuallyData}
            message={props.t('createPortfolioConfiguration.creatingPartner')}
            spinner={true}
            spinnerFontSize={'2rem'}
          >
            <S.CreatePartnerManuallyOuter>
              <S.CreatePartnerManuallyContainer>
                <S.CreatePartnerManuallyImageOuterContainer>
                  <S.CreatePartnerManuallyImageContainer>
                    {!customPartnerBeingCreated.pictureFile?.imageBase64 ? (
                      <UploadIconV1
                        containerWidth="4rem"
                        containerHeight="4rem"
                      />
                    ) : (
                      <S.CreatePartnerManuallyImage
                        src={customPartnerBeingCreated.pictureFile.imageBase64}
                      />
                    )}
                  </S.CreatePartnerManuallyImageContainer>

                  {!customPartnerBeingCreated?.pictureFile?.imageBase64 ? (
                    <FilePickerV2
                      handleChange={(files: IFile[]) => {
                        setCustomPartnerBeingCreated(prevState => ({
                          ...prevState,
                          pictureFile: files[0],
                        }));
                      }}
                      text={''}
                      showSelectedFiles={false}
                      single={true}
                      type={'images'}
                      buttonBorderRadius={'0.6rem'}
                      withImagePreview={false}
                      customButton={
                        <S.UploadPortfolioImagesOption>
                          <UploadIconV1
                            containerWidth="2.3rem"
                            containerHeight="2.3rem"
                          />

                          <span>
                            {props.t(
                              'createPortfolioConfiguration.uploadBrandImage',
                            )}
                          </span>
                        </S.UploadPortfolioImagesOption>
                      }
                    />
                  ) : (
                    <S.UploadPortfolioImagesOption
                      onClick={() => {
                        setCustomPartnerBeingCreated(prevState => ({
                          ...prevState,
                          pictureFile: null,
                        }));
                      }}
                    >
                      <CrossIcon_V2
                        containerWidth="2.3rem"
                        containerHeight="2.3rem"
                      />

                      <span>
                        {props.t(
                          'createPortfolioConfiguration.removeBrandImage',
                        )}
                      </span>
                    </S.UploadPortfolioImagesOption>
                  )}

                  {showPartnerBeingCreatedImageMandatoryFieldMessage && (
                    <S.CreatePartnerManuallyImageRequiredMessage>
                      {props.t('inputControl.mandatoryField')}
                    </S.CreatePartnerManuallyImageRequiredMessage>
                  )}
                </S.CreatePartnerManuallyImageOuterContainer>

                <S.CreatePartnerManuallyNameContainer>
                  <S.CreatePartnerManuallyNameTitle>
                    {props.t('createPortfolioConfiguration.name')}
                  </S.CreatePartnerManuallyNameTitle>

                  <InputControlV2
                    value={customPartnerBeingCreated.name}
                    onChange={(inputValue: string) => {
                      setCustomPartnerBeingCreated(prevState => ({
                        ...prevState,
                        name: inputValue,
                      }));
                    }}
                    inputFieldContainerWidth={'100%'}
                    placeholder={props.t(
                      'createPortfolioConfiguration.customPartnerBrandNameInputPlaceholder',
                    )}
                    mandatoryField={true}
                    setIsInvalidOrProcessing={(
                      isInvalidOrProcessing: boolean,
                    ) =>
                      setIsCustomPartnerBeingCreatedNameValid(
                        !isInvalidOrProcessing,
                      )
                    }
                    validationFunction={inputValue => {
                      if (
                        customPartnersCreated.find(
                          brand =>
                            brand.name.toLowerCase() ===
                            inputValue.toLowerCase(),
                        ) ||
                        brands?.find(
                          brand =>
                            brand.name.toLowerCase() ===
                            inputValue.toLowerCase(),
                        )
                      ) {
                        return false;
                      }
                      return true;
                    }}
                    invalidFieldWarningMessage={props.t(
                      'createPortfolioConfiguration.brandNameAlreadyInUse',
                    )}
                    forceErrorDisplay={
                      showPartnerBeingCreatedNameMandatoryFieldMessage
                        ? props.t('inputControl.mandatoryField')
                        : ''
                    }
                    forceBorderColorRed={
                      showPartnerBeingCreatedNameMandatoryFieldMessage
                    }
                    onFocus={() =>
                      setShowPartnerBeingCreatedNameMandatoryFieldMessage(false)
                    }
                    onBlur={() => {
                      if (!customPartnerBeingCreated.name.length)
                        setShowPartnerBeingCreatedNameMandatoryFieldMessage(
                          true,
                        );
                    }}
                    warningMessageContainerPositionAbsolute={true}
                    warningMessageContainerTopOffset={'0.1rem'}
                    warningMessageContainerLeftOffset={'0.2rem'}
                    allowGreenBorder={true}
                    allowCheckMarkIcon={true}
                  />
                </S.CreatePartnerManuallyNameContainer>

                <S.CreatePartnerManuallyNameContainer>
                  <S.CreatePartnerManuallyNameTitle>
                    {props.t('createPortfolioConfiguration.website')}
                  </S.CreatePartnerManuallyNameTitle>

                  <InputControlV2
                    value={customPartnerBeingCreated.website}
                    onChange={(inputValue: string) => {
                      setCustomPartnerBeingCreated(prevState => ({
                        ...prevState,
                        website: inputValue,
                      }));
                    }}
                    inputFieldContainerWidth={'100%'}
                    placeholder={props.t(
                      'createPortfolioConfiguration.customPartnerBrandWebsiteInputPlaceholder',
                    )}
                    setIsInvalidOrProcessing={(
                      isInvalidOrProcessing: boolean,
                    ) =>
                      setIsCustomPartnerBeingCreatedWebsiteValid(
                        !isInvalidOrProcessing,
                      )
                    }
                    validationFunction={isUrlValid}
                    invalidFieldWarningMessage={props.t(
                      'createPortfolioConfiguration.invalidWebsiteUrlFormat',
                    )}
                    forceErrorDisplay={
                      showPartnerBeingCreatedWebsiteMandatoryFieldMessage
                        ? props.t('inputControl.mandatoryField')
                        : ''
                    }
                    forceBorderColorRed={
                      showPartnerBeingCreatedWebsiteMandatoryFieldMessage
                    }
                    onFocus={() =>
                      setShowPartnerBeingCreatedWebsiteMandatoryFieldMessage(
                        false,
                      )
                    }
                    onBlur={() => {
                      if (!customPartnerBeingCreated.website.length)
                        setShowPartnerBeingCreatedWebsiteMandatoryFieldMessage(
                          true,
                        );
                    }}
                    warningMessageContainerPositionAbsolute={true}
                    warningMessageContainerTopOffset={'0.1rem'}
                    warningMessageContainerLeftOffset={'0.2rem'}
                    allowGreenBorder={true}
                    allowCheckMarkIcon={true}
                  />
                </S.CreatePartnerManuallyNameContainer>

                <S.CreatePartnerManuallySaveButtonContainer>
                  <Button
                    onClick={() => {
                      if (
                        !customPartnerBeingCreated.name.length ||
                        !isCustomPartnerBeingCreatedNameValid ||
                        !customPartnerBeingCreated.website.length ||
                        !isCustomPartnerBeingCreatedWebsiteValid ||
                        !customPartnerBeingCreated.pictureFile?.imageBase64
                      ) {
                        if (!customPartnerBeingCreated.name.length) {
                          setShowPartnerBeingCreatedNameMandatoryFieldMessage(
                            true,
                          );
                        }
                        if (!customPartnerBeingCreated.website.length) {
                          setShowPartnerBeingCreatedWebsiteMandatoryFieldMessage(
                            true,
                          );
                        }
                        if (
                          !customPartnerBeingCreated.pictureFile?.imageBase64
                        ) {
                          setShowPartnerBeingCreatedImageMandatoryFieldMessage(
                            true,
                          );
                        }
                      } else {
                        setIsSavingPartnerCreatedManuallyData(true);

                        const imageFormData = new FormData();
                        imageFormData.append(
                          'file',
                          customPartnerBeingCreated.pictureFile.data,
                        );
                        imageFormData.append(
                          'name',
                          customPartnerBeingCreated.name,
                        );
                        imageFormData.append(
                          'website',
                          customPartnerBeingCreated.website,
                        );

                        mutateUploadUserBrandPicture(imageFormData);
                      }
                    }}
                    disabledNotNative={
                      !customPartnerBeingCreated.name.length ||
                      !isCustomPartnerBeingCreatedNameValid ||
                      !customPartnerBeingCreated.website.length ||
                      !isCustomPartnerBeingCreatedWebsiteValid ||
                      !customPartnerBeingCreated.pictureFile?.imageBase64
                    }
                    themeStyles={
                      (themeGlobal as any).createPortfolioConfiguration
                        .customPartnerBeingCreatedSaveButton
                    }
                  >
                    {props.t('generic.save')}
                  </Button>
                </S.CreatePartnerManuallySaveButtonContainer>
              </S.CreatePartnerManuallyContainer>
            </S.CreatePartnerManuallyOuter>
          </LoaderLocal>
        }
        modalWidth={'70rem'}
        isCancelable={false}
        primaryButtonAction={() => {
          setCustomPartnerBeingCreated({
            name: '',
            website: '',
            pictureFile: null,
            image: '',
          });
          setShowCustomPartnerCreationModal(false);
        }}
        primaryButtonText={props.t('generic.close')}
        primaryButtonDisabled={isSavingPartnerCreatedManuallyData}
      />

      <GenericModal
        show={
          Boolean(partnerImageToCheckDeletionPermission) &&
          !isErrorGetPortfoliosByBrandPicture
        }
        title={props.t('createPortfolioConfiguration.partnerDeletion')}
        body={
          <S.PartnerDeletionModalOuterContainer>
            {isFetchingGetPortfoliosByBrandPicture ? (
              <S.PartnerDeletionModalLoadingContainer>
                <S.PartnerDeletionModalLoadingIconContainer>
                  <InputSpinnerIcon />
                </S.PartnerDeletionModalLoadingIconContainer>

                <S.PartnerDeletionModalLoadingMessage>
                  {props.t(
                    'createPortfolioConfiguration.partnerDeletionPermissionCheckMessage',
                  )}
                </S.PartnerDeletionModalLoadingMessage>
              </S.PartnerDeletionModalLoadingContainer>
            ) : isSuccessGetPortfoliosByBrandPicture &&
              portfoliosByBrandPicture.total ? (
              <S.PartnerDeletionDeniedContainer>
                <S.PartnerDeletionDeniedMessage>
                  <S.PartnerDeletionDeniedMessageIcon>
                    <InfoV2Icon />
                  </S.PartnerDeletionDeniedMessageIcon>
                  <S.PartnerDeletionDeniedMessageText>
                    {props.t(
                      'createPortfolioConfiguration.partnerDeletionDeniedMessage',
                    )}
                  </S.PartnerDeletionDeniedMessageText>
                </S.PartnerDeletionDeniedMessage>
                <S.PartnerDeletionPortfoliosContainer>
                  {portfoliosByBrandPicture.portfolios.map(portfolio => (
                    <S.PartnerDeletionPortfolioOuterContainer>
                      <S.PartnerDeletionPortfolioInnerContainer>
                        <S.PartnerDeletionPortfolioLeftContainer>
                          <S.PartnerDeletionPortfolioSocialAccountImage
                            src={portfolio.image}
                          />
                        </S.PartnerDeletionPortfolioLeftContainer>

                        <S.PartnerDeletionPortfolioSocialAccountRightContainer>
                          <S.PartnerDeletionPortfolioSocialAccountUsernameContainer>
                            <S.PartnerDeletionPortfolioSocialAccountUsername>
                              {portfolio.name}
                            </S.PartnerDeletionPortfolioSocialAccountUsername>
                          </S.PartnerDeletionPortfolioSocialAccountUsernameContainer>
                          <S.PartnerDeletionPortfolioSocialAccountSubTextContainer>
                            <S.PartnerDeletionPortfolioSocialAccountNetworkName>
                              {`${props.t(
                                'generic.created',
                              )} ${getTimePeriodAgoLabel(
                                new Date(portfolio.createdAt).getTime(),
                                props.t,
                              )}`}
                            </S.PartnerDeletionPortfolioSocialAccountNetworkName>
                          </S.PartnerDeletionPortfolioSocialAccountSubTextContainer>
                        </S.PartnerDeletionPortfolioSocialAccountRightContainer>
                      </S.PartnerDeletionPortfolioInnerContainer>
                    </S.PartnerDeletionPortfolioOuterContainer>
                  ))}
                </S.PartnerDeletionPortfoliosContainer>
                {portfoliosByBrandPicture.total >
                  portfoliosByBrandPicture.portfolios.length && (
                  <S.PartnerDeletionDeniedExtraPortfoliosCountContainer>
                    <S.PartnerDeletionDeniedExtraPortfoliosIcon>
                      <PlusIconV2 />
                    </S.PartnerDeletionDeniedExtraPortfoliosIcon>
                    <S.PartnerDeletionDeniedExtraPortfoliosCount>
                      {portfoliosByBrandPicture.total -
                        portfoliosByBrandPicture.portfolios.length}{' '}
                    </S.PartnerDeletionDeniedExtraPortfoliosCount>
                  </S.PartnerDeletionDeniedExtraPortfoliosCountContainer>
                )}
              </S.PartnerDeletionDeniedContainer>
            ) : (
              <S.PartnerDeletionModalConfirm>
                <S.PartnerDeletionModalConfirmMessage>
                  <span>
                    {props.t(
                      'createPortfolioConfiguration.partnerDeletionConfirmationPrimaryMessage',
                    )}
                  </span>
                  <span>
                    {props.t(
                      `${
                        !editMode
                          ? 'createPortfolioConfiguration'
                          : 'updatePortfolioConfiguration'
                      }.partnerDeletionConfirmationSecondaryMessage`,
                    )}
                  </span>
                </S.PartnerDeletionModalConfirmMessage>
                <S.PartnerDeletionModalConfirmButtons>
                  <Button
                    onClick={() => {
                      setPartnerImageToCheckDeletionPermission('');
                      setPartnerNameToBeDeleted('');
                    }}
                    themeStyles={getThemeStyles(
                      ['button', 'secondary-1'],
                      themeGlobal,
                    )}
                  >
                    {props.t('generic.cancel')}
                  </Button>

                  <Button
                    onClick={() => {
                      setCustomPartnersCreated(prevState =>
                        prevState.filter(
                          brand =>
                            !(
                              brand.name === partnerNameToBeDeleted &&
                              brand.url ===
                                partnerImageToCheckDeletionPermission
                            ),
                        ),
                      );
                      setPortfolioSettings(prevState => ({
                        ...prevState,
                        partners: prevState.partners.filter(
                          partner =>
                            !(
                              partner.name === partnerNameToBeDeleted &&
                              partner.url ===
                                partnerImageToCheckDeletionPermission
                            ),
                        ),
                      }));

                      setPartnerImagesDeleted(prevState => [
                        ...prevState,
                        partnerImageToCheckDeletionPermission,
                      ]);

                      setPartnerImageToCheckDeletionPermission('');
                      setPartnerNameToBeDeleted('');
                    }}
                  >
                    {props.t('generic.confirm')}
                  </Button>
                </S.PartnerDeletionModalConfirmButtons>
              </S.PartnerDeletionModalConfirm>
            )}
          </S.PartnerDeletionModalOuterContainer>
        }
        modalWidth={'70rem'}
        isCancelable={false}
        primaryButtonAction={() => {
          setPartnerImageToCheckDeletionPermission('');
        }}
        primaryButtonText={
          !isFetchingGetPortfoliosByBrandPicture &&
          portfoliosByBrandPicture?.total === 0
            ? undefined
            : props.t('generic.close')
        }
      />

      <GenericModal
        show={Boolean(configurationBeingCustomizedId)}
        title={props.t('createPortfolioConfiguration.customizeView')}
        body={
          !configurationBeingCustomizedId ? (
            <></>
          ) : (
            <S.CustomizeViewOuterContainer>
              <S.CustomizeViewContainer>
                <S.CustomizeViewInnerContainer>
                  <S.ConfigurationUnderCustomizationOuterContainer>
                    <S.ConfigurationUnderCustomizationInnerContainer>
                      {(showCustomizedMediaKitPicture ||
                        showCustomizedMediaKitIcon) && (
                        <S.SocialAccountLeftContainer>
                          {showCustomizedMediaKitPicture && (
                            <S.SocialAccountImage
                              src={
                                (customizedMediaKitDisplayPictureFile as any)
                                  ?.imageBase64 ??
                                customizedMediaKitDisplayPictureFile ??
                                (
                                  addedMediaKitsCustomizableView[
                                    configurationBeingCustomizedId as string
                                  ]?.displayPictureFile as any
                                )?.imageBase64 ??
                                addedMediaKitsCustomizableView[
                                  configurationBeingCustomizedId as string
                                ]?.displayPictureFile ??
                                configurationUnderCustomization!.displayPicture
                              }
                            />
                          )}
                          {showCustomizedMediaKitIcon && (
                            <S.SocialAccountNetworkIconContainer
                              single={!showCustomizedMediaKitPicture}
                            >
                              {getSocialNetworkIcon({
                                socialNetwork:
                                  configurationUnderCustomization!
                                    .socialNetwork!,
                                width: !showCustomizedMediaKitPicture
                                  ? '100%'
                                  : '2rem',
                                height: !showCustomizedMediaKitPicture
                                  ? '100%'
                                  : '2rem',
                                gradientColored: true,
                              })}
                            </S.SocialAccountNetworkIconContainer>
                          )}
                        </S.SocialAccountLeftContainer>
                      )}

                      {(showCustomizedMediaKitTitle ||
                        showCustomizedMediaKitUsername) && (
                        <S.SocialAccountRightContainer
                          single={
                            !(
                              showCustomizedMediaKitPicture ||
                              showCustomizedMediaKitIcon
                            )
                          }
                        >
                          {showCustomizedMediaKitTitle && (
                            <S.SocialAccountUsernameContainer>
                              <S.SocialAccountUsername
                                single={!showCustomizedMediaKitUsername}
                              >
                                {customizedMediaKitName}
                              </S.SocialAccountUsername>
                              {verifiedPublicUsers.has(
                                configurationUnderCustomization!.targetId,
                              ) && (
                                <S.VerifiedIconContainer>
                                  <VerifiedIconV1
                                    containerWidth="1.7rem"
                                    containerHeight="1.7rem"
                                    containerMarginLeft="0.3rem"
                                    containerMarginTop="0.2rem"
                                    containerMarginBottom="0.5rem"
                                  />
                                </S.VerifiedIconContainer>
                              )}
                            </S.SocialAccountUsernameContainer>
                          )}
                          {showCustomizedMediaKitUsername && (
                            <S.SocialAccountSubTextContainer
                              single={!showCustomizedMediaKitTitle}
                            >
                              <S.SocialAccountNetworkName>
                                {
                                  publicUsers?.find(
                                    publicUser =>
                                      publicUser._id ===
                                      configurationUnderCustomization!.targetId,
                                  )?.socialNetwork.username
                                }
                              </S.SocialAccountNetworkName>
                              {!showCustomizedMediaKitTitle &&
                                verifiedPublicUsers.has(
                                  configurationUnderCustomization!.targetId,
                                ) && (
                                  <S.VerifiedIconContainer>
                                    <VerifiedIconV1
                                      containerWidth="1.7rem"
                                      containerHeight="1.7rem"
                                      containerMarginLeft="0.3rem"
                                    />
                                  </S.VerifiedIconContainer>
                                )}
                            </S.SocialAccountSubTextContainer>
                          )}
                        </S.SocialAccountRightContainer>
                      )}
                    </S.ConfigurationUnderCustomizationInnerContainer>
                  </S.ConfigurationUnderCustomizationOuterContainer>

                  <S.CustomizableMediaKitNameInputContainer>
                    <InputControlV2
                      value={customizedMediaKitName}
                      onChange={(inputValue: string) => {
                        if (inputValue.length === 0) {
                          if (
                            configurationUnderCustomizationShownFieldsCount > 1
                          ) {
                            setCustomizedMediaKitName(inputValue);
                            setShowCustomizedMediaKitTitle(false);
                          } else {
                            if (
                              !(
                                configurationUnderCustomizationShownFieldsCount ===
                                  1 && showCustomizedMediaKitTitle
                              )
                            ) {
                              setCustomizedMediaKitName(inputValue);
                            }
                          }
                        } else {
                          setCustomizedMediaKitName(inputValue);
                        }
                      }}
                      inputFieldContainerWidth={'100%'}
                      placeholder={props.t(
                        'createPortfolioConfiguration.customizedNameInputPlaceholder',
                      )}
                      forceErrorDisplay={
                        showMediaKitNameMandatoryFieldMessage
                          ? props.t('inputControl.mandatoryField')
                          : ''
                      }
                      forceBorderColorRed={
                        showMediaKitNameMandatoryFieldMessage
                      }
                      onFocus={() =>
                        setShowMediaKitNameMandatoryFieldMessage(false)
                      }
                      onBlur={() => {
                        if (!customizedMediaKitName.length)
                          setShowMediaKitNameMandatoryFieldMessage(true);
                      }}
                      warningMessageContainerPositionAbsolute={true}
                      warningMessageContainerTopOffset={'0.1rem'}
                      warningMessageContainerLeftOffset={'0.2rem'}
                    />
                  </S.CustomizableMediaKitNameInputContainer>

                  <S.CustomImageButtonsContainer>
                    <FilePickerV2
                      handleChange={(files: IFile[]) =>
                        setCustomizedMediaKitDisplayPictureFile(files[0])
                      }
                      text={props.t(
                        'createPortfolioConfiguration.uploadCustomImage',
                      )}
                      showSelectedFiles={false}
                      single={true}
                      type={'images'}
                      buttonBorderRadius={'0.6rem'}
                      buttonPaddingLeft={'1.2rem'}
                      buttonPaddingRight={'1.2rem'}
                      uploadButtonIconMarginRight={'0.3rem'}
                      withImagePreview={false}
                    />

                    {customizedMediaKitDisplayPictureFile &&
                      customizedMediaKitDisplayPictureFile !==
                        configurationUnderCustomization?.displayPicture && (
                        <S.RemoveUploadedImageButton
                          onClick={() => {
                            if (
                              customizedMediaKitDisplayPictureFile ===
                                addedMediaKitsCustomizableView[
                                  configurationBeingCustomizedId as string
                                ]?.displayPictureFile ||
                              (customizedMediaKitDisplayPictureFile as any)
                                ?.imageBase64 ===
                                (
                                  addedMediaKitsCustomizableView[
                                    configurationBeingCustomizedId as string
                                  ]?.displayPictureFile as any
                                )?.imageBase64
                            ) {
                              setCustomizedMediaKitDisplayPictureFile(
                                configurationUnderCustomization?.displayPicture,
                              );
                            } else {
                              setCustomizedMediaKitDisplayPictureFile(
                                undefined,
                              );
                            }
                          }}
                        >
                          <CrossIcon_V2
                            containerWidth="1.9rem"
                            containerHeight="1.9rem"
                          />
                          <span>
                            {props.t(
                              'createPortfolioConfiguration.removeCustomImage',
                            )}
                          </span>
                        </S.RemoveUploadedImageButton>
                      )}
                  </S.CustomImageButtonsContainer>

                  <S.CustomViewGeneralSettingsContainer>
                    <S.CustomViewGeneralSettingsTitle>
                      {props.t(
                        'createPortfolioConfiguration.customizeViewGeneralSettings',
                      )}
                    </S.CustomViewGeneralSettingsTitle>
                    <S.CustomViewGeneralSettingsInnerContainer>
                      <S.CustomViewGeneralSettingsEntry>
                        {props.t('createPortfolioConfiguration.showPicture')}
                        <ToggleButton
                          checked={showCustomizedMediaKitPicture}
                          setChecked={(checked: boolean) => {
                            configurationCustomizeViewToggleClicked(
                              checked,
                              setShowCustomizedMediaKitPicture,
                            );
                          }}
                          width={'auto'}
                          toggleContainerCursor={
                            showCustomizedMediaKitPicture &&
                            configurationUnderCustomizationShownFieldsCount ===
                              1
                              ? 'not-allowed'
                              : 'pointer'
                          }
                        />
                      </S.CustomViewGeneralSettingsEntry>
                      <S.CustomViewGeneralSettingsEntry>
                        {props.t('createPortfolioConfiguration.showIcon')}
                        <ToggleButton
                          checked={showCustomizedMediaKitIcon}
                          setChecked={(checked: boolean) => {
                            configurationCustomizeViewToggleClicked(
                              checked,
                              setShowCustomizedMediaKitIcon,
                            );
                          }}
                          width={'auto'}
                          toggleContainerCursor={
                            showCustomizedMediaKitIcon &&
                            configurationUnderCustomizationShownFieldsCount ===
                              1
                              ? 'not-allowed'
                              : 'pointer'
                          }
                        />
                      </S.CustomViewGeneralSettingsEntry>
                      <S.CustomViewGeneralSettingsEntry>
                        {props.t('createPortfolioConfiguration.showTitle')}
                        <ToggleButton
                          checked={showCustomizedMediaKitTitle}
                          setChecked={(checked: boolean) => {
                            configurationCustomizeViewToggleClicked(
                              checked,
                              setShowCustomizedMediaKitTitle,
                            );
                          }}
                          width={'auto'}
                          toggleContainerCursor={
                            showCustomizedMediaKitTitle &&
                            configurationUnderCustomizationShownFieldsCount ===
                              1
                              ? 'not-allowed'
                              : 'pointer'
                          }
                        />
                      </S.CustomViewGeneralSettingsEntry>

                      <S.CustomViewGeneralSettingsEntry>
                        {props.t('createPortfolioConfiguration.showUsername')}
                        <ToggleButton
                          checked={showCustomizedMediaKitUsername}
                          setChecked={(checked: boolean) => {
                            configurationCustomizeViewToggleClicked(
                              checked,
                              setShowCustomizedMediaKitUsername,
                            );
                          }}
                          width={'auto'}
                          toggleContainerCursor={
                            showCustomizedMediaKitUsername &&
                            configurationUnderCustomizationShownFieldsCount ===
                              1
                              ? 'not-allowed'
                              : 'pointer'
                          }
                        />
                      </S.CustomViewGeneralSettingsEntry>
                    </S.CustomViewGeneralSettingsInnerContainer>
                  </S.CustomViewGeneralSettingsContainer>
                </S.CustomizeViewInnerContainer>
              </S.CustomizeViewContainer>
            </S.CustomizeViewOuterContainer>
          )
        }
        primaryButtonLeftMargin={'1rem'}
        modalWidth={'70rem'}
        isCancelable={true}
        primaryButtonAction={() => {
          setAddedMediaKitsCustomizableView(prevState => ({
            ...prevState,
            [configurationBeingCustomizedId as string]: {
              name: customizedMediaKitName,
              displayPictureFile: customizedMediaKitDisplayPictureFile,
              showPicture: showCustomizedMediaKitPicture,
              showIcon: showCustomizedMediaKitIcon,
              showTitle: showCustomizedMediaKitTitle,
              showUsername: showCustomizedMediaKitUsername,
            },
          }));
          setConfigurationBeingCustomizedId(null);
        }}
        primaryButtonText={props.t('generic.save')}
        primaryButtonDisabled={customizedMediaKitName.length === 0}
        cancelAction={() => setConfigurationBeingCustomizedId(null)}
      />

      <NotifyCustom
        location={'top'}
        show={isCreatingPortfolio || isUpdatingPortfolio}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {actionExecutingTooltipMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={
          showCustomPartnerCreatedSuccessfully ||
          showPortfolioCreatedSuccessfully ||
          showPortfolioUpdatedSuccessfully
        }
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {actionExecutedSuccessfullyTooltipMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />
    </S.Container>
  );
};

export default withTranslation()(CreatePortfolioConfiguration);
