/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

import { SocialNetworkFilterIconProps } from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 75%;
  border-radius: 2.4rem;
  min-height: calc(100vh - 10.5rem);
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1270px) {
    width: 85%;
  }

  @media (max-width: 1150px) {
    width: 98%;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-height: calc(100vh - 5.7rem - 2rem);
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2rem;

  @media (max-width: 925px) {
    flex-direction: column;
  }
`;

export const UsernameFilterInputContainer = styled.div`
  width: 55rem;
  position: relative;

  &:hover input {
    border-color: rgb(41, 41, 41);
  }

  @media (max-width: 925px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const MagnifyingGlassContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const SocialNetworkFilterContainer = styled.div`
  display: flex;
  width: 20rem;

  @media (max-width: 925px) {
    width: 100%;
    justify-content: center;
  }
`;

export const SocialNetworkFilterInnerContainer = styled.div`
  width: 100%;

  @media (max-width: 925px) {
    width: 20rem;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const SocialNetworkFilterIcon = styled.div<SocialNetworkFilterIconProps>`
  display: flex;
  align-items: center;
  color: #292929;

  ${props => {
    if (props.globalEntry) {
      return css`
        & path {
          fill: #8494a9;
        }
      `;
    }
  }}
`;

export const BenchmarkListItemCard = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.3rem 0 rgb(239 244 255 / 0%);
  transition: all 0.3s ease;
  border-radius: 2rem;
  background-color: #fff;
  margin: 0 0.8rem 2.5rem 0.8rem;
  flex-wrap: wrap;
`;

export const CardInnerContainer = styled.div`
  padding: 0 3.2rem;

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const BenchmarkListItemNetwork = styled.div`
  flex: 0 0 8rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  min-width: 0;
  max-width: 100%;
  position: relative;
  width: 100%;
`;

export const BenchmarkListItemNetworkIcon = styled.img`
  width: 75%;
  vertical-align: middle;
  border-style: none;
`;

export const SocialAccountCardContainer = styled.div`
  width: 23rem;
  height: 100;

  @media (max-width: 1150px) {
    width: 50%;
  }
`;

export const NameContainer = styled.div`
  width: 24rem;

  @media (max-width: 1150px) {
    width: 50%;
  }
`;

export const DurationContainer = styled.div`
  width: 22rem;

  @media (max-width: 1150px) {
    width: 50%;
  }
`;

export const ViewsContainer = styled.div`
  width: 5rem;
  align-items: center;

  @media (max-width: 1150px) {
    width: 25%;
  }
`;

export const OpenContainer = styled.div`
  width: 3rem;

  @media (max-width: 1150px) {
    width: 25%;
  }
`;

export const BenchmarkListItemCol = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
`;

export const BenchmarkListItemNameContainer = styled.div`
  width: 100%;
`;

export const BenchmarkListItemTitle = styled.h2`
  font-size: 1.28rem;
  padding-top: 0;
  color: #8492a6;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const BenchmarkListItemLegend = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  text-overflow: unset;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
`;

export const BenchmarkListItemType = styled.div`
  width: 100%;
`;

export const ViewsColumnInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const EyeIconContainer = styled.div`
  margin-top: 2.92rem;

  & svg {
    cursor: pointer;
  }
`;

export const SocialAccountContainer = styled.div`
  height: 7rem;
  min-width: 20rem;
  max-width: 35rem;
  display: flex;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 1.5rem;
  padding: 0.5rem;
  margin-right: 2.5rem;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    border-color: rgb(180, 180, 180);
  }
`;

export const SocialAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SocialAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const SocialAccountNetworkIconContainer = styled.div`
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: white;
`;

export const SocialAccountCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex-grow: 1;
  max-width: 16rem;
  padding-left: 0.8rem;
`;

export const SocialAccountUsernameContainer = styled.div`
  max-width: 15rem;
  margin-bottom: 0.9rem;
  display: flex;
  align-items: center;
`;

export const SocialAccountUsername = styled.div`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const SocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  margin-top: 0.9rem;
`;

export const SocialAccountRightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
`;

export const SocialAccountVerified = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #14b56f;
  background-color: #e8f8f1;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1.4rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
`;

export const ConfigurationFiltersContainer = styled.div`
  width: 20rem;
  margin-bottom: 3rem;
`;

export const ConfigurationUsernameTooltip = styled(UncontrolledTooltip)`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0.5rem;
  }

  .arrow {
    display: none !important;
  }
`;

export const SocialAccountsInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: overlay;

  @media (max-width: 925px) {
    justify-content: center;
  }
`;

export const SocialAccountsEmpty = styled.div`
  display: flex;
  justify-content: center;
`;

export const SocialAccountsEmptyInner = styled.div`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.6rem;
  font-weight: 400;
  padding-top: 2rem;
  margin-bottom: 1rem;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const SocialAccountsEmptySecondaryMessage = styled.div`
  display: flex;
  padding-top: 0.5rem;
  white-space: pre;
`;

export const SocialAccountsEmptyLink = styled.div`
  color: #5870f6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
