import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeleteBenchmark } from '../api';
import { BenchmarkOutput } from '../types';

export const useViewBenchmark = () => {
  const navigate = useNavigate();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleConfirmDeleteModal = useCallback(() => {
    setShowConfirmDeleteModal(prevState => !prevState);
  }, []);

  const handleGoToUpdateBenchmark = useCallback(
    (data: BenchmarkOutput) => {
      navigate(`/update-benchmark/${data.benchmarkInfo.id}`, {
        state: {
          benchmarkData: data.benchmarkInfo,
        },
      });
    },
    [navigate],
  );

  const { mutateAsync: deleteBenchmark, isSuccess: isSuccessDeleteBenchmark } =
    useDeleteBenchmark();

  const handleDeleteBenchmark = useCallback(
    async (id: string) => {
      await deleteBenchmark(id);
    },
    [deleteBenchmark],
  );

  useEffect(() => {
    if (isSuccessDeleteBenchmark) {
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [isSuccessDeleteBenchmark, navigate]);

  return {
    handleDeleteBenchmark,
    showConfirmDeleteModal,
    handleConfirmDeleteModal,
    isSuccessDeleteBenchmark,
    handleGoToUpdateBenchmark,
  };
};
