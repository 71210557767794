import { t } from 'i18next';
import moment from 'moment';
import { useCallback } from 'react';
import EyeIcon from 'assets/iconComponents/EyeIcon';
import PostTypeTextIcon from 'assets/iconComponents/PostTypeTextIcon';
import PostTypeVideoIcon from 'assets/iconComponents/PostTypeVideoIcon';
import { DEFAULT_USER_AVATAR, SocialNetworks } from 'Services/Utils/types';
import PostTypeGalleryIcon from 'assets/iconComponents/PostTypeGalleryIcon';
import { PublicPost, PublicPostTypes } from 'Services/SkorrApi/new/Post/types';
import PostTypeSingleImageIcon from 'assets/iconComponents/PostTypeSingleImageIcon';
import {
  convertNumberToHumanStringfiedFormat,
  getSocialNetworkIcon,
} from 'Services/Utils/Utils';

import * as S from './Styles';

const CopyToClipboard = require('react-copy-to-clipboard');

type Props = {
  posts: Partial<PublicPost>[];
};

export function ListeningTermPosts({ posts }: Props) {
  const getFormattedPostKpiValue = useCallback((value?: number) => {
    if (value === -1) {
      return '-';
    }

    return convertNumberToHumanStringfiedFormat(value ?? 0);
  }, []);

  const getPostTypeIcon = (postType: PublicPostTypes) => {
    switch (postType) {
      case PublicPostTypes.STORY:
      case PublicPostTypes.IMAGE:
        return <PostTypeSingleImageIcon fill={'white'} />;
      case PublicPostTypes.CAROUSEL:
        return <PostTypeGalleryIcon fill={'white'} />;
      case PublicPostTypes.VIDEO:
        return <PostTypeVideoIcon fill={'white'} />;
      default:
        return <PostTypeTextIcon fill={'white'} />;
    }
  };

  const copySmartLinkButtonClicked = (event: any) => {
    event.stopPropagation();
    const notification = event.currentTarget.querySelector('span');
    notification.style.opacity = '1';
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (notification.style.opacity = '0'), 600);
  };

  const renderCopyContent = (
    post: Partial<PublicPost>,
    t: (path: string) => string,
  ) => {
    return (
      <S.CopyContentContainer
        onClick={event => copySmartLinkButtonClicked(event)}
      >
        <S.CopiedToClipboard>
          {t('mediaKit.post.description.copiedToClipboard')}
        </S.CopiedToClipboard>
        <CopyToClipboard text={post.content} id={`copyContent${post._id}`}>
          <span>
            <S.CopyIcon />
          </span>
        </CopyToClipboard>
        <S.CopyIconTooltip placement="top" target={`copyContent${post._id}`}>
          {t('mediaKit.post.description.copyText')}
        </S.CopyIconTooltip>
      </S.CopyContentContainer>
    );
  };

  return (
    <S.PostsContainer>
      {posts.map(post => (
        <S.OuterContainer key={post._id}>
          <S.InnerContainer>
            <S.HeaderContainer>
              <span>
                <S.HeaderAvatar src={post.image || DEFAULT_USER_AVATAR} />
              </span>
              <span>
                <S.HeaderName>@{post.socialNetwork?.username}</S.HeaderName>
                <S.HeaderDate>
                  {moment(new Date(post.date!)).format('YYYY-MM-DD')}
                </S.HeaderDate>
              </span>
            </S.HeaderContainer>

            <S.PictureContainer>
              <S.PictureOverlay>
                <S.PictureOverlayActions>
                  <S.PictureOverlayIcon
                    onClick={() => window.open(post.link, '_blank')}
                  >
                    <EyeIcon fill={'white'} width={'6rem'} height={'6rem'} />
                  </S.PictureOverlayIcon>
                </S.PictureOverlayActions>
              </S.PictureOverlay>

              <S.TypeIconContainer>
                {getPostTypeIcon(post.type!)}
              </S.TypeIconContainer>

              {post.image ? (
                <S.Picture src={post.image} />
              ) : (
                <S.TextContainer>
                  <p>{post.content}</p>
                </S.TextContainer>
              )}
            </S.PictureContainer>

            <S.DescriptionContainer>
              <S.SocialNetworkContainer socialNetwork={post.network!}>
                {getSocialNetworkIcon({
                  socialNetwork: post.network!,
                  width: '2rem',
                  height: '2rem',
                  gradientColored: true,
                })}
              </S.SocialNetworkContainer>

              {post.image ? (
                <S.DescriptionTextContainer>
                  <S.DescriptionText>{post.content}</S.DescriptionText>
                </S.DescriptionTextContainer>
              ) : (
                <span className="--hide-from-pdf">
                  <S.DescriptionTextContainer
                    typeCursor={true}
                    onClick={() => window.open(post.link, '_blank')}
                  >
                    <S.DescriptionText>
                      <S.ViewPostContainer>
                        {t('mediaKit.post.description.viewPost')}
                        <S.ViewPostIcon />
                      </S.ViewPostContainer>
                    </S.DescriptionText>
                  </S.DescriptionTextContainer>
                </span>
              )}

              {post.image && post.content && renderCopyContent(post, t)}
            </S.DescriptionContainer>

            <S.ReactionsContainer>
              {post.network === SocialNetworks.YOUTUBE &&
                post.type !== PublicPostTypes.STORY && (
                  <S.KpiContainer>
                    <S.KpiLabel>
                      {t('mediaKit.post.reactions.views')}
                    </S.KpiLabel>
                    <S.KpiValue>
                      {getFormattedPostKpiValue(post.kpi?.views)}
                    </S.KpiValue>
                  </S.KpiContainer>
                )}
              {post.type !== PublicPostTypes.STORY && (
                <S.KpiContainer>
                  <S.KpiLabel>{t('mediaKit.post.reactions.likes')}</S.KpiLabel>
                  <S.KpiValue>
                    {getFormattedPostKpiValue(post.kpi?.likes)}
                  </S.KpiValue>
                </S.KpiContainer>
              )}
              {post.type !== PublicPostTypes.STORY && (
                <S.KpiContainer>
                  <S.KpiLabel>
                    {t('mediaKit.post.reactions.comments')}
                  </S.KpiLabel>
                  <S.KpiValue>
                    {getFormattedPostKpiValue(post.kpi?.comments)}
                  </S.KpiValue>
                </S.KpiContainer>
              )}
              {post.type !== PublicPostTypes.STORY &&
                post.network !== SocialNetworks.YOUTUBE && (
                  <S.KpiContainer>
                    <S.KpiLabel>
                      {t('mediaKit.post.reactions.shares')}
                    </S.KpiLabel>
                    <S.KpiValue>
                      {getFormattedPostKpiValue(post.kpi?.shares)}
                    </S.KpiValue>
                  </S.KpiContainer>
                )}
            </S.ReactionsContainer>
          </S.InnerContainer>
        </S.OuterContainer>
      ))}
    </S.PostsContainer>
  );
}
