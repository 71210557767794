import { useDiscoverContext } from 'features/Discover/hooks/contexts/use-discover-context';
import { t } from 'i18next';
import { DateRangePicker } from 'react-date-range';
import { withTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

const DiscoverToolBarFilterSideBarCalendar = () => {
  const { setValue } = useFormContext();

  const {
    timeFrame,
    setTimeFrame,
    showTimeFramePicker,
    setShowTimeFramePicker,
  } = useDiscoverContext();

  return (
    <ClickOutHandler
      onClickOut={() => {
        setShowTimeFramePicker(false);

        const activeInputContainer = document.querySelector(
          '.rdrDateRangeWrapper .rdrDateDisplayItemActive',
        );

        activeInputContainer?.classList.remove('rdrDateDisplayItemActive');
      }}
    >
      <S.ToolBarFilterSideBarInputContainer>
        <S.ToolBarFilterSideBarInputTitle>
          {t('discover.timeframe')}
        </S.ToolBarFilterSideBarInputTitle>

        <S.ToolBarFilterSideBarTimeFrameInnerContainer>
          <S.ToolBarFilterSideBarTimeFrameCalendarContainer
            onClick={() => setShowTimeFramePicker(!showTimeFramePicker)}
          >
            {showTimeFramePicker ? t('discover.close') : t('discover.open')}
            {t('discover.calendar')}
          </S.ToolBarFilterSideBarTimeFrameCalendarContainer>

          <S.ToolBarFilterSideBarTimeFramePickerContainer
            visible={showTimeFramePicker}
          >
            <DateRangePicker
              ranges={timeFrame}
              editableDateInputs={true}
              retainEndDateOnFirstSelection={true}
              onChange={(item: any) => {
                setTimeFrame([item.selection]);
                setValue(
                  'filters.startDate',
                  item.selection.startDate.getTime(),
                );
                setValue('filters.endDate', item.selection.endDate.getTime());
              }}
            />
          </S.ToolBarFilterSideBarTimeFramePickerContainer>
        </S.ToolBarFilterSideBarTimeFrameInnerContainer>
      </S.ToolBarFilterSideBarInputContainer>
    </ClickOutHandler>
  );
};

export default withTranslation()(DiscoverToolBarFilterSideBarCalendar);
