import styled from 'styled-components';

export const EmptyState = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyStateIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;

  svg {
    width: 10rem;
    height: 10rem;
    vertical-align: middle;
  }
`;

export const EmptyStateTitle = styled.h2`
  color: #292929;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const EmptyStateDescription = styled.p`
  display: flex;
  color: #676767;
  font-weight: 400;
`;

export const EmptyStateOrText = styled.p`
  display: flex;
  margin: 2rem 0;
  color: #bfbfbf;
  font-weight: 400;
`;
