/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable no-empty-function */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable no-param-reassign */
/* eslint-disable no-self-assign */
/* eslint-disable vars-on-top */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable import/extensions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { DateRangePicker, Calendar } from 'react-date-range';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import VerifiedIconV1 from '../../assets/iconComponents/VerifiedIconV1';
import Button from '../../components/shared/Button/Button';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import {
  useGetPublicPostsByPublicUserId,
  useGetPublicUserById,
  useGetSelfUser,
} from '../../Services/Utils/CustomHooks';
import { getSocialNetworkIcon } from '../../Services/Utils/Utils';
import * as S from './Styles';
import { ICreateMediaKitConfigurationProps } from './types';
import CheckBox from '../../components/shared/Checkbox/CheckBox';
import { getShortenedMonthResourceKey } from '../../Services/Utils/dateUtils';
import GearIconV1 from '../../assets/iconComponents/GearIconV1';
import { getThemeStyles } from '../../css/ThemeGlobal';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import CrossIcon_V2 from '../../assets/iconComponents/CrossIcon_V2';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import Post from './Post';
import { PublicPostTypes } from '../../Services/SkorrApi/new/Post/types';
import {
  useGenerateExternalMediaKitUrl,
  useGetExternalMediaKitConfigurationById,
  useGetPublicPostsByPublicUserIdFilteredByKeywords,
  useUpdateExternalMediaKitById,
} from './RQCustomHooks';
import InfoV2Icon from '../../assets/iconComponents/InfoIconV2';
import { ExternalMediaKitConfigurationTypes } from '../../Services/SkorrApi/new/ExternalMediaKit/types';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';

const ClickOutHandler = require('react-onclickout');

enum PostTypeFilterValues {
  'ALL' = 'ALL',
  'PHOTOS' = 'PHOTOS',
  'VIDEOS' = 'VIDEOS',
}

const CreateMediaKitConfiguration: React.FC<
  ICreateMediaKitConfigurationProps
> = (props: ICreateMediaKitConfigurationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const themeGlobal = useTheme();
  const editMode = useMemo(() => location.pathname.includes('edit'), []);
  const [showNameMandatoryFieldMessage, setShowNameMandatoryFieldMessage] =
    useState(false);
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] = useState(true);
  const [isGetPublicUserByIdEnabled, setIsGetPublicUserByIdEnabled] =
    useState(false);
  const [
    isGetPublicPostsByPublicUserIdEnabled,
    setIsGetPublicPostsByPublicUserIdEnabled,
  ] = useState(false);
  const [
    isGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled,
    setIsGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled,
  ] = useState(false);
  const [
    isGetExternalMediaKitConfigurationByIdEnabled,
    setIsGetExternalMediaKitConfigurationByIdEnabled,
  ] = useState(() => editMode);
  const [
    isLoadingConfigurationToBeUpdatedData,
    setIsLoadingConfigurationToBeUpdatedData,
  ] = useState(editMode);
  const [publicUserIds, setPublicUserIds] = useState<string[]>([]);
  const [socialAccountSelected, setSocialAccountSelected] = useState('');
  const [socialAccountsAvailableValues, setSocialAccountsAvailableValues] =
    useState<{ value: string; label: any }[]>([]);
  const [timeFrame, setTimeFrame] = useState(() => {
    return [
      {
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        endDate: new Date(),
        key: 'selection',
      },
    ];
  });
  const [showTimeFramePicker, setShowTimeFramePicker] = useState(false);
  const [expirationDate, setExpirationDate] = useState<Date>(
    new Date(new Date().setMonth(new Date().getMonth() + 1)),
  );
  const [showExpirationDatePicker, setShowExpirationDatePicker] =
    useState(false);
  const [expirationDateSelected, setExpirationDateSelected] = useState(false);
  const getDateLabel = useCallback((date: Date) => {
    return `${props.t(
      getShortenedMonthResourceKey(date),
    )} ${date.getDate()}${`, ${date.getFullYear()}`}`;
  }, []);
  const [automaticUpdate, setAutomaticUpdate] = useState(false);
  const [filterContent, setFilterContent] = useState(false);
  const [showFilterContentPage, setShowFilterContentPage] = useState(false);
  const [filterByKeywords, setFilterByKeywords] = useState(true);
  const [selectPosts, setSelectPosts] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [name, setName] = useState(() => {
    const date = new Date();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${props.t(
      'createMediaKitConfiguration.mediaKit',
    )} ${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  });
  const [showMediaKitCreatedSuccessfully, setShowMediaKitCreatedSuccessfully] =
    useState(false);
  const [showMediaKitUpdatedSuccessfully, setShowMediaKitUpdatedSuccessfully] =
    useState(false);
  const [
    showPostOptionsSavedSuccessfully,
    setShowPostOptionsSavedSuccessfully,
  ] = useState(false);
  const [
    showCouldNotCreateMediaKitSuccessfully,
    setShowCouldNotCreateMediaKitSuccessfully,
  ] = useState(false);
  const [
    showCouldNotUpdateMediaKitSuccessfully,
    setShowCouldNotUpdateMediaKitSuccessfully,
  ] = useState(false);
  const [
    actionExecutedSuccessfullyTooltipMessage,
    setActionExecutedSuccessfullyTooltipMessage,
  ] = useState('');
  const keywordInputKeyRef = useRef(new Date().getTime());
  const [postTypeFilterSelected, setPostTypeFilterSelected] =
    useState<PostTypeFilterValues>(PostTypeFilterValues.ALL);
  const [timeFrameOfLastGetPublicPosts, setTimeFrameOfLastGetPublicPosts] =
    useState<{ startDate: number; endDate: number }>();
  const [keywordsOfLastGetPublicPosts, setKeywordsOfLastGetPublicPosts] =
    useState<string[]>([]);
  const [oldIncludedPostIds, setOldIncludedPostIds] = useState<string[]>([]);
  const [oldExcludedPostIds, setOldExcludedPostIds] = useState<string[]>([]);
  const [includedPostIds, setIncludedPostIds] = useState<string[]>([]);
  const [excludedPostIds, setExcludedPostIds] = useState<string[]>([]);
  const [postsRequestHasBeenMade, setPostsRequestHasBeenMade] = useState(false);
  const [
    postsFilteredByKeywordsRequestHasBeenMade,
    setPostsFilteredByKeywordsRequestHasBeenMade,
  ] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const setIsLoadingToFalseTimerId = useRef<NodeJS.Timeout>();
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: publicUsers,
    isFetching: isFetchingGetPublicUserById,
    isSuccess: isSuccessGetPublicUserById,
    isError: isErrorGetPublicUserById,
  } = useGetPublicUserById(isGetPublicUserByIdEnabled, publicUserIds);
  const {
    data: publicPosts,
    isFetching: isFetchingGetPublicPostsByPublicUserId,
    isSuccess: isSuccessGetPublicPostsByPublicUserId,
    isError: isErrorGetPublicPostsByPublicUserId,
  } = useGetPublicPostsByPublicUserId(isGetPublicPostsByPublicUserIdEnabled, {
    publicUserIds: [socialAccountSelected],
    startDate: timeFrameOfLastGetPublicPosts?.startDate!,
    endDate: timeFrameOfLastGetPublicPosts?.endDate!,
    sortBy: 'date',
    sortOrder: 'DESC',
  });
  const {
    data: publicPostsFilteredByKeywords,
    isFetching: isFetchingGetPublicPostsByPublicUserIdFilteredByKeywords,
    isSuccess: isSuccessGetPublicPostsByPublicUserIdFilteredByKeywords,
    isError: isErrorGetPublicPostsByPublicUserIdFilteredByKeywords,
  } = useGetPublicPostsByPublicUserIdFilteredByKeywords(
    isGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled,
    {
      publicUserId: socialAccountSelected,
      startDate: timeFrameOfLastGetPublicPosts?.startDate!,
      endDate: timeFrameOfLastGetPublicPosts?.endDate!,
      sortBy: 'date',
      sortOrder: 'DESC',
      keywords,
    },
  );
  const {
    data: mediaKitConfiguration,
    isFetching: isFetchingGetMediaKitConfiguration,
    isSuccess: isSuccessGetMediaKitConfigurationById,
    isError: isErrorGetMediaKitConfigurationById,
  } = useGetExternalMediaKitConfigurationById(
    isGetExternalMediaKitConfigurationByIdEnabled,
    params.id!,
  );
  const {
    isLoading: isLoadingGenerateExternalMediaKitUrl,
    isSuccess: isSuccessGenerateExternalMediaKitUrl,
    isError: isErrorGenerateExternalMediaKitUrl,
    mutate: generateExternalMediaKitUrl,
  } = useGenerateExternalMediaKitUrl();
  const {
    isLoading: isLoadingUpdateExternalMediaKitById,
    isSuccess: isSuccessUpdateExternalMediaKitById,
    isError: isErrorUpdateExternalMediaKitById,
    mutate: updateExternalMediaKitById,
  } = useUpdateExternalMediaKitById();
  const [
    disableCreateUpdateButtonAfterCreationUpdate,
    setDisableCreateUpdateButtonAfterCreationUpdate,
  ] = useState(false);
  const keywordRemove = useCallback(
    (toRemove: string) => {
      const keywordToRemove = toRemove.slice(0);
      const newKeywords = [];

      for (const keyword of keywords) {
        if (keyword !== keywordToRemove) {
          newKeywords.push(keyword);
        }
      }

      setKeywords(newKeywords);
    },
    [keywords],
  );
  const addKeywordActionInvoked = useCallback(() => {
    if (!keywords.find(item => item === keyword)) {
      setKeywords(prevState => [...prevState, keyword]);
      setKeyword('');
    }
  }, [keyword, keywords]);
  const isPostSelected = useCallback(
    (id: string) => {
      if (!keywords.length) {
        if (!excludedPostIds.find(excludedId => id === excludedId)) {
          return true;
        }

        return false;
      }
      if (
        publicPostsFilteredByKeywords?.find(
          item => item.id === id && item.keywordsMatched === true,
        )
      ) {
        if (!excludedPostIds.find(excludedId => excludedId === id)) {
          return true;
        }
        return false;
      }
      if (includedPostIds.find(includedId => includedId === id)) {
        return true;
      }
      return false;
    },
    [includedPostIds, excludedPostIds, keywords, publicPostsFilteredByKeywords],
  );
  const postSelectionActionInvoked = useCallback(
    (id: string, toInclude: boolean) => {
      if (toInclude) {
        if (excludedPostIds.find(excludedId => excludedId === id)) {
          const newExcludedPostIds = excludedPostIds.filter(
            excludedId => excludedId !== id,
          );

          setExcludedPostIds(newExcludedPostIds);
        } else {
          setIncludedPostIds(prevState => [...prevState, id]);
        }
      } else {
        if (includedPostIds.find(includedId => includedId === id)) {
          const newIncludedPostIds = includedPostIds.filter(
            includedId => includedId !== id,
          );

          setIncludedPostIds(newIncludedPostIds);
        } else {
          setExcludedPostIds(prevState => [...prevState, id]);
        }
      }
    },
    [includedPostIds, excludedPostIds],
  );
  const isPostVisible = useCallback(
    (postType: PublicPostTypes) => {
      if (postTypeFilterSelected === PostTypeFilterValues.ALL) {
        return true;
      }
      if (
        postTypeFilterSelected === PostTypeFilterValues.PHOTOS &&
        (postType === PublicPostTypes.IMAGE ||
          postType === PublicPostTypes.PHOTO ||
          postType === PublicPostTypes.CAROUSEL)
      ) {
        return true;
      }
      if (
        postTypeFilterSelected === PostTypeFilterValues.VIDEOS &&
        (postType === PublicPostTypes.VIDEO ||
          postType === PublicPostTypes.STORY)
      ) {
        return true;
      }

      return false;
    },
    [publicPosts, postTypeFilterSelected],
  );
  const restoreSelectedPostsToPreviousSavedState = useCallback(() => {
    if (
      includedPostIds.length !== oldIncludedPostIds.length ||
      excludedPostIds.length !== oldExcludedPostIds.length ||
      !includedPostIds.every(includedId =>
        oldIncludedPostIds.find(oldIncludedId => oldIncludedId === includedId),
      ) ||
      !excludedPostIds.every(excludedId =>
        oldExcludedPostIds.find(oldExcludedId => oldExcludedId === excludedId),
      )
    ) {
      setIncludedPostIds(oldIncludedPostIds);
      setExcludedPostIds(oldExcludedPostIds);
    }
  }, [
    includedPostIds,
    excludedPostIds,
    oldIncludedPostIds,
    oldExcludedPostIds,
  ]);
  const isPostsRefetchRequired = useCallback(() => {
    if (
      !timeFrameOfLastGetPublicPosts ||
      timeFrame[0].startDate.getTime() !==
        timeFrameOfLastGetPublicPosts.startDate ||
      (automaticUpdate
        ? new Date().getDate() !=
            new Date(timeFrameOfLastGetPublicPosts.endDate).getDate() ||
          new Date().getMonth() !=
            new Date(timeFrameOfLastGetPublicPosts.endDate).getMonth() ||
          new Date().getFullYear() !=
            new Date(timeFrameOfLastGetPublicPosts.endDate).getFullYear()
        : timeFrame[0].endDate!.getTime() !==
          timeFrameOfLastGetPublicPosts.endDate)
    ) {
      setTimeFrameOfLastGetPublicPosts({
        startDate: timeFrame[0].startDate.getTime(),
        endDate: automaticUpdate
          ? new Date().getTime()
          : timeFrame[0].endDate!.getTime(),
      });
      setKeywordsOfLastGetPublicPosts([...keywords]);
      setIsGetPublicPostsByPublicUserIdEnabled(true);
      setIsGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled(true);
    } else {
      if (
        keywords.length !== keywordsOfLastGetPublicPosts.length ||
        !keywords.every(key =>
          keywordsOfLastGetPublicPosts.find(item => item === key),
        )
      ) {
        setKeywordsOfLastGetPublicPosts([...keywords]);
        setIsGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled(true);
      }
    }
  }, [
    timeFrame,
    timeFrameOfLastGetPublicPosts,
    automaticUpdate,
    keywords,
    keywordsOfLastGetPublicPosts,
  ]);
  const verifyExistenceOfPostOptionsToBeSaved = useCallback(() => {
    if (
      includedPostIds.length != oldIncludedPostIds.length ||
      excludedPostIds.length !== oldExcludedPostIds.length ||
      !includedPostIds.every(includedId =>
        oldIncludedPostIds.find(oldIncludedId => oldIncludedId == includedId),
      ) ||
      !excludedPostIds.every(excludedId =>
        oldExcludedPostIds.find(oldExcludedId => oldExcludedId == excludedId),
      )
    ) {
      return true;
    }

    return false;
  }, [
    includedPostIds,
    excludedPostIds,
    oldIncludedPostIds,
    oldExcludedPostIds,
  ]);

  useEffect(() => {
    const dateRangeInputContainers = document.querySelectorAll(
      '.rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay .rdrDateInput.rdrDateDisplayItem',
    );

    if (dateRangeInputContainers && dateRangeInputContainers.length) {
      for (const inputContainer of dateRangeInputContainers) {
        inputContainer.classList.remove('rdrDateDisplayItemActive');
        inputContainer.addEventListener('click', () => {
          inputContainer.classList.add('rdrDateDisplayItemActive');
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccessGetSelfUser) {
      if (!editMode) {
        const localPublicUserIds: string[] = [];

        selfUser.publicProfiles
          ?.sort((profileA, profileB) =>
            profileA.username < profileB.username ? -1 : 1,
          )
          .forEach(publicProfile => {
            localPublicUserIds.push(publicProfile.publicUserId!);
          });

        setPublicUserIds(localPublicUserIds);
        if (localPublicUserIds.length) {
          setIsGetPublicUserByIdEnabled(true);
        }
      }
    }
  }, [isSuccessGetSelfUser]);

  useEffect(() => {
    if (isSuccessGetMediaKitConfigurationById) {
      setPublicUserIds([mediaKitConfiguration?.targetId!]);
      setIsGetPublicUserByIdEnabled(true);

      const mediaKitConfigurationStartAt =
        mediaKitConfiguration.details?.startAt;
      const mediaKitConfigurationEndAt = mediaKitConfiguration.details?.endAt;
      const mediaKitConfigurationFilters = mediaKitConfiguration.filters;

      if (!mediaKitConfigurationEndAt) {
        setAutomaticUpdate(true);

        const dateRangeEndDateInputContainer = document.querySelectorAll(
          '.rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay .rdrDateInput.rdrDateDisplayItem',
        )[1];

        if (dateRangeEndDateInputContainer) {
          (dateRangeEndDateInputContainer as any).style.position = 'absolute';
          (dateRangeEndDateInputContainer as any).style.pointerEvents = 'none';
          (dateRangeEndDateInputContainer as any).style.opacity = 0;
          (dateRangeEndDateInputContainer as any).style.zIndex = '-999';
        }
      }

      setTimeFrame([
        {
          startDate: new Date(mediaKitConfigurationStartAt!),
          endDate: mediaKitConfigurationEndAt
            ? new Date(mediaKitConfigurationEndAt)
            : new Date(new Date().setMonth(new Date().getMonth() + 1)),
          key: 'selection',
        },
      ]);

      if (mediaKitConfiguration.expiredAt) {
        setExpirationDate(new Date(mediaKitConfiguration.expiredAt));
        setExpirationDateSelected(true);
      }

      if (
        mediaKitConfigurationFilters?.keywords?.length ||
        mediaKitConfigurationFilters?.includePostIds?.length ||
        mediaKitConfigurationFilters?.excludePostIds?.length
      ) {
        setKeywords(mediaKitConfigurationFilters.keywords ?? []);
        setIncludedPostIds(mediaKitConfigurationFilters.includePostIds ?? []);
        setExcludedPostIds(mediaKitConfigurationFilters.excludePostIds ?? []);
        setOldIncludedPostIds(
          mediaKitConfigurationFilters.includePostIds ?? [],
        );
        setOldExcludedPostIds(
          mediaKitConfigurationFilters.excludePostIds ?? [],
        );
        setFilterContent(true);
      }

      setName(mediaKitConfiguration.name!);

      setIsLoadingConfigurationToBeUpdatedData(false);
    }
  }, [isSuccessGetMediaKitConfigurationById]);

  useEffect(() => {
    if (isSuccessGetPublicUserById) {
      if (!editMode) {
        const accountAvailableValues: { value: string; label: any }[] = [];

        (selfUser!.publicProfiles! ?? []).forEach(publicProfile => {
          let verified = false;

          const publicUser = publicUsers.find(
            publicUser => publicUser._id === publicProfile.publicUserId,
          );

          if (publicUser && publicUser.socialUserId) {
            if (
              selfUser!.socialUsers?.find(
                item => String(item.socialUserId) === publicUser.socialUserId,
              )
            ) {
              verified = true;
            } else {
              verified = false;
            }
          }

          accountAvailableValues.push({
            value: publicProfile.publicUserId!,
            label: (
              <S.SocialAccountContainer>
                <S.SocialAccountLeftContainer>
                  <S.SocialAccountImage src={publicProfile.displayPicture} />
                  <S.SocialAccountNetworkIconContainer>
                    {getSocialNetworkIcon({
                      socialNetwork: publicProfile.socialNetwork!,
                      width: '2rem',
                      height: '2rem',
                      gradientColored: true,
                    })}
                  </S.SocialAccountNetworkIconContainer>
                </S.SocialAccountLeftContainer>

                <S.SocialAccountRightContainer>
                  <S.SocialAccountUsernameContainer>
                    <S.SocialAccountUsername
                      id={`public-profile-username-${publicProfile.publicUserId}`}
                    >
                      {`@${publicProfile.username}`}
                    </S.SocialAccountUsername>
                    {verified && (
                      <VerifiedIconV1
                        containerWidth="1.7rem"
                        containerHeight="1.7rem"
                        containerMarginLeft="0.3rem"
                        containerMarginTop="0.2rem"
                      />
                    )}
                  </S.SocialAccountUsernameContainer>
                  {publicProfile.username.length > 30 && (
                    <S.SocialAccountUsernameTooltip
                      placement="top"
                      target={`public-profile-username-${publicProfile.publicUserId}`}
                    >
                      {publicProfile.username}
                    </S.SocialAccountUsernameTooltip>
                  )}
                  <S.SocialAccountNetworkName>
                    {publicProfile.socialNetwork![0] +
                      publicProfile.socialNetwork!.slice(1).toLowerCase()}
                  </S.SocialAccountNetworkName>
                </S.SocialAccountRightContainer>
              </S.SocialAccountContainer>
            ),
          });
        });

        setSocialAccountsAvailableValues(accountAvailableValues);
        setSocialAccountSelected(accountAvailableValues[0].value);
      } else {
        let verified = false;
        const publicUser = publicUsers[0];
        const publicProfile = selfUser?.publicProfiles?.find(
          pp => pp.publicUserId === publicUser._id,
        );

        if (publicUser && publicUser.socialUserId) {
          if (
            selfUser!.socialUsers?.find(
              item => String(item.socialUserId) === publicUser.socialUserId,
            )
          ) {
            verified = true;
          } else {
            verified = false;
          }
        }

        const accountSelected = {
          value: publicUser._id,
          label: (
            <S.SocialAccountContainer>
              <S.SocialAccountLeftContainer>
                <S.SocialAccountImage
                  src={publicProfile?.displayPicture ?? publicUser.image}
                />
                <S.SocialAccountNetworkIconContainer>
                  {getSocialNetworkIcon({
                    socialNetwork: publicUser.socialNetwork.network,
                    width: '2rem',
                    height: '2rem',
                    gradientColored: true,
                  })}
                </S.SocialAccountNetworkIconContainer>
              </S.SocialAccountLeftContainer>

              <S.SocialAccountRightContainer>
                <S.SocialAccountUsernameContainer>
                  <S.SocialAccountUsername
                    id={`public-profile-username-${publicUser.socialNetwork.username}`}
                  >
                    {`@${publicUser.socialNetwork.username}`}
                  </S.SocialAccountUsername>
                  {verified && (
                    <VerifiedIconV1
                      containerWidth="1.7rem"
                      containerHeight="1.7rem"
                      containerMarginLeft="0.3rem"
                      containerMarginTop="0.2rem"
                    />
                  )}
                </S.SocialAccountUsernameContainer>
                {publicUser.socialNetwork.username.length > 30 && (
                  <S.SocialAccountUsernameTooltip
                    placement="top"
                    target={`public-profile-username-${publicUser._id}`}
                  >
                    {publicUser.socialNetwork.username}
                  </S.SocialAccountUsernameTooltip>
                )}
                <S.SocialAccountNetworkName>
                  {publicUser.socialNetwork.network[0] +
                    publicUser.socialNetwork.network.slice(1).toLowerCase()}
                </S.SocialAccountNetworkName>
              </S.SocialAccountRightContainer>
            </S.SocialAccountContainer>
          ),
        };

        setSocialAccountsAvailableValues([accountSelected]);
        setSocialAccountSelected(accountSelected.value);
      }
    }
  }, [isSuccessGetPublicUserById]);

  useEffect(() => {
    if (
      isErrorGetSelfUser ||
      isErrorGetPublicUserById ||
      isErrorGetPublicPostsByPublicUserId ||
      isErrorGetPublicPostsByPublicUserIdFilteredByKeywords ||
      isErrorGetMediaKitConfigurationById
    ) {
      setIsGetSelfUserEnabled(false);
      setIsGetPublicUserByIdEnabled(false);
      setIsGetPublicPostsByPublicUserIdEnabled(false);
      setIsGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled(false);
      setIsGetExternalMediaKitConfigurationByIdEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
    }

    if (isErrorGetMediaKitConfigurationById) {
      setIsLoadingConfigurationToBeUpdatedData(false);
    }

    if (isErrorGenerateExternalMediaKitUrl) {
      setShowCouldNotCreateMediaKitSuccessfully(true);
      setTimeout(() => setShowCouldNotCreateMediaKitSuccessfully(false), 3000);
    } else if (isErrorUpdateExternalMediaKitById) {
      setShowCouldNotUpdateMediaKitSuccessfully(true);
      setTimeout(() => setShowCouldNotUpdateMediaKitSuccessfully(false), 3000);
    }
  }, [
    isErrorGetSelfUser,
    isErrorGetPublicUserById,
    isErrorGetPublicPostsByPublicUserId,
    isErrorGetPublicPostsByPublicUserIdFilteredByKeywords,
    isErrorGenerateExternalMediaKitUrl,
    isErrorGetMediaKitConfigurationById,
    isErrorUpdateExternalMediaKitById,
  ]);

  useEffect(() => {
    if (
      isSuccessGetPublicPostsByPublicUserId ||
      isSuccessGetPublicPostsByPublicUserIdFilteredByKeywords
    ) {
      if (
        postsRequestHasBeenMade &&
        postsFilteredByKeywordsRequestHasBeenMade
      ) {
        setIncludedPostIds([]);
        setExcludedPostIds([]);
        setOldIncludedPostIds([]);
        setOldExcludedPostIds([]);
      }

      if (isSuccessGetPublicPostsByPublicUserId) {
        setIsGetPublicPostsByPublicUserIdEnabled(false);
        setPostsRequestHasBeenMade(true);
      } else if (isSuccessGetPublicPostsByPublicUserIdFilteredByKeywords) {
        setIsGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled(false);
        setPostsFilteredByKeywordsRequestHasBeenMade(true);
      }
    }
  }, [
    isSuccessGetPublicPostsByPublicUserId,
    isSuccessGetPublicPostsByPublicUserIdFilteredByKeywords,
  ]);

  useEffect(() => {
    if (isSuccessGenerateExternalMediaKitUrl) {
      setShowMediaKitCreatedSuccessfully(true);
      setTimeout(() => {
        setShowMediaKitCreatedSuccessfully(false);
        navigate('/view-media-kit-configurations-list');
      }, 2000);
      setActionExecutedSuccessfullyTooltipMessage(
        props.t('createMediaKitConfiguration.mediaKitCreated'),
      );
    }
  }, [isSuccessGenerateExternalMediaKitUrl]);

  useEffect(() => {
    if (isSuccessUpdateExternalMediaKitById) {
      setShowMediaKitUpdatedSuccessfully(true);
      setTimeout(() => {
        setShowMediaKitUpdatedSuccessfully(false);
        navigate('/view-media-kit-configurations-list');
      }, 2000);
      setActionExecutedSuccessfullyTooltipMessage(
        props.t('editMediaKitConfiguration.mediaKitUpdated'),
      );
    }
  }, [isSuccessUpdateExternalMediaKitById]);

  useEffect(() => {
    if (
      isSuccessGenerateExternalMediaKitUrl ||
      isSuccessUpdateExternalMediaKitById ||
      isLoadingGenerateExternalMediaKitUrl ||
      isLoadingUpdateExternalMediaKitById
    ) {
      setDisableCreateUpdateButtonAfterCreationUpdate(true);
    }
  }, [
    isSuccessGenerateExternalMediaKitUrl,
    isSuccessUpdateExternalMediaKitById,
    isLoadingGenerateExternalMediaKitUrl,
    isLoadingUpdateExternalMediaKitById,
  ]);

  useEffect(() => {
    if (
      isFetchingGetSelfUser ||
      isFetchingGetPublicUserById ||
      isFetchingGetMediaKitConfiguration ||
      isLoadingConfigurationToBeUpdatedData
    ) {
      setIsLoading(true);

      if (setIsLoadingToFalseTimerId.current) {
        clearTimeout(setIsLoadingToFalseTimerId.current);
      }
    } else {
      if (setIsLoadingToFalseTimerId.current) {
        clearTimeout(setIsLoadingToFalseTimerId.current);
      }

      setIsLoadingToFalseTimerId.current = setTimeout(
        () => setIsLoading(false),
        200,
      );
    }
  }, [
    isFetchingGetSelfUser ||
      isFetchingGetPublicUserById ||
      isFetchingGetMediaKitConfiguration ||
      isLoadingConfigurationToBeUpdatedData,
  ]);

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    } else if (isErrorGetPublicUserById) {
      setIsGetPublicUserByIdEnabled(true);
    } else if (isErrorGetPublicPostsByPublicUserId) {
      setIsGetPublicPostsByPublicUserIdEnabled(true);
    } else if (isErrorGetPublicPostsByPublicUserIdFilteredByKeywords) {
      setIsGetPublicPostsByPublicUserIdFilteredByKeywordsEnabled(true);
    } else if (isErrorGetMediaKitConfigurationById) {
      setIsGetExternalMediaKitConfigurationByIdEnabled(true);
      setIsLoadingConfigurationToBeUpdatedData(true);
    }
  };

  return (
    <S.Container>
      <Loader show={isLoading} message={props.t('generic.loadingData')} />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitlesContainer>
                <S.HeaderTitleContainer>
                  {!editMode
                    ? props.t('createMediaKitConfiguration.title')
                    : props.t('editMediaKitConfiguration.title')}
                </S.HeaderTitleContainer>

                <S.HeaderSubtitleContainer>
                  {props.t('createMediaKitConfiguration.subtitle')}
                </S.HeaderSubtitleContainer>
              </S.HeaderTitlesContainer>

              <S.PageCrossIconContainer
                onClick={() => navigate('/view-media-kit-configurations-list')}
              >
                <CrossIcon_V2
                  containerWidth="3.3rem"
                  containerHeight="3.3rem"
                />
              </S.PageCrossIconContainer>
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          {isSuccessGetSelfUser &&
            !isFetchingGetSelfUser &&
            ((selfUser.publicProfiles?.length ?? 0) > 0 ? (
              <S.CardInnerContainer>
                <S.MainSectionsOuterMostContainer
                  visible={!isLoadingConfigurationToBeUpdatedData}
                >
                  {!showFilterContentPage ? (
                    <S.MainSectionsOuterContainer>
                      <S.MainSectionsContainer>
                        <S.SocialAccountFilterContainer editMode={editMode}>
                          <S.SocialAccountFilterTitle>
                            {props.t(
                              'createMediaKitConfiguration.socialAccountFilterTitle',
                            )}
                          </S.SocialAccountFilterTitle>
                          <ComboBox
                            valueKey={'socialAccountSelected'}
                            value={socialAccountSelected}
                            type={'single'}
                            width={'auto'}
                            borderRadius={'0.6rem'}
                            valuesAvailable={socialAccountsAvailableValues}
                            isFilterable={true}
                            isClearable={false}
                            onChange={(obj: {
                              socialAccountSelected: string;
                            }) => {
                              setSocialAccountSelected(
                                obj.socialAccountSelected,
                              );
                            }}
                            height={'8.3rem'}
                            availableOptionsContainerBorderRadius={'0.6rem'}
                            optionContainerBorderRadius={'0.6rem'}
                            innerOptionsContainerMarginTop={'1.2rem'}
                            innerOptionsContainerMarginBottom={'1.2rem'}
                            innerOptionsContainerPaddingLeft={'1.2rem'}
                            innerOptionsContainerPaddingRight={'1.2rem'}
                            optionContainerPaddingTop={'2rem'}
                            optionContainerPaddingBottom={'2rem'}
                            optionContainerPaddingLeft={'2rem'}
                            optionContainerPaddingRight={'2rem'}
                            themeStyles={
                              (themeGlobal as any).comboBoxTopicsOfInterest
                            }
                            changeBackgroundColorOnHover={false}
                            innerOptionsContainerMaxHeight={'25rem'}
                            arrowFontSize={'2.5rem'}
                          />
                        </S.SocialAccountFilterContainer>

                        <ClickOutHandler
                          onClickOut={() => {
                            setShowTimeFramePicker(false);

                            const activeInputContainer = document.querySelector(
                              '.rdrDateRangeWrapper .rdrDateDisplayItemActive',
                            );

                            activeInputContainer?.classList.remove(
                              'rdrDateDisplayItemActive',
                            );
                          }}
                        >
                          <S.TimeFrameContainer disabled={automaticUpdate}>
                            <S.SocialAccountFilterTitle>
                              {props.t(
                                'createMediaKitConfiguration.timeRangeTitle',
                              )}
                            </S.SocialAccountFilterTitle>
                            <S.TimeFrameInnerContainer>
                              <S.TimeFramePickerContainer
                                visible={showTimeFramePicker}
                              >
                                <DateRangePicker
                                  editableDateInputs={true}
                                  onChange={(item: any) =>
                                    setTimeFrame([item.selection])
                                  }
                                  moveRangeOnFirstSelection={false}
                                  ranges={timeFrame}
                                />
                              </S.TimeFramePickerContainer>

                              <S.ArrowContainer
                                onClick={() =>
                                  setShowTimeFramePicker(
                                    prevState => !prevState,
                                  )
                                }
                              >
                                {showTimeFramePicker === false ? (
                                  <RiArrowDownSLine size={'2.5rem'} />
                                ) : (
                                  <RiArrowUpSLine size={'2.5rem'} />
                                )}
                              </S.ArrowContainer>
                            </S.TimeFrameInnerContainer>
                          </S.TimeFrameContainer>
                        </ClickOutHandler>

                        <ClickOutHandler
                          onClickOut={() => setShowExpirationDatePicker(false)}
                        >
                          <S.ExpirationDateContainer>
                            <S.SocialAccountFilterTitle>
                              {props.t(
                                'createMediaKitConfiguration.expirationDateTitle',
                              )}
                            </S.SocialAccountFilterTitle>
                            <S.ExpirationDateInnerContainer
                              active={expirationDateSelected}
                              onClick={() =>
                                setShowExpirationDatePicker(
                                  prevState => !prevState,
                                )
                              }
                            >
                              <S.ExpirationDateLabel>
                                {!expirationDateSelected
                                  ? props.t(
                                      'createMediaKitConfiguration.neverExpires',
                                    )
                                  : getDateLabel(expirationDate)}
                              </S.ExpirationDateLabel>
                              {showExpirationDatePicker && (
                                <S.ExpirationDatePickerContainer>
                                  <Calendar
                                    date={expirationDate}
                                    onChange={setExpirationDate}
                                  />
                                </S.ExpirationDatePickerContainer>
                              )}

                              <S.ArrowContainer
                                disabled={!expirationDateSelected}
                              >
                                {showExpirationDatePicker === false ? (
                                  <RiArrowDownSLine size={'2.5rem'} />
                                ) : (
                                  <RiArrowUpSLine size={'2.5rem'} />
                                )}
                              </S.ArrowContainer>

                              <CheckBox
                                checked={expirationDateSelected}
                                onChange={() =>
                                  setExpirationDateSelected(prevState => {
                                    if (prevState) {
                                      setShowExpirationDatePicker(false);
                                    }
                                    return !prevState;
                                  })
                                }
                                marginLeft={'0.5rem'}
                                checkBoxContainerWidth={'1.8rem'}
                                checkBoxContainerHeight={'1.8rem'}
                                borderWidth={'0.25rem'}
                              />
                            </S.ExpirationDateInnerContainer>
                          </S.ExpirationDateContainer>
                        </ClickOutHandler>

                        <S.TimeFrameContainer>
                          <S.SocialAccountFilterTitle>
                            {props.t(
                              'createMediaKitConfiguration.automaticUpdate',
                            )}
                          </S.SocialAccountFilterTitle>
                          <S.AutomaticUpdateInnerContainer>
                            <S.AutomaticUpdateLabel>
                              {props.t(
                                'createMediaKitConfiguration.automaticUpdateDescription',
                              )}
                            </S.AutomaticUpdateLabel>

                            <CheckBox
                              checked={automaticUpdate}
                              onChange={() => {
                                setAutomaticUpdate(prevState => !prevState);

                                const dateRangeEndDateInputContainer =
                                  document.querySelectorAll(
                                    '.rdrDateRangeWrapper .rdrDateDisplayWrapper .rdrDateDisplay .rdrDateInput.rdrDateDisplayItem',
                                  )[1];

                                if (dateRangeEndDateInputContainer) {
                                  if (!automaticUpdate) {
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.position = 'absolute';
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.pointerEvents = 'none';
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.opacity = 0;
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.zIndex = '-999';
                                  } else {
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.position = 'static';
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.pointerEvents = 'all';
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.opacity = 1;
                                    (
                                      dateRangeEndDateInputContainer as any
                                    ).style.zIndex = '9999';
                                  }
                                }
                              }}
                              marginLeft={'auto'}
                              checkBoxContainerWidth={'1.8rem'}
                              checkBoxContainerHeight={'1.8rem'}
                              borderWidth={'0.25rem'}
                            />
                          </S.AutomaticUpdateInnerContainer>
                        </S.TimeFrameContainer>

                        <S.TimeFrameContainer>
                          <S.SocialAccountFilterTitle>
                            {props.t(
                              'createMediaKitConfiguration.filterContent',
                            )}
                          </S.SocialAccountFilterTitle>
                          <S.AutomaticUpdateInnerContainer>
                            <S.AutomaticUpdateLabel>
                              {props.t(
                                'createMediaKitConfiguration.filterContentDescription',
                              )}
                            </S.AutomaticUpdateLabel>

                            <S.GearIconContainer
                              onClick={() => setShowFilterContentPage(true)}
                              disabled={!filterContent}
                            >
                              <GearIconV1
                                svgWidth="2.2rem"
                                svgHeight="2.2rem"
                                containerMarginLeft="0.5rem"
                                containerPaddingTop="0.5rem"
                                containerPaddingBottom="0.5rem"
                                containerPaddingLeft="0.5rem"
                                containerPaddingRight="0.5rem"
                              />
                            </S.GearIconContainer>

                            <CheckBox
                              checked={filterContent}
                              onChange={() =>
                                setFilterContent(prevState => !prevState)
                              }
                              marginLeft={'0.5rem'}
                              checkBoxContainerWidth={'1.8rem'}
                              checkBoxContainerHeight={'1.8rem'}
                              borderWidth={'0.25rem'}
                            />
                          </S.AutomaticUpdateInnerContainer>
                        </S.TimeFrameContainer>

                        <S.TimeFrameContainer>
                          <S.SocialAccountFilterTitle>
                            {props.t('createMediaKitConfiguration.name')}
                          </S.SocialAccountFilterTitle>
                          <S.AutomaticUpdateInnerContainer>
                            <InputControlV2
                              value={name}
                              onChange={(inputValue: string) =>
                                setName(inputValue)
                              }
                              focusOnMount={false}
                              inputFieldContainerWidth={'100%'}
                              placeholder={props.t(
                                'createMediaKitConfiguration.nameInputPlaceholder',
                              )}
                              forceErrorDisplay={
                                showNameMandatoryFieldMessage
                                  ? props.t('inputControl.mandatoryField')
                                  : ''
                              }
                              forceBorderColorRed={
                                showNameMandatoryFieldMessage
                              }
                              onFocus={() =>
                                setShowNameMandatoryFieldMessage(false)
                              }
                              onBlur={() => {
                                if (!name.length)
                                  setShowNameMandatoryFieldMessage(true);
                              }}
                              warningMessageContainerPositionAbsolute={true}
                              warningMessageContainerTopOffset={'0.1rem'}
                              warningMessageContainerLeftOffset={'0.2rem'}
                            />
                          </S.AutomaticUpdateInnerContainer>
                        </S.TimeFrameContainer>
                      </S.MainSectionsContainer>
                      {!editMode && (
                        <S.MediaKitEditionMessage>
                          <S.MediaKitEditionMessageInner>
                            <InfoV2Icon
                              containerWidth="3rem"
                              containerHeight="3rem"
                              containerMarginRight="1.5rem"
                            />
                            {props.t(
                              'createMediaKitConfiguration.mediaKitEditionMessage',
                            )}
                          </S.MediaKitEditionMessageInner>
                        </S.MediaKitEditionMessage>
                      )}
                    </S.MainSectionsOuterContainer>
                  ) : (
                    <S.FilterContentPageContainer>
                      <S.FilterContentOptionsContainer>
                        <S.FilterContentOptionsRowsContainer>
                          <S.FilterContentOptionsRow>
                            <Button
                              cursorOnDisable={'pointer'}
                              blockHoverStylesOnDisable={false}
                              pointerEventsOnDisable={'all'}
                              themeStyles={
                                (themeGlobal as any)
                                  .listExternalMediaKitConfigurations
                                  .configurationTypeBeingDisplayed
                              }
                              disabledNotNative={selectPosts}
                              onClick={() => {
                                setFilterByKeywords(true);
                                setSelectPosts(false);
                                if (!filterByKeywords) {
                                  keywordInputKeyRef.current =
                                    new Date().getTime();
                                }
                              }}
                            >
                              {props.t(
                                'createMediaKitConfiguration.filterByKeywords',
                              )}
                            </Button>

                            {keywords.length > 0 && (
                              <S.CheckMarkIconContainer>
                                <CheckMarkIcon
                                  containerWidth="3.9rem"
                                  containerHeight="3.9rem"
                                  containerMarginLeft="1rem"
                                />
                              </S.CheckMarkIconContainer>
                            )}
                          </S.FilterContentOptionsRow>

                          <S.FilterContentOptionsRow>
                            <Button
                              cursorOnDisable={'pointer'}
                              blockHoverStylesOnDisable={false}
                              pointerEventsOnDisable={'all'}
                              themeStyles={
                                (themeGlobal as any)
                                  .listExternalMediaKitConfigurations
                                  .configurationTypeBeingDisplayed
                              }
                              disabledNotNative={filterByKeywords}
                              onClick={() => {
                                if (!selectPosts) {
                                  isPostsRefetchRequired();
                                }

                                setFilterByKeywords(false);
                                setSelectPosts(true);
                              }}
                            >
                              {props.t(
                                'createMediaKitConfiguration.selectPosts',
                              )}
                            </Button>

                            {(includedPostIds.length > 0 ||
                              excludedPostIds.length > 0) && (
                              <S.CheckMarkIconContainer>
                                <CheckMarkIcon
                                  containerWidth="3.9rem"
                                  containerHeight="3.9rem"
                                  containerMarginLeft="1rem"
                                />
                              </S.CheckMarkIconContainer>
                            )}
                          </S.FilterContentOptionsRow>
                        </S.FilterContentOptionsRowsContainer>

                        {selectPosts && (
                          <S.PostsOptionsContainer>
                            <S.PostTypeButtonsContainer>
                              <S.PostTypeButton
                                active={
                                  postTypeFilterSelected ===
                                  PostTypeFilterValues.ALL
                                }
                                onClick={() =>
                                  setPostTypeFilterSelected(
                                    PostTypeFilterValues.ALL,
                                  )
                                }
                              >
                                {props.t(
                                  'createMediaKitConfiguration.postFilter.all',
                                )}
                              </S.PostTypeButton>
                              <S.PostTypeButton
                                active={
                                  postTypeFilterSelected ===
                                  PostTypeFilterValues.PHOTOS
                                }
                                onClick={() =>
                                  setPostTypeFilterSelected(
                                    PostTypeFilterValues.PHOTOS,
                                  )
                                }
                              >
                                {props.t(
                                  'createMediaKitConfiguration.postFilter.photos',
                                )}
                              </S.PostTypeButton>
                              <S.PostTypeButton
                                active={
                                  postTypeFilterSelected ===
                                  PostTypeFilterValues.VIDEOS
                                }
                                onClick={() =>
                                  setPostTypeFilterSelected(
                                    PostTypeFilterValues.VIDEOS,
                                  )
                                }
                              >
                                {props.t(
                                  'createMediaKitConfiguration.postFilter.videos',
                                )}
                              </S.PostTypeButton>
                            </S.PostTypeButtonsContainer>

                            <Button
                              onClick={() => {
                                if (verifyExistenceOfPostOptionsToBeSaved()) {
                                  setOldIncludedPostIds(includedPostIds);
                                  setOldExcludedPostIds(excludedPostIds);
                                  setShowPostOptionsSavedSuccessfully(true);
                                  setTimeout(
                                    () =>
                                      setShowPostOptionsSavedSuccessfully(
                                        false,
                                      ),
                                    3000,
                                  );
                                  setActionExecutedSuccessfullyTooltipMessage(
                                    props.t(
                                      'createMediaKitConfiguration.postOptionsSaved',
                                    ),
                                  );
                                }
                              }}
                            >
                              {props.t(
                                'createMediaKitConfiguration.saveOptions',
                              )}
                            </Button>
                          </S.PostsOptionsContainer>
                        )}
                      </S.FilterContentOptionsContainer>
                      {filterByKeywords && (
                        <S.KeywordsMainSectionContainer>
                          <S.KeywordsInnerSectionContainer>
                            <S.KeywordsSectionFirstRow>
                              <InputControlV2
                                value={keyword}
                                onChange={(inputValue: string) =>
                                  setKeyword(inputValue)
                                }
                                enterKeyPressedHandler={() =>
                                  addKeywordActionInvoked()
                                }
                                focusOnMount={true}
                                key={keywordInputKeyRef.current}
                              />

                              <Button
                                onClick={() => addKeywordActionInvoked()}
                                disabled={keyword.length < 3}
                              >
                                {props.t('createMediaKitConfiguration.add')}
                              </Button>
                            </S.KeywordsSectionFirstRow>
                            <S.SelectedKeywordsContainer>
                              <S.SelectedKeywordsInnerContainer>
                                {keywords.map(item => (
                                  <S.SelectedKeyword>
                                    <S.SelectedKeywordText>{`#${item}`}</S.SelectedKeywordText>
                                    <S.SelectedKeywordCross
                                      onClick={() => keywordRemove(item)}
                                    >
                                      <CrossIcon_V2
                                        containerWidth="2rem"
                                        containerHeight="2rem"
                                      />
                                    </S.SelectedKeywordCross>
                                  </S.SelectedKeyword>
                                ))}
                              </S.SelectedKeywordsInnerContainer>
                            </S.SelectedKeywordsContainer>
                          </S.KeywordsInnerSectionContainer>
                        </S.KeywordsMainSectionContainer>
                      )}
                      {selectPosts && (
                        <S.PostsMainSectionContainer>
                          <S.PostsContainer>
                            {(isFetchingGetPublicPostsByPublicUserId ||
                              isFetchingGetPublicPostsByPublicUserIdFilteredByKeywords) && (
                              <S.LoadingPostDataSpinnerContainer>
                                <S.LoadingPostDataMessage>
                                  {props.t(
                                    'createMediaKitConfiguration.loadingPosts',
                                  )}
                                </S.LoadingPostDataMessage>
                                <InputSpinnerIcon
                                  containerWidth="3rem"
                                  containerHeight="3rem"
                                  containerMarginLeft="1rem"
                                />
                              </S.LoadingPostDataSpinnerContainer>
                            )}

                            <S.PostsInnerContainer>
                              {publicPosts &&
                                publicPosts.length > 0 &&
                                publicPosts.map(post => {
                                  if (isPostVisible(post.type) === true) {
                                    return (
                                      <Post
                                        post={post}
                                        user={
                                          publicUsers?.find(
                                            pu =>
                                              pu._id === socialAccountSelected,
                                          )!
                                        }
                                        isSelected={isPostSelected(post.id)}
                                        postSelectionActionInvoked={(
                                          postId: string,
                                          toInclude: boolean,
                                        ) =>
                                          postSelectionActionInvoked(
                                            postId,
                                            toInclude,
                                          )
                                        }
                                      />
                                    );
                                  }
                                  return null;
                                })}
                            </S.PostsInnerContainer>
                          </S.PostsContainer>
                        </S.PostsMainSectionContainer>
                      )}
                    </S.FilterContentPageContainer>
                  )}
                </S.MainSectionsOuterMostContainer>
              </S.CardInnerContainer>
            ) : (
              <S.SocialAccountsEmpty>
                <S.SocialAccountsEmptyInner>
                  <div>
                    {props.t('menu-navbar.noSocialAccountsPrimaryMessage')}
                  </div>
                  <S.SocialAccountsEmptySecondaryMessage>
                    <S.SocialAccountsEmptyLink
                      onClick={() => navigate('/social-accounts-management')}
                    >
                      {props.t(
                        'menu-navbar.noSocialAccountsSecondaryMessagePart1',
                      )}
                    </S.SocialAccountsEmptyLink>
                    {props.t(
                      'menu-navbar.noSocialAccountsSecondaryMessagePart2',
                    )}
                  </S.SocialAccountsEmptySecondaryMessage>
                </S.SocialAccountsEmptyInner>
              </S.SocialAccountsEmpty>
            ))}

          {isSuccessGetSelfUser &&
            !isFetchingGetSelfUser &&
            (selfUser.publicProfiles?.length ?? 0) > 0 && (
              <S.FooterContainer>
                {showFilterContentPage ? (
                  <Button
                    borderRadius={'0.6rem'}
                    borderWidth={'0rem'}
                    height={'4rem'}
                    fontSize={'1.6rem'}
                    textAlign={'center'}
                    fontWeight={'500'}
                    paddingRight={'1rem'}
                    marginRight={'auto'}
                    onClick={() => {
                      setShowFilterContentPage(false);
                      setSelectPosts(false);
                      setFilterByKeywords(true);
                      restoreSelectedPostsToPreviousSavedState();
                    }}
                    themeStyles={getThemeStyles(
                      ['button', 'secondary-1'],
                      themeGlobal,
                    )}
                  >
                    {props.t('createMediaKitConfiguration.return')}
                  </Button>
                ) : (
                  <S.CreateMediaKitFooter>
                    <Button
                      borderRadius={'0.6rem'}
                      borderWidth={'0rem'}
                      height={'4rem'}
                      fontSize={'1.6rem'}
                      textAlign={'center'}
                      fontWeight={'500'}
                      paddingRight={'1rem'}
                      marginRight={'1.5rem'}
                      onClick={() => {
                        navigate('/view-media-kit-configurations-list');
                      }}
                      themeStyles={getThemeStyles(
                        ['button', 'secondary-1'],
                        themeGlobal,
                      )}
                    >
                      {props.t('generic.cancel')}
                    </Button>

                    <Button
                      borderRadius={'0.6rem'}
                      borderWidth={'0rem'}
                      height={'4rem'}
                      fontSize={'1.6rem'}
                      textAlign={'center'}
                      fontWeight={'500'}
                      paddingRight={'1rem'}
                      disabledNotNative={
                        !name.length ||
                        disableCreateUpdateButtonAfterCreationUpdate
                      }
                      pointerEventsOnDisable={
                        disableCreateUpdateButtonAfterCreationUpdate
                          ? 'none'
                          : 'all'
                      }
                      cursorOnDisable={
                        disableCreateUpdateButtonAfterCreationUpdate
                          ? 'default'
                          : 'pointer'
                      }
                      themeStyles={
                        (themeGlobal as any).createExternalMediaKitConfiguration
                          .createButton
                      }
                      onClick={() => {
                        if (name.length) {
                          const data = {
                            type: ExternalMediaKitConfigurationTypes.EMK_PUBLIC_USER,
                            name,
                            targetId: socialAccountSelected,
                            expiredAt: expirationDateSelected
                              ? expirationDate.getTime()
                              : null,
                            socialNetwork: publicUsers?.find(
                              pu => pu._id == socialAccountSelected,
                            )?.socialNetwork.network!,
                            startAt: timeFrame[0].startDate.getTime(),
                            endAt: automaticUpdate
                              ? null
                              : timeFrame[0].endDate.getTime(),
                            filters: {
                              keywords: filterContent ? keywords : [],
                              includePostIds: filterContent
                                ? includedPostIds
                                : [],
                              excludePostIds: filterContent
                                ? excludedPostIds
                                : [],
                            },
                          };

                          if (!editMode) {
                            generateExternalMediaKitUrl(data);
                          } else {
                            updateExternalMediaKitById({
                              ...data,
                              id: params.id!,
                            });
                          }
                        } else {
                          setShowNameMandatoryFieldMessage(true);
                        }
                      }}
                    >
                      {!editMode
                        ? props.t('generic.create')
                        : props.t('generic.update')}
                    </Button>
                  </S.CreateMediaKitFooter>
                )}
              </S.FooterContainer>
            )}
        </S.Card>
      </S.InnerContainer>

      <NotifyCustom
        location={'top'}
        show={
          showCouldNotCreateMediaKitSuccessfully ||
          showCouldNotUpdateMediaKitSuccessfully
        }
        themeStyles={(themeGlobal as any).signin['error-notify']}
      >
        <S.NotifyInnerContainer>
          {!editMode
            ? props.t('createMediaKitConfiguration.couldNotCreateMediaKit')
            : props.t('createMediaKitConfiguration.couldNotUpdateMediaKit')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={
          isLoadingGenerateExternalMediaKitUrl ||
          isLoadingUpdateExternalMediaKitById
        }
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {!editMode
            ? props.t('createMediaKitConfiguration.creatingMediaKit')
            : props.t('editMediaKitConfiguration.updatingMediaKit')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={
          showMediaKitCreatedSuccessfully ||
          showPostOptionsSavedSuccessfully ||
          showMediaKitUpdatedSuccessfully
        }
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {actionExecutedSuccessfullyTooltipMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />
    </S.Container>
  );
};

export default withTranslation()(CreateMediaKitConfiguration);
