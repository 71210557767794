import EyeIcon from 'assets/iconComponents/EyeIcon';
import PostTypeGalleryIcon from 'assets/iconComponents/PostTypeGalleryIcon';
import PostTypeSingleImageIcon from 'assets/iconComponents/PostTypeSingleImageIcon';
import PostTypeTextIcon from 'assets/iconComponents/PostTypeTextIcon';
import PostTypeVideoIcon from 'assets/iconComponents/PostTypeVideoIcon';
import { Post, PostType } from 'features/Benchmark/types';
import { t } from 'i18next';
import moment from 'moment';
import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { DEFAULT_USER_AVATAR, SocialNetworks } from 'Services/Utils/types';
import {
  convertNumberToHumanStringfiedFormat,
  getSocialNetworkIcon,
} from 'Services/Utils/Utils';

import * as S from './Styles';

const CopyToClipboard = require('react-copy-to-clipboard');

type Props = {
  posts: Post[];
};

const BenchmarkPostsFeed = ({ posts }: Props) => {
  const getFormattedPostKpiValue = useCallback((value?: number) => {
    if (value === -1) {
      return '-';
    }

    return convertNumberToHumanStringfiedFormat(value ?? 0);
  }, []);

  const getPostTypeIcon = (postType: PostType) => {
    switch (postType) {
      case PostType.STORY:
      case PostType.IMAGE:
        return <PostTypeSingleImageIcon fill={'white'} />;
      case PostType.CAROUSEL:
        return <PostTypeGalleryIcon fill={'white'} />;
      case PostType.VIDEO:
        return <PostTypeVideoIcon fill={'white'} />;
      default:
        return <PostTypeTextIcon fill={'white'} />;
    }
  };

  const copySmartLinkButtonClicked = (event: any) => {
    event.stopPropagation();
    const notification = event.currentTarget.querySelector('span');
    notification.style.opacity = '1';
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (notification.style.opacity = '0'), 600);
  };

  const renderCopyContent = (post: Post, t: (path: string) => string) => {
    return (
      <S.CopyContentContainer
        onClick={event => copySmartLinkButtonClicked(event)}
      >
        <S.CopiedToClipboard>
          {t('mediaKit.post.description.copiedToClipboard')}
        </S.CopiedToClipboard>
        <CopyToClipboard text={post.content} id={`copyContent${post.id}`}>
          <span>
            <S.CopyIcon />
          </span>
        </CopyToClipboard>
        <S.CopyIconTooltip placement="top" target={`copyContent${post.id}`}>
          {t('mediaKit.post.description.copyText')}
        </S.CopyIconTooltip>
      </S.CopyContentContainer>
    );
  };

  return (
    <S.CardContainer>
      <S.CardTitle>Posts feed</S.CardTitle>

      <S.PostsContainer>
        {posts.map(post => (
          <S.OuterContainer key={post.id}>
            <S.InnerContainer>
              <S.HeaderContainer>
                <span>
                  <S.HeaderAvatar
                    src={post.user.displayPicture || DEFAULT_USER_AVATAR}
                  />
                </span>
                <span>
                  <S.HeaderName>@{post.user.username}</S.HeaderName>
                  <S.HeaderDate>
                    {moment(new Date(post.date)).format('YYYY-MM-DD')}
                  </S.HeaderDate>
                </span>
              </S.HeaderContainer>

              <S.PictureContainer>
                <S.PictureOverlay>
                  <S.PictureOverlayActions>
                    <S.PictureOverlayIcon
                      onClick={() => window.open(post.url, '_blank')}
                    >
                      <EyeIcon fill={'white'} width={'6rem'} height={'6rem'} />
                    </S.PictureOverlayIcon>
                  </S.PictureOverlayActions>
                </S.PictureOverlay>

                <S.TypeIconContainer>
                  {getPostTypeIcon(post.type)}
                </S.TypeIconContainer>

                {post.image ? (
                  <S.Picture src={post.image} />
                ) : (
                  <S.TextContainer>
                    <p>{post.content}</p>
                  </S.TextContainer>
                )}
              </S.PictureContainer>

              <S.DescriptionContainer>
                <S.SocialNetworkContainer
                  socialNetwork={post.user.socialNetwork}
                >
                  {getSocialNetworkIcon({
                    socialNetwork: post.user.socialNetwork,
                    width: '2rem',
                    height: '2rem',
                    gradientColored: true,
                  })}
                </S.SocialNetworkContainer>

                {post.image ? (
                  <S.DescriptionTextContainer>
                    <S.DescriptionText>{post.content}</S.DescriptionText>
                  </S.DescriptionTextContainer>
                ) : (
                  <span className="--hide-from-pdf">
                    <S.DescriptionTextContainer
                      typeCursor={true}
                      onClick={() => window.open(post.url, '_blank')}
                    >
                      <S.DescriptionText>
                        <S.ViewPostContainer>
                          {t('mediaKit.post.description.viewPost')}
                          <S.ViewPostIcon />
                        </S.ViewPostContainer>
                      </S.DescriptionText>
                    </S.DescriptionTextContainer>
                  </span>
                )}

                {post.image && post.content && renderCopyContent(post, t)}
              </S.DescriptionContainer>

              <S.ReactionsContainer>
                {post.user.socialNetwork === SocialNetworks.YOUTUBE &&
                  post.type !== PostType.STORY && (
                    <S.KpiContainer>
                      <S.KpiLabel>
                        {t('mediaKit.post.reactions.views')}
                      </S.KpiLabel>
                      <S.KpiValue>
                        {getFormattedPostKpiValue(post.kpis.views)}
                      </S.KpiValue>
                    </S.KpiContainer>
                  )}
                {post.type !== PostType.STORY && (
                  <S.KpiContainer>
                    <S.KpiLabel>
                      {t('mediaKit.post.reactions.likes')}
                    </S.KpiLabel>
                    <S.KpiValue>
                      {getFormattedPostKpiValue(post.kpis.likes)}
                    </S.KpiValue>
                  </S.KpiContainer>
                )}
                {post.type !== PostType.STORY && (
                  <S.KpiContainer>
                    <S.KpiLabel>
                      {t('mediaKit.post.reactions.comments')}
                    </S.KpiLabel>
                    <S.KpiValue>
                      {getFormattedPostKpiValue(post.kpis.comments)}
                    </S.KpiValue>
                  </S.KpiContainer>
                )}
                {post.type !== PostType.STORY &&
                  post.user.socialNetwork !== SocialNetworks.YOUTUBE && (
                    <S.KpiContainer>
                      <S.KpiLabel>
                        {t('mediaKit.post.reactions.shares')}
                      </S.KpiLabel>
                      <S.KpiValue>
                        {getFormattedPostKpiValue(post.kpis.shares)}
                      </S.KpiValue>
                    </S.KpiContainer>
                  )}
                {post.type === PostType.STORY && (
                  <S.KpiContainer>
                    <S.KpiLabel>
                      {t('mediaKit.post.reactions.reach')}
                    </S.KpiLabel>
                    <S.KpiValue>
                      {getFormattedPostKpiValue(post.kpis.reach)}
                    </S.KpiValue>
                  </S.KpiContainer>
                )}
                {post.type === PostType.STORY && (
                  <S.KpiContainer>
                    <S.KpiLabel>
                      {t('mediaKit.post.reactions.impressions')}
                    </S.KpiLabel>
                    <S.KpiValue>
                      {getFormattedPostKpiValue(post.kpis.impressions)}
                    </S.KpiValue>
                  </S.KpiContainer>
                )}
                {post.type === PostType.STORY && (
                  <S.KpiContainer>
                    <S.KpiLabel>
                      {t('mediaKit.post.reactions.interactions')}
                    </S.KpiLabel>
                    <S.KpiValue>
                      {getFormattedPostKpiValue(post.kpis.total_interactions)}
                    </S.KpiValue>
                  </S.KpiContainer>
                )}
              </S.ReactionsContainer>
            </S.InnerContainer>
          </S.OuterContainer>
        ))}
      </S.PostsContainer>
    </S.CardContainer>
  );
};

export default withTranslation()(BenchmarkPostsFeed);
