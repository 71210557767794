/* eslint-disable no-use-before-define */
import { SocialNetworks } from '../../../Utils/types';

export type GetPublicUserHashtagsSortedByMetricsParams = {
  publicUserId: string;
  startDate?: number;
  endDate?: number;
  sortBy?: SortByCriteria;
  sortOrder?: SortOrder;
  limit?: number;
  type?: PublicPostTypes;
  keywords?: string[];
  includePostIds?: string[];
  excludePostIds?: string[];
};

export type SortByCriteria =
  | 'comments'
  | 'likes'
  | 'saves'
  | 'shares'
  | 'engagementRate'
  | 'reactions'
  | 'count'
  | 'hashtag'
  | 'date';

export type SortOrder = 'ASC' | 'DESC';

export enum PublicPostTypes {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  PHOTO = 'PHOTO',
  CAROUSEL = 'CAROUSEL',
  STORY = 'STORY',
}

export type GetPublicUserHashtagsSortedByMetricsResponse = {
  [key: string]: {
    hashtag: string;
    comments: number;
    likes: number;
    saves: number;
    shares: number;
    engRate: number;
    reactions: number;
    count: number;
    weight: number;
  }[];
};

export type GetPublicPostsByPublicUserIdResponse = {
  id: string;
  publicUserId: string;
  socialUserId?: string;
  image: string;
  username: string;
  socialNetwork: SocialNetworks;
  date: Date;
  type: Exclude<PublicPostTypes, null>;
  content: string;
  url: string;
  insights: {
    comments?: number;
    likes?: number;
    saves?: number;
    shares?: number;
    engagementRate?: number;
    reactions?: number;
    total_interactions?: number;
    reach?: number;
    impressions?: number;
  };
}[];

export type GetPublicPostsByPublicUserIdFilteredByKeywordsResponse = {
  id: string;
  publicUserId: string;
  socialUserId?: string;
  image: string;
  username: string;
  socialNetwork: SocialNetworks;
  date: Date;
  type: Exclude<PublicPostTypes, null>;
  content: string;
  url: string;
  insights: {
    comments?: number;
    likes?: number;
    saves?: number;
    shares?: number;
    engagementRate?: number;
    reactions?: number;
    total_interactions?: number;
    reach?: number;
    impressions?: number;
  };
  keywordsMatched: boolean;
}[];

export interface IPublicPost {
  id: string;
  publicUserId: string;
  socialUserId?: string;
  image: string;
  username: string;
  socialNetwork: SocialNetworks;
  date: Date;
  type: Exclude<PublicPostTypes, null>;
  content: string;
  url: string;
  insights: {
    comments?: number;
    likes?: number;
    saves?: number;
    shares?: number;
    engagementRate?: number;
    reactions?: number;
    views?: number;
    total_interactions?: number;
    reach?: number;
    impressions?: number;
  };
}

export type PostSocialNetwork = {
  network: SocialNetworks;
  externalId: string;
  profileId: string;
  username: string;
};

export enum PrivacyLevels {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type PublicPost = {
  _id: string;
  socialNetwork?: PostSocialNetwork;
  network?: SocialNetworks;
  publicUserId: string;
  date: Date;
  link?: string;
  type?: PublicPostTypes;
  comments?: number;
  content?: string | null;
  countryCode?: string | null;
  hashTags?: string[];
  image?: string | null;
  imageExpireAt?: Date | null;
  likes?: number;
  saves?: number;
  mentions?: string[];
  privacyLevel?: PrivacyLevels;
  shares?: number;
  urls?: string[];
  kpi?: {
    comments?: number;
    likes?: number;
    saves?: number;
    shares?: number;
    views?: number;
    favorite?: number;
    engRate?: number;
    reactions?: number;
  };
  socialUserId?: string | null;
  isNew?: boolean;
  engRate?: number;
  reactions?: number;
  createdAt?: Date;
  updatedAt?: Date;
  aspectRatio: number;
};
