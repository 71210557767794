import { useMutation } from 'react-query';
import SkorrApi from 'Services/SkorrApi/new';
import { IErrorObject } from 'Services/Utils/types';
import { logApiRequestError } from 'Services/Utils/Utils';
import { GetPublicUsersByFilterRequest } from 'Services/SkorrApi/new/User/types';
import {
  GetHashtagsByFilterRequest,
  GetEvolutionByFilterRequest,
} from 'Services/SkorrApi/new/Post';

const { post, user } = SkorrApi;

export const useGetHashtagsByFilter = () => {
  return useMutation(
    async (params: GetHashtagsByFilterRequest) => {
      try {
        const response = await post.getHashtagsByFilter(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useGetEvolutionByFilter = () => {
  return useMutation(
    async (params: GetEvolutionByFilterRequest) => {
      try {
        const response = await post.getEvolutionByFilter(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useGetPublicPostsOrUsersByFilter = () => {
  return useMutation(
    async (params: GetPublicUsersByFilterRequest) => {
      try {
        const response = await user.getPublicUsersByFilter(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
