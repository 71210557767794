import styled from 'styled-components';

export const UsersListContainer = styled.div`
  margin-top: 3rem;
`;

export const SelectedUsersText = styled.h4`
  display: flex;
  color: #292929;
  white-space: pre;
  margin-left: 1rem;
  align-items: center;
  flex-direction: row;

  p {
    color: #696969;
    font-weight: 400;
  }
`;

export const FoundUsersText = styled.p`
  color: #696969;
  font-weight: 400;
  margin-top: 3rem;
  margin-left: 1rem;
`;

export const UsersListLoadingText = styled.p`
  color: #696969;
  font-weight: 400;
  margin-top: 3rem;
  margin-left: 1rem;
`;

export const ListItemOutContainer = styled.div<{ disable?: boolean }>`
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`;

export const ListItemContainer = styled.div<{ disable?: boolean }>`
  display: flex;
  margin: 2rem 0;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.6rem;
  background-color: white;
  justify-content: space-between;
  opacity: ${({ disable }) => (disable ? '50%' : '100%')};
  pointer-events: ${({ disable }) => (disable ? 'none' : 'all')};
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: 50%;
  }
`;

export const ListItemLeftSide = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
`;

export const ListItemUserInfo = styled.div`
  gap: 0.5rem;
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
`;

export const ListItemTitle = styled.h4`
  color: #292929;
`;

export const ListItemDate = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
`;

export const ListItemRightSide = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    width: 4rem;
    height: 4rem;
  }
`;

export const ListItemAvatarContainer = styled.div`
  display: flex;
  align-items: flex-end;

  div {
    padding: 0.2rem;
    margin-left: -1rem;
    border-radius: 0.6rem;
    margin-bottom: -0.4rem;
    background-color: white;
  }
`;

export const ListItemAvatarImg = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
`;

export const ListItemDivider = styled.div`
  opacity: 50%;
  height: 0.1rem;
  background-color: #dbdbdb;
`;
