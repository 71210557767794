import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { SocialNetworks } from 'Services/Utils/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  SortOrder,
  ResultTypes,
  SortByFields,
} from 'Services/SkorrApi/new/User/types';

import {
  useGetHashtagsByFilter,
  useGetEvolutionByFilter,
  useGetPublicPostsOrUsersByFilter,
} from '../api';

type ParamsFilters = {
  endDate: number;
  startDate: number;
  keywords: string[];
  countryCode: string[];
  network: SocialNetworks[] | undefined;
};

const wordCloudOptions = {
  colors: [
    '#52ACFE',
    '#2C9AFE',
    '#108AF9',
    '#067EEB',
    '#0271D5',
    '#0264BD',
    '#0559A4',
    '#024F94',
  ],
  padding: 2,
  rotations: 1,
  scale: 'sqrt',
  fontSizes: [10, 50],
  enableTooltip: true,
  deterministic: false,
  spiral: 'archimedean',
  fontFamily: 'Poppins',
  rotationAngles: [0, 90],
  transitionDuration: 1000,
};

export const useListeningTerm = () => {
  const { term } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const { filters }: { filters: ParamsFilters } = state || {};

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, []);

  const diffDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days',
  );

  const timeFrame = `${moment(filters.startDate).format('MMM D')} - ${moment(
    filters.endDate,
  ).format('ll')} (${diffDays} days)`;

  const { data: hashtagsByFilter, mutateAsync: getHashtagsByFilter } =
    useGetHashtagsByFilter();

  const noHashtagsData = hashtagsByFilter?.length === 0;

  const termData = hashtagsByFilter?.filter(tag => tag.hashtag === term)[0];

  const relatedTopics = hashtagsByFilter?.map(tag => {
    return {
      text: tag.hashtag,
      value: tag.numPosts,
    };
  });

  const handleGetHashtagsByFilter = useCallback(async () => {
    await getHashtagsByFilter({
      filters,
    });
  }, [getHashtagsByFilter, filters]);

  const { data: evolutionByFilter, mutateAsync: getEvolutionByFilter } =
    useGetEvolutionByFilter();

  const chartData = useCallback(() => {
    const categoriesData: string[] = [];
    const avgEngRateData: number[] = [];

    (evolutionByFilter || []).forEach(item => {
      categoriesData.push(item.day);
      avgEngRateData.push(item.kpis.avgEngRate * 100);
    });

    return {
      categories: categoriesData,
      lineData: [{ data: avgEngRateData, name: term }],
    };
  }, [evolutionByFilter, term]);

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
      connectNulls: true,
    },
    xaxis: {
      tickAmount: 6,
      type: 'datetime',
      categories: chartData().categories,
      labels: {
        offsetY: -0,
        rotate: -45,
        trim: false,
        rotateAlways: false,
        hideOverlappingLabels: true,
      },
    },
    markers: {
      size: 3,
      opacity: 0.5,
      strokeWidth: 1,
      strokeColor: '#fff',
      hover: {
        size: 5,
      },
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: 7,
        formatter(tickAmount: number) {
          return `${tickAmount.toFixed(1)} %`;
        },
      },
    },
  };

  const handleGetEvolutionByFilter = useCallback(async () => {
    await getEvolutionByFilter({
      filters,
    });
  }, [getEvolutionByFilter, filters]);

  const { data: publicPostsByFilter, mutateAsync: getPublicPostsByFilter } =
    useGetPublicPostsOrUsersByFilter();

  const handleGetPublicPostsByFilter = useCallback(async () => {
    await getPublicPostsByFilter({
      filters,
      resultType: ResultTypes.POSTS,
      page: 0,
      limit: 50,
      sort: 'date' as SortByFields,
      sortOrder: SortOrder.DESC,
    });
  }, [getPublicPostsByFilter, filters]);

  const { data: top5Users, mutateAsync: getTop5UsersByFilter } =
    useGetPublicPostsOrUsersByFilter();

  const handleGetTop5UsersByFilter = useCallback(async () => {
    await getTop5UsersByFilter({
      filters,
      resultType: ResultTypes.USERS,
      page: 0,
      limit: 5,
      sort: SortByFields.engagement_rate,
      sortOrder: SortOrder.DESC,
    });
  }, [getPublicPostsByFilter, filters]);

  useEffect(() => {
    if (term) {
      handleGetHashtagsByFilter();
      handleGetEvolutionByFilter();
      handleGetPublicPostsByFilter();
      handleGetTop5UsersByFilter();
    }
  }, [
    term,
    handleGetHashtagsByFilter,
    handleGetEvolutionByFilter,
    handleGetPublicPostsByFilter,
    handleGetTop5UsersByFilter,
  ]);

  return {
    term,
    termData,
    timeFrame,
    top5Users,
    chartData,
    chartOptions,
    handleGoBack,
    relatedTopics,
    noHashtagsData,
    wordCloudOptions,
    hashtagsByFilter,
    evolutionByFilter,
    publicPostsByFilter,
  };
};
