import styled from 'styled-components';

export const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 2rem;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GoBackButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const MainTitle = styled.h1`
  color: #292929;
`;

export const Timeframe = styled.div`
  display: flex;
  margin-top: 3rem;
  justify-content: flex-end;

  p {
    background: #fff;
    font-size: 1.4rem;
    min-height: 3.2rem;
    align-content: center;
    border-radius: 1.9rem;
    padding: 0.4rem 1.4rem;
    color: rgb(41, 67, 93);
  }
`;
