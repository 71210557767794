import { t } from 'i18next';
import { useTheme } from 'styled-components';
import ComboBox from 'components/shared/ComboBox/ComboBox';

import * as S from './Styles';

type Props = {
  title: string;
  value: any;
  valueKey: string;
  optionValues: any;
  placeholder: string;
  isClearable?: boolean;
  isFilterable?: boolean;
  setValue: (value: any) => void;
};

export function ListeningFilterDropdown({
  title,
  value,
  setValue,
  valueKey,
  optionValues,
  placeholder,
  isClearable = false,
  isFilterable = false,
}: Props) {
  const themeGlobal = useTheme();

  return (
    <S.InputContainer>
      <S.InputTitle>{t(`discover.${title}`)}</S.InputTitle>

      <ComboBox
        width="100%"
        type="single"
        height="4.4rem"
        value={value}
        valueKey={valueKey}
        borderRadius={'0.6rem'}
        placeholder={placeholder}
        valuesAvailable={optionValues}
        isClearable={isClearable}
        isFilterable={isFilterable}
        onChange={(obj: any) => {
          if (valueKey === 'countryCode') {
            setValue(obj?.[valueKey]?.value ?? obj?.[valueKey]);
          } else {
            setValue(obj?.[valueKey]);
          }
        }}
        optionContainerBorderRadius={'0.6rem'}
        availableOptionsContainerBorderRadius={'0.6rem'}
        innerOptionsContainerPaddingTop={'1.2rem'}
        innerOptionsContainerPaddingBottom={'1.2rem'}
        innerOptionsContainerPaddingLeft={'1.2rem'}
        innerOptionsContainerPaddingRight={'1.2rem'}
        innerOptionsContainerTextAlign={'start'}
        optionContainerPaddingTop={'1rem'}
        optionContainerPaddingBottom={'1rem'}
        optionContainerPaddingLeft={'1rem'}
        optionContainerPaddingRight={'1rem'}
        innerOptionsContainerMaxHeight={'25rem'}
        optionSelectedContainerPaddingLeft={'0'}
        changeBackgroundColorOnHover={false}
        themeStyles={(themeGlobal as any).comboBoxTopicsOfInterest}
      />
    </S.InputContainer>
  );
}
