import InstagramIcon from 'assets/iconComponents/InstagramIcon';
import TiktokIcon from 'assets/iconComponents/TiktokIcon';
import YoutubeIcon from 'assets/iconComponents/YoutubeIcon';

export const benchmarkColors = [
  '#4CC9F0',
  '#4361EE',
  '#3A0CA3',
  '#7232BD',
  '#F72585',
];

export const socialNetworkIconsAvailableValues = [
  {
    value: 'INSTAGRAM',
    label: (
      <InstagramIcon svgWidth="2rem" svgHeight="2rem" gradientColored={true} />
    ),
  },
  {
    value: 'YOUTUBE',
    label: <YoutubeIcon svgWidth="2rem" svgHeight="2rem" />,
  },
  {
    value: 'TIKTOK',
    label: <TiktokIcon svgWidth="2rem" svgHeight="2rem" />,
  },
];
