import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useGetLocations } from 'Services/Utils/CustomHooks';
import {
  SocialNetworks,
  TCountriesAvailableValues,
} from 'Services/Utils/types';

export const useListening = () => {
  const navigate = useNavigate();

  const startDate = moment().subtract(90, 'days').utc();
  const endDate = moment().utc();

  const [timeFrame, setTimeFrame] = useState(() => {
    return [
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ];
  });

  const [validFilters, setValidFilters] = useState(false);
  const [toggleFilterButton, setToggleFilterButton] = useState(false);
  const [showTimeFramePicker, setShowTimeFramePicker] = useState(false);

  const [visibleHashtagsCount, setVisibleHashtagsCount] = useState(5);

  const [countryCode, setCountryCode] = useState<string>('PT');
  const [searchWord, setSearchWord] = useState<string | undefined>();
  const [network, setNetwork] = useState<SocialNetworks | undefined>();

  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);

  const diffDays = moment(timeFrame[0].endDate).diff(
    moment(timeFrame[0].startDate),
    'days',
  );

  const handleGoToTermAnalytics = useCallback(() => {
    if (searchWord && searchWord.trim().length > 0) {
      navigate(`/listening/${searchWord.trim()}`, {
        state: {
          filters: {
            endDate: timeFrame[0].endDate.valueOf(),
            startDate: timeFrame[0].startDate.valueOf(),
            keywords: [searchWord.trim()],
            countryCode: [countryCode],
            network: network ? [network] : undefined,
          },
        },
      });
    }
  }, [timeFrame, searchWord, countryCode, network]);

  const handleChangeSearchWord = useCallback((word: string) => {
    setSearchWord(word);
  }, []);

  const handleLoadMoreHashtags = useCallback(() => {
    setVisibleHashtagsCount(prevState => prevState + 5);
  }, []);

  const handleToggleFilterButton = useCallback(() => {
    setTimeout(() => setToggleFilterButton(!toggleFilterButton), 0);
  }, [toggleFilterButton]);

  const handleClickOutToggleFilterButton = useCallback(() => {
    if (toggleFilterButton) {
      setToggleFilterButton(false);
    }
  }, [toggleFilterButton]);

  const { data: locations, isFetching: isFetchingGetLocations } =
    useGetLocations(true);

  useEffect(() => {
    if (locations && !isFetchingGetLocations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];

      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });

      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [locations, isFetchingGetLocations]);

  const handleClearFilters = useCallback(() => {
    setSearchWord('');
    setCountryCode('PT');
    setNetwork(undefined);
    setTimeFrame([
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ]);
  }, []);

  useEffect(() => {
    if (searchWord && searchWord.trim().length > 0) {
      setValidFilters(true);
    } else {
      setValidFilters(false);
    }
  }, [searchWord]);

  return {
    network,
    diffDays,
    timeFrame,
    setNetwork,
    searchWord,
    countryCode,
    validFilters,
    setTimeFrame,
    setCountryCode,
    toggleFilterButton,
    handleClearFilters,
    showTimeFramePicker,
    visibleHashtagsCount,
    setShowTimeFramePicker,
    handleChangeSearchWord,
    handleLoadMoreHashtags,
    handleGoToTermAnalytics,
    countriesAvailableValues,
    handleToggleFilterButton,
    handleClickOutToggleFilterButton,
  };
};
