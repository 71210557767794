/* eslint-disable no-empty-function */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import GenericModal from 'components/shared/GenericModal/GenericModal';
import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { SocialNetworks } from 'Services/Utils/types';

import * as S from './Styles';
import { OfficialAccountSynchronizationFailedProps } from './types';

const OfficialAccountSynchronizationFailedModal = (
  props: OfficialAccountSynchronizationFailedProps,
) => {
  const getTitle = useCallback(() => {
    if (props.socialNetwork == SocialNetworks.INSTAGRAM) {
      return props.t('socialAccountManagement.instagramAccountSyncFailedTitle');
    }

    return props.t('socialAccountManagement.tiktokAccountSyncFailedTitle');
  }, [props.socialNetwork]);

  const getBody = useCallback(() => {
    if (props.socialNetwork == SocialNetworks.INSTAGRAM) {
      return (
        <S.SocialNetworkAccountSyncErrorBulletPointsContainer>
          <S.SocialNetworkAccountSyncErrorBulletPoint>
            <S.SocialNetworkAccountSyncErrorDotContainer>
              <S.SocialNetworkAccountSyncErrorDot />
            </S.SocialNetworkAccountSyncErrorDotContainer>
            {props.t(
              'socialAccountManagement.instagramAccountSyncFailedDescriptionFirstParagraphPart1',
            )}

            <b>
              {props.t(
                'socialAccountManagement.instagramAccountSyncFailedDescriptionFirstParagraphPart2',
              )}
            </b>
          </S.SocialNetworkAccountSyncErrorBulletPoint>

          <S.SocialNetworkAccountSyncErrorBulletPoint>
            <S.SocialNetworkAccountSyncErrorDotContainer>
              <S.SocialNetworkAccountSyncErrorDot />
            </S.SocialNetworkAccountSyncErrorDotContainer>
            {props.t(
              'socialAccountManagement.instagramAccountSyncFailedDescriptionSecondParagraphPart1',
            )}

            <b>
              {props.t(
                'socialAccountManagement.instagramAccountSyncFailedDescriptionSecondParagraphPart2',
              )}
            </b>

            {props.t(
              'socialAccountManagement.instagramAccountSyncFailedDescriptionSecondParagraphPart3',
            )}
          </S.SocialNetworkAccountSyncErrorBulletPoint>
        </S.SocialNetworkAccountSyncErrorBulletPointsContainer>
      );
    }

    return (
      <S.SocialNetworkAccountSyncErrorBulletPointsContainer>
        <S.SocialNetworkAccountSyncErrorBulletPoint>
          {props.t(
            'socialAccountManagement.tiktokAccountSyncFailedDescriptionPart1',
          )}

          <b>
            {props.t(
              'socialAccountManagement.tiktokAccountSyncFailedDescriptionPart2',
            )}
          </b>

          {props.t(
            'socialAccountManagement.tiktokAccountSyncFailedDescriptionPart3',
          )}
        </S.SocialNetworkAccountSyncErrorBulletPoint>
      </S.SocialNetworkAccountSyncErrorBulletPointsContainer>
    );
  }, [props.socialNetwork]);

  return (
    <GenericModal
      show={true}
      title={getTitle()}
      body={getBody()}
      primaryButtonText={props.t('generic.gotIt')}
      primaryButtonAction={() => props.modalCloseActionInvoked()}
    />
  );
};

export default withTranslation()(OfficialAccountSynchronizationFailedModal);
