import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  useListening,
  ListeningSearch,
  ListeningFilter,
} from 'features/Listening';

import * as S from './Styles';

const Listening = () => {
  const listeningHook = useListening();

  const { network, diffDays, countryCode } = listeningHook;

  return (
    <S.MainContainer>
      <S.ContainerWrap>
        <S.ListeningHeadSection>
          <S.ListeningTitle>What are people talking about?</S.ListeningTitle>

          <ListeningSearch customHook={listeningHook} />

          <S.ListeningOptions>
            <S.FilterTags>
              <S.Tag active={true}>{t(`countryCode.${countryCode}`)}</S.Tag>
              <S.Tag active={true}>Last {diffDays} days</S.Tag>
              {network && <S.Tag active={true}>{network}</S.Tag>}
            </S.FilterTags>

            <S.FilterOptions>
              <ListeningFilter customHook={listeningHook} />
            </S.FilterOptions>
          </S.ListeningOptions>
        </S.ListeningHeadSection>
      </S.ContainerWrap>
    </S.MainContainer>
  );
};

export default withTranslation()(Listening);
