import styled from 'styled-components';

export const Tag = styled.div<{ active?: boolean }>`
  height: 4rem;
  font-size: 1.4rem;
  margin-top: 0.4rem;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  margin-right: 0.4rem;
  border-radius: 1.9rem;
  padding: 0.4rem 1.4rem;
  justify-content: center;
  color: ${({ active }) => (active ? '#ffffff' : '#29435d')};
  background: ${({ active }) => (active ? '#292929' : '#ffffff')};
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const FilterSideBarBackground = styled.div<{ active: boolean }>`
  top: 6rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  position: absolute;
  transition: all 0.08s;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ active }) => (active ? '1' : '0')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const FilterSideBarContainer = styled.div`
  width: 35rem;
  height: 100%;
  padding: 2rem;
  display: flex;
  background: #fff;
  overflow-y: scroll;
  flex-direction: column;
`;

export const FilterSideBarFooterContainer = styled.div`
  right: 0;
  bottom: 0;
  gap: 1rem;
  width: 35rem;
  height: 8rem;
  padding: 2rem;
  display: flex;
  position: fixed;
  overflow-y: scroll;
  align-items: center;
  background-color: #fff;
`;

export const FilterSideBarFooterButton = styled.div<{
  secondary?: boolean;
}>`
  flex: 1;
  height: 4rem;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  background-color: ${({ secondary }) => (secondary ? '#fff' : '#5870f6')};
  cursor: pointer;
`;

export const FilterSideBarFooterButtonText = styled.div<{
  secondary?: boolean;
}>`
  color: ${({ secondary }) => (secondary ? '#676767' : '#fff')};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.85;
`;
