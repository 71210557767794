import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 4rem;
  align-items: center;
  flex-direction: column;
  transition: all 0.08s ease;
`;

export const ContainerWrap = styled.div`
  width: 120rem;
  max-width: 100%;
`;

export const ListeningHeadSection = styled.div`
  padding: 0 10rem;
`;

export const ListeningTitle = styled.div`
  color: #292929;
  margin-top: 4rem;
  font-weight: 600;
  font-size: 3.2rem;
  text-align: center;
  line-height: 3.6rem;
  margin-bottom: 4rem;
`;

export const ListeningOptions = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 6rem;
`;

export const FilterTags = styled.div`
  height: auto;
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: center;
  display: inline-flex;
  width: calc(100% - 2.5rem);
`;

export const Tag = styled.div<{ active?: boolean }>`
  height: 4rem;
  font-size: 1.4rem;
  margin-top: 0.4rem;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  margin-right: 0.4rem;
  border-radius: 1.9rem;
  padding: 0.4rem 1.4rem;
  justify-content: center;
  color: ${({ active }) => (active ? '#ffffff' : '#29435d')};
  background: ${({ active }) => (active ? '#292929' : '#ffffff')};

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const FilterOptions = styled.div``;
