import Button from 'components/shared/Button/Button';
import LoaderFixed from 'components/shared/LoaderFixed/LoaderFixed';
import Pagination from 'components/shared/Pagination/Pagination';
import {
  BenchmarkEmptyState,
  BenchmarkList,
  useBenchmarksList,
} from 'features/Benchmark';
import { t } from 'i18next';
import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './Styles';

const BenchmarksList = () => {
  const navigate = useNavigate();

  const {
    page,
    benchmarks,
    hasBenchmarks,
    totalBenchmarks,
    handleChangePage,
    isLoadingBenchmarks,
  } = useBenchmarksList();

  const handleGoToCreateBenchmark = useCallback(() => {
    navigate('/create-benchmark');
  }, [navigate]);

  return (
    <>
      <LoaderFixed show={isLoadingBenchmarks} message={t('generic.loading')} />

      <S.MainContainer>
        <S.TopBarContainer>
          <S.MainTitle>Benchmarks</S.MainTitle>

          {hasBenchmarks && (
            <Button borderRadius="0.6rem" onClick={handleGoToCreateBenchmark}>
              Create benchmark
            </Button>
          )}
        </S.TopBarContainer>

        {!hasBenchmarks ? (
          <BenchmarkEmptyState isLoading={isLoadingBenchmarks} />
        ) : (
          <S.ListContainer>
            <BenchmarkList data={benchmarks} />

            <Pagination
              page={page}
              totalRecordsPerPage={20}
              changePage={handleChangePage}
              totalRecords={totalBenchmarks ?? 20}
            />
          </S.ListContainer>
        )}
      </S.MainContainer>
    </>
  );
};

export default withTranslation()(BenchmarksList);
