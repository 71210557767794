/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { useTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import * as S from './Styles';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import StyledButton from '../../components/shared/Button/Button';
import { getThemeStyles } from '../../css/ThemeGlobal';
import {
  GENDERS,
  ICountryComboBoxEntry,
  PartnerTypes,
  TCountriesAvailableValues,
  TopicsInterestAvailableValues,
} from '../../Services/Utils/types';
import {
  useUpdateAgentAndUser,
  useUpdateInfluencerProfile,
} from './RQCustomHooks';
import {
  useGetLocations,
  useGetSelfUser,
  useGetToken,
  useGetTopicsInterest,
} from '../../Services/Utils/CustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { valueEmpty } from '../../validations/validationFunctions';
import { ProfileEditProps } from './types';
import { IFile } from '../../components/shared/FilePickerV2/types';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import auth from '../../api/auth-helper';
import { isAuthenticated } from '../../Services/Utils/Utils';
import { FileSourceEnum } from '../../Services/SkorrApi/new/Files/types';
import { OuterContext } from '../../components/MenuNavbarV2/MenuNavbarV2';

const ProfileEdit = (props: ProfileEditProps) => {
  const [profileImageSrc, setProfileImageSrc] = useState('');
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] =
    useState<boolean>(false);
  const [isGetLocationsEnabled, setIsGetLocationsEnabled] =
    useState<boolean>(true);
  const [isGetTopicsInterestEnabled, setIsGetTopicsInterestEnabled] =
    useState(true);
  const [isGetTokenEnabled, setIsGetTokenEnabled] = useState<boolean>(false);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [countrySelected, setCountrySelected] = useState<
    ICountryComboBoxEntry | undefined
  >();
  const [genderSelected, setGenderSelected] = useState<string>();
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [files, setFiles] = useState<IFile[]>([]);
  const [name, setName] = useState<string>();
  const [topicsInterestAvailableValues, setTopicsInterestAvailableValues] =
    useState<TopicsInterestAvailableValues>([]);
  const [topicsInterestSelected, setTopicsInterestSelected] = useState<
    Array<string>
  >([]);
  const {
    isLoading: isLoadingUpdateInfluencerProfile,
    isSuccess: isSuccessUpdateInfluencerProfile,
    isError: isErrorUpdateInfluencerProfile,
    mutate: updateInfluencerProfile,
  } = useUpdateInfluencerProfile();
  const {
    isLoading: isLoadingUpdateAgentAndUser,
    isSuccess: isSuccessUpdateAgentAndUser,
    isError: isErrorUpdateAgentAndUser,
    mutate: updateAgentAndUser,
  } = useUpdateAgentAndUser();
  const {
    data: locations,
    isFetching: isFetchingGetLocations,
    isSuccess: isSuccessGetLocations,
    isError: isErrorGetLocations,
  } = useGetLocations(isGetLocationsEnabled);
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: topicsInterest,
    isFetching: isFetchingGetTopicsInterest,
    isSuccess: isSuccessTopicsInterest,
    isError: isErrorGetTopicsInterest,
  } = useGetTopicsInterest(isGetTopicsInterestEnabled);
  const {
    isFetching: isFetchingGetToken,
    isSuccess: isSuccessGetToken,
    refetch: refetchGetToken,
  } = useGetToken(isGetTokenEnabled, isAuthenticated()?.agentEmail ?? '');
  const [
    showInfluencerProfileUpdateSuccessNotification,
    setShowInfluencerProfileUpdateSuccessNotification,
  ] = useState<boolean>(false);
  const [
    showInfluencerProfileUpdateSuccessNotificationText,
    setShowInfluencerProfileUpdateSuccessNotificationText,
  ] = useState<boolean>(false);
  const context = useContext(OuterContext);
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const queryClient = useQueryClient();
  const initialProfileImageUrl = useMemo(
    () => [profileImageSrc],
    [profileImageSrc],
  );
  const [gendersAvailableValues, _] = useState([
    {
      value: GENDERS.MALE,
      label: props.t('userProfile.male'),
    },
    {
      value: GENDERS.FEMALE,
      label: props.t('userProfile.female'),
    },
    {
      value: GENDERS.OTHER,
      label: props.t('userProfile.other'),
    },
  ]);
  const [yearOfBirth, setYearOfBirth] = useState();
  const [monthOfBirth, setMonthOfBirth] = useState();
  const [dayOfBirth, setDayOfBirth] = useState();
  const monthOfBirthRef = useRef<HTMLInputElement>();
  const dayOfBirthRef = useRef<HTMLInputElement>();
  const [yearOfBirthInputFocused, setYearOfBirthInputFocused] = useState(false);
  const [monthOfBirthInputFocused, setMonthOfBirthInputFocused] =
    useState(false);
  const [dayOfBirthInputFocused, setDayOfBirthInputFocused] = useState(false);
  const [showInvalidDateOfBirthWarning, setShowInvalidDateOfBirthWarning] =
    useState(false);

  useEffect(() => {
    if (auth.isAuthenticated().partnerType === PartnerTypes.influencer) {
      const invalidateTokenData = async () => {
        await queryClient.invalidateQueries({
          queryKey: ['get-user', isAuthenticated()?.userId],
        });
      };
      invalidateTokenData();
      setIsGetSelfUserEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (isSuccessGetSelfUser && !isFetchingGetSelfUser) {
      setName(selfUser.name ?? '');
      if (selfUser.displayPicture) {
        setProfileImageSrc(selfUser.displayPicture);
      }
      if (selfUser.dateOfBirth) {
        const dateOfBirthObj = new Date(selfUser.dateOfBirth);
        setYearOfBirth(String(dateOfBirthObj.getFullYear()) as any);
        const stringifiedMonth = String(dateOfBirthObj.getMonth() + 1) as any;
        setMonthOfBirth(
          stringifiedMonth.length == 1
            ? `0${stringifiedMonth}`
            : stringifiedMonth,
        );
        const stringifiedDay = String(dateOfBirthObj.getDate()) as any;
        setDayOfBirth(
          stringifiedDay.length == 1 ? `0${stringifiedDay}` : stringifiedDay,
        );
      }

      if (selfUser.topicsInterest?.length) {
        setTopicsInterestSelected(selfUser.topicsInterest);
      }
    }
  }, [isSuccessGetSelfUser, isFetchingGetSelfUser]);

  useEffect(() => {
    if (locations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];
      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });
      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [isSuccessGetLocations]);

  useEffect(() => {
    if (isSuccessTopicsInterest && topicsInterest) {
      setIsGetTopicsInterestEnabled(false);
      const topicsInterestAvailableValuesLocal: TopicsInterestAvailableValues =
        [];

      topicsInterest.forEach((label, key) => {
        topicsInterestAvailableValuesLocal.push({
          value: key,
          label: props.t(label),
        });
      });

      setTopicsInterestAvailableValues(topicsInterestAvailableValuesLocal);
    }
  }, [isSuccessTopicsInterest, topicsInterest]);

  useEffect(() => {
    if (selfUser) {
      if (countriesAvailableValues.length) {
        if (selfUser.countryCode) {
          for (let i = 0; i < countriesAvailableValues.length; i++) {
            if (countriesAvailableValues[i].value === selfUser.countryCode) {
              setCountrySelected({
                value: selfUser!.countryCode!,
                data: countriesAvailableValues[i].data!,
              });
              break;
            }
          }
        }
      }

      if (gendersAvailableValues.length) {
        if (selfUser.gender) {
          for (let i = 0; i < gendersAvailableValues.length; i++) {
            if (
              gendersAvailableValues[i].value === selfUser.gender.toUpperCase()
            ) {
              setGenderSelected(selfUser.gender.toUpperCase() as GENDERS);
              break;
            }
          }
        }
      }
    }
  }, [selfUser, countriesAvailableValues, gendersAvailableValues]);

  useEffect(() => {
    if (
      yearOfBirthInputFocused &&
      !Number.isNaN(yearOfBirth) &&
      String(yearOfBirth).length === 4
    ) {
      monthOfBirthRef.current?.focus();
    }
  }, [yearOfBirth]);

  useEffect(() => {
    if (
      monthOfBirthInputFocused &&
      !Number.isNaN(monthOfBirth) &&
      String(monthOfBirth).length === 2
    ) {
      dayOfBirthRef.current?.focus();
    }
  }, [monthOfBirth]);

  useEffect(() => {
    if (
      yearOfBirthInputFocused ||
      monthOfBirthInputFocused ||
      dayOfBirthInputFocused
    ) {
      setShowInvalidDateOfBirthWarning(false);
    }

    if (
      !(
        yearOfBirthInputFocused ||
        monthOfBirthInputFocused ||
        dayOfBirthInputFocused
      ) &&
      !isDateOfBirthValid()
    ) {
      setShowInvalidDateOfBirthWarning(true);
    }
  }, [
    yearOfBirthInputFocused,
    monthOfBirthInputFocused,
    dayOfBirthInputFocused,
  ]);

  useEffect(() => {
    if (isSuccessUpdateInfluencerProfile || isSuccessUpdateAgentAndUser) {
      const invalidateTokenData = async () => {
        await queryClient.invalidateQueries({ queryKey: ['tokenData'] });
      };

      invalidateTokenData();

      setTimeout(() => {
        refetchGetToken();
      }, 2000);

      setShowInfluencerProfileUpdateSuccessNotification(true);
      setShowInfluencerProfileUpdateSuccessNotificationText(true);
      setTimeout(() => {
        setShowInfluencerProfileUpdateSuccessNotification(false);
      }, 2000);
      setTimeout(() => {
        setShowInfluencerProfileUpdateSuccessNotificationText(false);
      }, 2600);
    }
  }, [isSuccessUpdateInfluencerProfile, isSuccessUpdateAgentAndUser]);

  useEffect(() => {
    if (isSuccessGetToken && !isFetchingGetToken) {
      setIsGetTokenEnabled(false);
      setTimeout(() => context.refreshNavbar(), 1000);
    }
  }, [isSuccessGetToken, isFetchingGetToken]);

  useEffect(() => {
    if (isErrorGetSelfUser) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
      setIsGetSelfUserEnabled(false);
    }
  }, [isErrorGetSelfUser]);

  useEffect(() => {
    if (isErrorGetLocations) {
      setHasError(true);
      setIsGetLocationsEnabled(false);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorGetLocations]);

  useEffect(() => {
    if (isErrorGetTopicsInterest) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
      setIsGetTopicsInterestEnabled(false);
    }
  }, [isErrorGetTopicsInterest]);

  useEffect(() => {
    if (isErrorUpdateInfluencerProfile) {
      setHasError(true);
      setMsgError(props.t('profileEdit.couldNotUpdateProfile'));
    }
  }, [isErrorUpdateInfluencerProfile]);

  useEffect(() => {
    if (isErrorUpdateAgentAndUser) {
      setHasError(true);
      setMsgError(props.t('profileEdit.couldNotUpdateProfile'));
    }
  }, [isErrorUpdateAgentAndUser]);

  const isDateOfBirthValid = () => {
    return (
      (String(yearOfBirth ?? '').length === 0 &&
        String(monthOfBirth ?? '').length === 0 &&
        String(dayOfBirth ?? '').length === 0) ||
      (/^(?:[1-9]\d*|)$/.test(String(yearOfBirth ?? '')) &&
        String(yearOfBirth ?? '').length === 4 &&
        /^(?:[0-9]\d*|)$/.test(String(monthOfBirth ?? '')) &&
        String(monthOfBirth ?? '').length >= 1 &&
        !Array.from(monthOfBirth ?? []).every(digit => digit == 0) &&
        /^(?:[0-9]\d*|)$/.test(String(dayOfBirth ?? '')) &&
        String(dayOfBirth ?? '').length >= 1 &&
        !Array.from(dayOfBirth ?? []).every(digit => digit == 0))
    );
  };

  const saveButtonClicked = () => {
    const args: any = {};
    args.name = name;
    args.displayPicture = profileImageSrc;
    if (
      String(yearOfBirth ?? '').length === 4 &&
      String(monthOfBirth ?? '').length >= 1 &&
      String(dayOfBirth ?? '').length >= 1
    ) {
      args.dateOfBirth = new Date(
        `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`,
      );
    } else {
      args.dateOfBirth = null;
    }
    args.countryId = countrySelected?.data?.countryId ?? null;
    args.countryCode = countrySelected?.value ?? null;
    args.gender = genderSelected ?? null;
    args.topicsOfInterest = topicsInterestSelected;

    let influencerProfilePictureFormData;
    if (files[0]?.data) {
      influencerProfilePictureFormData = new FormData();
      influencerProfilePictureFormData.append('file', files[0]!.data);
      influencerProfilePictureFormData.append(
        'source',
        FileSourceEnum.BRAND_PICTURE,
      );
      influencerProfilePictureFormData.append('id', isAuthenticated()?.userId!);
    }
    if (isAuthenticated()!.partnerType === PartnerTypes.influencer) {
      updateInfluencerProfile({
        influencerProfileUpdateData: args,
        influencerPictureData: influencerProfilePictureFormData,
        influencerInitialPicture: profileImageSrc,
      });
    } else
      updateAgentAndUser({
        updateData: args,
        pictureData: influencerProfilePictureFormData,
        initialPicture: profileImageSrc,
      });
  };

  const cancelButtonClicked = () => {
    navigate(-1);
  };

  const getLoaderMessage = () => {
    if (isFetchingGetSelfUser) {
      return props.t('generic.loadingData');
    }
    if (isLoadingUpdateInfluencerProfile || isLoadingUpdateAgentAndUser) {
      return props.t('generic.savingChanges');
    }
    if (isFetchingGetLocations) {
      return props.t('generic.loadingData');
    }
    return '';
  };

  const isSaveButtonEnabled = () => {
    if (!valueEmpty(name) && isDateOfBirthValid()) return true;
    return false;
  };

  const getActionResultTooltipMessage = () => {
    if (showInfluencerProfileUpdateSuccessNotificationText)
      return props.t('profileEdit.profileUpdated');
  };

  const errorModalCloseButtonClicked = () => {
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }
    if (isErrorGetLocations) {
      setIsGetLocationsEnabled(true);
    }
    if (isErrorGetTopicsInterest) {
      setIsGetTopicsInterestEnabled(true);
    }
    setHasError(false);
  };

  const getPageTitle = () => {
    return props.t('profileEdit.editProfile');
  };

  return (
    <S.OuterContainer>
      <Loader
        show={
          isFetchingGetSelfUser ||
          isFetchingGetLocations ||
          isFetchingGetTopicsInterest ||
          isLoadingUpdateInfluencerProfile ||
          isLoadingUpdateAgentAndUser
        }
        message={getLoaderMessage()}
      />

      <S.Container>
        <S.InnerContainer>
          <S.BusinessProfileCard>
            <S.HeaderOuterContainer>
              <S.HeaderContainer>
                <S.HeaderTitleContainer>
                  {getPageTitle()}
                </S.HeaderTitleContainer>
                <S.HiddenHeaderElement />
              </S.HeaderContainer>
              <S.HeaderDivider />
            </S.HeaderOuterContainer>

            <S.CardInnerContainer>
              <S.BPDetailsContainer>
                <S.Title>{props.t('profileEdit.profileDetails')}</S.Title>

                <S.BPDetailsInnerContainer>
                  <S.BPDetailsInnerFirstRowContainer>
                    <S.BPImagePickerContainer>
                      <S.BPImagePickerButtonContainer>
                        <FilePickerV2
                          handleChange={(files: IFile[]) => {
                            setFiles(files);
                            if (!files.length) {
                              setProfileImageSrc('');
                            }
                          }}
                          text={props.t('generic.selectPicture')}
                          showSelectedFiles={false}
                          single={true}
                          type={'images'}
                          marginTop={'1rem'}
                          initialImages={initialProfileImageUrl}
                          imageContainerWidth={'16.8rem'}
                          imageContainerHeight={'13.8rem'}
                          buttonBorderRadius={'0.6rem'}
                        />
                      </S.BPImagePickerButtonContainer>
                    </S.BPImagePickerContainer>

                    <S.BPNameAndDescriptionContainer>
                      <S.BPNameAndDescriptionInnerContainer>
                        <InputControlV2
                          value={name}
                          onChange={(inputValue: string) => setName(inputValue)}
                          title={props.t('generic.name')}
                          inputFieldContainerWithTitleWidth={'100%'}
                          mandatoryField={true}
                          placeholder={props.t('placeholder.insertName')}
                          inputTitleMarginBottom={'1.2rem'}
                          inputFieldContainerMarginBottom={'2.7rem'}
                          warningMessageContainerPositionAbsolute={true}
                          warningMessageContainerTopOffset={'0.1rem'}
                          warningMessageContainerLeftOffset={'0.2rem'}
                          requiredFieldWarningMessage={props.t(
                            'generic.nameIsRequired',
                          )}
                        />

                        <S.TopicsInterestContainer>
                          <S.DateOfBirthPickerContainer>
                            <S.TopicsInterestTitleContainer>
                              {props.t('generic.topicsOfInterest')}
                            </S.TopicsInterestTitleContainer>

                            <ComboBox
                              valueKey={'topicsInterestSelected'}
                              value={topicsInterestSelected}
                              type={'multi'}
                              width={'100%'}
                              marginTop={'0.5rem'}
                              borderRadius={'0.6rem'}
                              valuesAvailable={topicsInterestAvailableValues}
                              isFilterable={true}
                              isClearable={true}
                              placeholder={props.t(
                                'profileEdit.selectNoMoreThanFiveTopics',
                              )}
                              onChange={(obj: {
                                topicsInterestSelected: Array<string>;
                              }) => {
                                setTopicsInterestSelected(
                                  obj.topicsInterestSelected,
                                );
                              }}
                              preventOptionSelection={
                                topicsInterestSelected.length === 5
                              }
                              height={'7rem'}
                              availableOptionsContainerBorderRadius={'0.6rem'}
                              optionContainerBorderRadius={'0.6rem'}
                              innerOptionsContainerMarginTop={'1.2rem'}
                              innerOptionsContainerMarginBottom={'1.2rem'}
                              innerOptionsContainerPaddingLeft={'1.2rem'}
                              innerOptionsContainerPaddingRight={'1.2rem'}
                              optionContainerPaddingTop={'2rem'}
                              optionContainerPaddingBottom={'2rem'}
                              optionContainerPaddingLeft={'2rem'}
                              optionContainerPaddingRight={'2rem'}
                              optionSelectedContainerBorderRadius={'2rem'}
                              themeStyles={(themeGlobal as any).comboBoxTags}
                              changeBackgroundColorOnHover={false}
                              crossIconV2={true}
                              growWithoutScroll={true}
                            />
                          </S.DateOfBirthPickerContainer>
                        </S.TopicsInterestContainer>

                        <S.CountryGenderContainer>
                          <S.CountryContainer>
                            <S.TopicsInterestTitleContainer>
                              {props.t('generic.country')}
                            </S.TopicsInterestTitleContainer>

                            <ComboBox
                              valueKey={'countrySelected'}
                              value={countrySelected}
                              type={'single'}
                              width={'100%'}
                              borderRadius={'0.6rem'}
                              valuesAvailable={countriesAvailableValues}
                              isFilterable={true}
                              isClearable={true}
                              onChange={(obj: {
                                countrySelected: ICountryComboBoxEntry;
                              }) => {
                                setCountrySelected(obj.countrySelected);
                              }}
                              placeholder={props.t('placeholder.selectCountry')}
                              height={'4.4rem'}
                              availableOptionsContainerBorderRadius={'0.6rem'}
                              optionContainerBorderRadius={'0.6rem'}
                              innerOptionsContainerMarginTop={'1.2rem'}
                              innerOptionsContainerMarginBottom={'1.2rem'}
                              innerOptionsContainerPaddingLeft={'1.2rem'}
                              innerOptionsContainerPaddingRight={'1.2rem'}
                              optionContainerPaddingTop={'2rem'}
                              optionContainerPaddingBottom={'2rem'}
                              optionContainerPaddingLeft={'2rem'}
                              optionContainerPaddingRight={'2rem'}
                              themeStyles={
                                (themeGlobal as any).influencerProfileEdit
                              }
                              changeBackgroundColorOnHover={false}
                              changeContainerBorderColorOnHover={true}
                              innerOptionsContainerMaxHeight={'25rem'}
                            />
                          </S.CountryContainer>

                          <S.GenderContainer>
                            <S.TopicsInterestTitleContainer>
                              {props.t('generic.gender')}
                            </S.TopicsInterestTitleContainer>

                            <ComboBox
                              valueKey={'genderSelected'}
                              value={genderSelected}
                              type={'single'}
                              width={'100%'}
                              borderRadius={'0.6rem'}
                              valuesAvailable={gendersAvailableValues}
                              isFilterable={false}
                              isClearable={true}
                              onChange={(obj: { genderSelected: string }) => {
                                setGenderSelected(obj.genderSelected);
                              }}
                              placeholder={props.t('placeholder.selectGender')}
                              height={'4.4rem'}
                              availableOptionsContainerBorderRadius={'0.6rem'}
                              optionContainerBorderRadius={'0.6rem'}
                              innerOptionsContainerMarginTop={'1.2rem'}
                              innerOptionsContainerMarginBottom={'1.2rem'}
                              innerOptionsContainerPaddingLeft={'1.2rem'}
                              innerOptionsContainerPaddingRight={'1.2rem'}
                              optionContainerPaddingTop={'2rem'}
                              optionContainerPaddingBottom={'2rem'}
                              optionContainerPaddingLeft={'2rem'}
                              optionContainerPaddingRight={'2rem'}
                              themeStyles={
                                (themeGlobal as any).influencerProfileEdit
                              }
                              changeBackgroundColorOnHover={false}
                              changeContainerBorderColorOnHover={true}
                              innerOptionsContainerMaxHeight={'25rem'}
                            />
                          </S.GenderContainer>
                        </S.CountryGenderContainer>

                        <S.GenderAgeContainer>
                          <S.DateOfBirthPickerContainer>
                            <S.DateOfBirthPickerTitle>
                              {props.t('generic.dateOfBirth')}
                            </S.DateOfBirthPickerTitle>

                            <S.DateOfBirthPickerInputContainer>
                              <InputControlV2
                                value={yearOfBirth}
                                type={'number'}
                                onChange={(inputValue: string) => {
                                  if (
                                    /^(?:[1-9]\d*|)$/.test(inputValue) &&
                                    Number(inputValue) >= 0 &&
                                    inputValue.length <= 4
                                  ) {
                                    setYearOfBirth(inputValue as any);
                                  }
                                }}
                                placeholder={props.t(
                                  'profileEdit.insertYearPlaceholder',
                                )}
                                inputFieldContainerWidth={'12rem'}
                                onFocus={() => setYearOfBirthInputFocused(true)}
                                onBlur={() => setYearOfBirthInputFocused(false)}
                                forceBorderColorRed={
                                  showInvalidDateOfBirthWarning
                                }
                              />
                              <S.DateOfBirthInputSeparator>
                                <S.DateOfBirthInputSeparatorContent />
                              </S.DateOfBirthInputSeparator>
                              <InputControlV2
                                value={monthOfBirth}
                                type={'number'}
                                onChange={(inputValue: string) => {
                                  if (
                                    /^(?:[0-9]\d*|)$/.test(inputValue) &&
                                    Number(inputValue) >= 0 &&
                                    Number(inputValue) <= 12
                                  ) {
                                    setMonthOfBirth(inputValue as any);
                                  }
                                }}
                                placeholder={props.t(
                                  'profileEdit.insertMonthPlaceholder',
                                )}
                                inputFieldContainerWidth={'10.5rem'}
                                refNotNative={monthOfBirthRef}
                                onFocus={() =>
                                  setMonthOfBirthInputFocused(true)
                                }
                                onBlur={() =>
                                  setMonthOfBirthInputFocused(false)
                                }
                                forceBorderColorRed={
                                  showInvalidDateOfBirthWarning
                                }
                              />
                              <S.DateOfBirthInputSeparator>
                                <S.DateOfBirthInputSeparatorContent />
                              </S.DateOfBirthInputSeparator>
                              <InputControlV2
                                value={dayOfBirth}
                                type={'number'}
                                onChange={(inputValue: string) => {
                                  if (
                                    /^(?:[0-9]\d*|)$/.test(inputValue) &&
                                    Number(inputValue) >= 0 &&
                                    Number(inputValue) <= 31
                                  ) {
                                    setDayOfBirth(inputValue as any);
                                  }
                                }}
                                placeholder={props.t(
                                  'profileEdit.insertDayPlaceholder',
                                )}
                                inputFieldContainerWidth={'10.5rem'}
                                refNotNative={dayOfBirthRef}
                                onFocus={() => setDayOfBirthInputFocused(true)}
                                onBlur={() => setDayOfBirthInputFocused(false)}
                                forceBorderColorRed={
                                  showInvalidDateOfBirthWarning
                                }
                              />
                            </S.DateOfBirthPickerInputContainer>
                            {showInvalidDateOfBirthWarning && (
                              <S.DateOfBirthInvalidValueWarning>
                                {props.t('warning.invalidDateOfBirth')}
                              </S.DateOfBirthInvalidValueWarning>
                            )}
                          </S.DateOfBirthPickerContainer>
                        </S.GenderAgeContainer>
                      </S.BPNameAndDescriptionInnerContainer>
                    </S.BPNameAndDescriptionContainer>
                  </S.BPDetailsInnerFirstRowContainer>
                </S.BPDetailsInnerContainer>
              </S.BPDetailsContainer>

              <S.BusinessProfileFooter>
                <StyledButton
                  borderRadius={'0.6rem'}
                  paddingLeft={'2rem'}
                  paddingTop={'1rem'}
                  paddingBottom={'1rem'}
                  paddingRight={'2rem'}
                  marginRight={'1rem'}
                  themeStyles={getThemeStyles(
                    ['button', 'secondary-1'],
                    themeGlobal,
                  )}
                  onClick={() => cancelButtonClicked()}
                >
                  {props.t('generic.cancel')}
                </StyledButton>

                <StyledButton
                  borderRadius={'0.6rem'}
                  paddingLeft={'2rem'}
                  paddingRight={'2rem'}
                  paddingTop={'1rem'}
                  paddingBottom={'1rem'}
                  onClick={() => saveButtonClicked()}
                  disabled={!isSaveButtonEnabled()}
                >
                  {props.t('generic.save')}
                </StyledButton>
              </S.BusinessProfileFooter>
            </S.CardInnerContainer>
          </S.BusinessProfileCard>
        </S.InnerContainer>
      </S.Container>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={showInfluencerProfileUpdateSuccessNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {getActionResultTooltipMessage()}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.OuterContainer>
  );
};

export default withTranslation()(ProfileEdit);
