import { ContentPost, DiscoverPost, Post } from 'features/Benchmark/types';

export function transformPosts(
  posts: (ContentPost | DiscoverPost)[],
  { fromDiscoverApis = false } = {},
): Post[] {
  if (fromDiscoverApis) {
    return (posts as DiscoverPost[]).map(discoverPost => {
      return {
        source: 'DiscoverPost',
        id: discoverPost.id,
        date: discoverPost.date.toString(),
        type: discoverPost.type,
        content: discoverPost.content,
        url: discoverPost.link,
        image: discoverPost.image,
        aspectRatio: discoverPost.aspectRatio,
        kpis: {
          comments: discoverPost.kpi.comments,
          likes: discoverPost.kpi.likes,
          shares: discoverPost.kpi.shares,
          reactions: discoverPost.kpi.reactions,
          engagementRate: discoverPost.kpi.engRate,
          views: discoverPost.kpi.views,
          skorr: discoverPost.kpi.skorr,
          pulsedSkorr: discoverPost.kpi.pulsedSkorr,
        },
        user: {
          publicUserId: discoverPost.user.id,
          displayPicture: discoverPost.user.displayPicture,
          socialNetwork: discoverPost.user.socialNetwork,
          username: discoverPost.user.username,
          countryCode: discoverPost.user.countryCode,
          biography: discoverPost.user.biography,
          posts: discoverPost.user.posts,
          engagementRate: discoverPost.user.engRate,
        },
        relatedHashtags: discoverPost.relatedHashtags,
        avgLikes: discoverPost.avgLikes,
        avgComments: discoverPost.avgComments,
        avgViews: discoverPost.avgViews,
      };
    });
  }

  return (posts as ContentPost[]).map(contentPost => {
    return {
      source: 'Post',
      id: contentPost.id,
      date: contentPost.date.toString(),
      type: contentPost.type,
      content: contentPost.content,
      url: contentPost.url,
      image: contentPost.image,
      keywordsMatched: contentPost.keywordsMatched,
      aspectRatio: contentPost.aspectRatio,
      kpis: {
        comments: contentPost.insights.comments,
        likes: contentPost.insights.likes,
        saves: contentPost.insights.saves,
        shares: contentPost.insights.shares,
        reactions: contentPost.insights.reactions,
        engagementRate: contentPost.insights.engagementRate,
        views: contentPost.insights.views,
        follows: contentPost.insights.follows,
        impressions: contentPost.insights.impressions,
        navigation: contentPost.insights.navigation,
        profile_activity: contentPost.insights.profile_activity,
        reach: contentPost.insights.reach,
        total_interactions: contentPost.insights.total_interactions,
      },
      user: {
        publicUserId: contentPost.publicUserId,
        socialUserId: contentPost.socialUserId,
        socialNetwork: contentPost.socialNetwork,
        username: contentPost.username,
        displayPicture: contentPost.user?.displayPicture,
        countryCode: contentPost.user?.countryCode,
        biography: contentPost.user?.biography,
      },
      relatedHashtags: [],
    };
  });
}
