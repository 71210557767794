import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 4rem;
  padding-top: 1rem;
  align-items: center;
  flex-direction: column;
  transition: all 0.08s ease;
`;

export const ContainerWrap = styled.div`
  width: 120rem;
  max-width: 100%;
`;

export const TopNavbarContainer = styled.div`
  gap: 1.6rem;
  display: flex;
  margin: 1.6rem 0;
`;

export const TopNavbarInnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TopNavbarButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const FullWidthRow = styled.div`
  gap: 1.6rem;
  display: flex;
  margin: 1.6rem 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: #fff;
  min-height: 5rem;
  border-radius: 1.2rem;
  box-sizing: border-box;
  border: solid 1px #e0e5ea;
`;

export const ContentInnerContainer = styled.div`
  display: flex;
  margin: 0 3.2rem;
  flex-direction: column;
  justify-content: center;
`;

export const ContentHeadRow = styled.div`
  gap: 1.6rem;
  display: flex;
  margin-top: 1.6rem;
  flex-direction: row;
  justify-content: space-between;
`;

export const TermNameContainer = styled.div``;

export const TermName = styled.h5`
  color: #292929;
  font-weight: 600;
  font-size: 2.6rem;
  margin: 1rem 0 0.4rem 0;
`;

export const ContentMiddleRow = styled.div`
  gap: 1.6rem;
  display: flex;
  margin: 1.6rem 0;
  flex-direction: row;
  justify-content: space-between;
`;

export const HashtagsRow = styled.div`
  display: flex;
  align-items: center;

  span {
    height: 3.2rem;
    color: #606976;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 0.8rem;
    display: inline-flex;
    background: #f7f8fa;
    border-radius: 1.6rem;
    padding: 0.8rem 1.4rem;
    box-sizing: border-box;
  }
`;

export const HashtagsSymbol = styled.div`
  &::after {
    content: '#';
    color: #292929;
    font-weight: 100;
    font-size: 2.4rem;
    position: relative;
    line-height: 2.6rem;
    font-family: monospace;
  }
`;

export const DateTimeframe = styled.div`
  span {
    height: 3.2rem;
    color: #606976;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 0.8rem;
    display: inline-flex;
    background: #f7f8fa;
    border-radius: 1.6rem;
    padding: 0.8rem 1.4rem;
    box-sizing: border-box;
  }
`;

export const TermKpisContainer = styled.div`
  display: flex;
  margin-top: 0.8rem;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1rem solid #e7ebf4;
`;

export const MetricContainer = styled.div`
  width: 8.8rem;
  display: grid;
  padding: 2.4rem 0;
  flex-basis: content;

  &:last-child {
    margin-right: 4rem;
  }
`;

export const MetricValue = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
`;

export const MetricLabel = styled.span`
  color: #8492a6;
  font-weight: 400;
  font-size: 1.2rem;
`;

export const ChartsRowContainer = styled.div`
  gap: 1.6rem;
  display: flex;
  margin: 1.6rem 0;
  flex-direction: row;
  justify-content: space-between;
`;

export const ChartContentContainer = styled.div<{ customWidth?: number }>`
  flex-grow: 1;
  display: flex;
  background: #fff;
  min-height: 40rem;
  border-radius: 1.2rem;
  box-sizing: border-box;
  flex-direction: column;
  border: solid 1px #e0e5ea;
  width: ${({ customWidth }) => (customWidth ? `${customWidth}%` : '100%')};
  justify-content: ${({ customWidth }) =>
    customWidth ? 'unset' : 'space-between'};
`;

export const ChartMetricLabel = styled.div`
  gap: 0.4rem;
  display: flex;
  color: #8492a6;
  font-weight: 400;
  font-size: 1.2rem;
  align-items: center;
  padding: 2.4rem 3.2rem 0 3.2rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: #8492a6;
  }
`;

export const ChartKpisContainer = styled.div`
  gap: 4rem;
  display: flex;
  margin: 0 3.2rem;
  align-items: center;
  justify-content: initial;
`;

export const WordcloudContainer = styled.div`
  height: 100%;
  margin: 2.4rem 3.2rem;
`;

export const FilterOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 6rem;
`;

export const FilterTagsContainer = styled.div`
  height: auto;
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: center;
  display: inline-flex;
  width: calc(100% - 2.5rem);
`;

export const Tag = styled.div<{ active?: boolean }>`
  height: 4rem;
  font-size: 1.4rem;
  margin-top: 0.4rem;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  margin-right: 0.4rem;
  border-radius: 1.9rem;
  padding: 0.4rem 1.4rem;
  justify-content: center;
  color: ${({ active }) => (active ? '#ffffff' : '#29435d')};
  background: ${({ active }) => (active ? '#292929' : '#ffffff')};
  cursor: pointer;
`;
