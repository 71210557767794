/* eslint-disable no-use-before-define */
import { AxiosInstance } from 'axios';
import queryString from 'query-string';
import { SocialNetworks } from 'Services/Utils/types';

import config from '../../../config';
import {
  GetPublicPostsByPublicUserIdFilteredByKeywordsResponse,
  GetPublicPostsByPublicUserIdResponse,
  GetPublicUserHashtagsSortedByMetricsParams,
  GetPublicUserHashtagsSortedByMetricsResponse,
  PublicPostTypes,
  SortByCriteria,
  SortOrder,
} from './types';

const createPostInstanceApi = (instance: AxiosInstance) => {
  const getPublicUserHashtagsSortedByMetrics = async (
    data: GetPublicUserHashtagsSortedByMetricsParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserIds: [data.publicUserId],
        startDate:
          data.startDate ?? new Date().getTime() - 30 * 24 * 3600 * 1000,
        endDate: data.endDate ?? new Date().getTime(),
        sortBy: 'likes',
        limit: data.limit ?? 5,
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_USER_HASHTAGS_SORTED_BY_METRICS}?${query}`;

    const {
      data: hashtags,
    }: { data: GetPublicUserHashtagsSortedByMetricsResponse } =
      await instance.get(url);
    return hashtags[Object.keys(hashtags)[0]].map(item => item.hashtag);
  };

  const getPublicPostsByPublicUserId = async (
    data: IGetPublicPostsByPublicUserIdParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startDate: data.startDate,
        endDate: data.endDate,
        types: data.types ?? [
          PublicPostTypes.IMAGE,
          PublicPostTypes.PHOTO,
          PublicPostTypes.TEXT,
          PublicPostTypes.VIDEO,
          PublicPostTypes.STORY,
          PublicPostTypes.CAROUSEL,
        ],
        page: data.page ?? 1,
        limit: data.page ?? 9999999,
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID}?${query}`;

    const {
      data: publicPosts,
    }: { data: GetPublicPostsByPublicUserIdResponse } = await instance.get(url);

    return publicPosts;
  };

  const getPublicPostsByPublicUserIdFilteredByKeywords = async (
    data: IGetPublicPostsByPublicUserIdFilteredByKeywordsParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserId: data.publicUserId,
        startDate: data.startDate,
        endDate: data.endDate,
        types: data.types ?? [
          PublicPostTypes.IMAGE,
          PublicPostTypes.PHOTO,
          PublicPostTypes.TEXT,
          PublicPostTypes.VIDEO,
          PublicPostTypes.STORY,
          PublicPostTypes.CAROUSEL,
        ],
        page: data.page ?? 1,
        limit: data.page ?? 9999999,
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID_FILTERED_BY_KEYWORDS}?${query}`;

    const {
      data: publicPosts,
    }: { data: GetPublicPostsByPublicUserIdFilteredByKeywordsResponse } =
      await instance.get(url);

    return publicPosts;
  };

  const getHashtagsByFilter = async (data: GetHashtagsByFilterRequest) => {
    const url = config.ENDPOINTS.POST.GET_HASHTAGS_BY_FILTER;

    const { data: hashtagsByFilter }: { data: GetHashtagsByFilterResponse[] } =
      await instance.post(url, data);

    return hashtagsByFilter;
  };

  const getEvolutionByFilter = async (data: GetEvolutionByFilterRequest) => {
    const url = config.ENDPOINTS.POST.GET_EVOLUTION_BY_FILTER;

    const {
      data: evolutionByFilter,
    }: { data: GetEvolutionByFilterResponse[] } = await instance.post(
      url,
      data,
    );

    return evolutionByFilter;
  };

  return {
    getPublicUserHashtagsSortedByMetrics,
    getPublicPostsByPublicUserId,
    getPublicPostsByPublicUserIdFilteredByKeywords,
    getHashtagsByFilter,
    getEvolutionByFilter,
  };
};

export default {
  createPostInstanceApi,
};

export interface IGetPublicPostsByPublicUserIdParams {
  publicUserIds: string[];
  startDate: number;
  endDate: number;
  types?: PublicPostTypes[];
  page?: number;
  sortBy?: Exclude<SortByCriteria, 'hashtag'>;
  sortOrder?: SortOrder;
  limit?: number;
  keywords?: string[];
  includePostIds?: string[];
  excludePostIds?: string[];
}

export interface IGetPublicPostsByPublicUserIdFilteredByKeywordsParams {
  publicUserId: string;
  startDate: number;
  endDate: number;
  types?: PublicPostTypes[];
  page?: number;
  sortBy?: Exclude<SortByCriteria, 'hashtag'>;
  sortOrder?: SortOrder;
  limit?: number;
  keywords?: string[];
  includePostIds?: string[];
  excludePostIds?: string[];
}

export type GetHashtagsByFilterRequest = {
  filters: {
    endDate: number;
    startDate: number;
    keywords: string[];
    countryCode: string[];
    network?: SocialNetworks[];
  };
  limit?: number;
  sortBy?: string;
  sortOrder?: SortOrder;
};

export type KpisByFilter = {
  avgAudience: number;
  likes: number;
  avgLikes: number;
  shares: number;
  avgShares: number;
  engRate: number;
  avgEngRate: number;
  comments: number;
  avgComments: number;
  reactions: number;
  avgReactions: number;
};

export type GetHashtagsByFilterResponse = {
  hashtag: string;
  numPosts: number;
  numUsers: number;
  kpis: KpisByFilter;
};

export type GetEvolutionByFilterRequest = {
  filters: {
    endDate: number;
    startDate: number;
    keywords: string[];
    countryCode: string[];
    network?: SocialNetworks[];
  };
  sortBy?: string;
  sortOrder?: SortOrder;
};

export type GetEvolutionByFilterResponse = {
  day: string;
  numPosts: number;
  numUsers: number;
  kpis: KpisByFilter;
};
