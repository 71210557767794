import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;

  input {
    width: 100%;
    color: #8492a6;
    height: 4.4rem;
    padding: 1.6rem;
    font-size: 1.6rem;
    line-height: 4.4rem;
    border-style: solid;
    border-width: 0.2rem;
    border-color: #f4f4f4;
    border-radius: 0.6rem;

    &::placeholder {
      color: #8492a6;
    }

    &:hover {
      border-color: #292929;
    }

    &:focus {
      border-color: #5870f6;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #8492a6;
      -webkit-box-shadow: 0 0 0px 40rem #fff inset;
    }
  }

  @media (max-width: 635px) {
    width: 100%;
  }
`;

export const InputTitle = styled.div`
  color: #676767;
  text-align: left;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

export const ErrorText = styled.span`
  color: #b00020;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: start;
  padding-left: 1rem;
`;
