import styled from 'styled-components';

export const ListContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const ListItemContainer = styled.div`
  display: flex;
  margin: 2rem 0;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.6rem;
  background-color: white;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    opacity: 50%;
  }
`;

export const ListItemLeftSide = styled.div`
  display: flex;
  margin-right: 1rem;
  flex-direction: column;
`;

export const ListItemTitle = styled.h4`
  color: #292929;
`;

export const ListItemDate = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
`;

export const ListItemRightSide = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ListItemAvatarContainer = styled.div`
  display: flex;
  border-radius: 50%;
  margin-left: -1.2rem;
  align-items: flex-end;
  border: 0.4rem solid white;

  div {
    padding: 0.2rem;
    margin-left: -1rem;
    border-radius: 0.6rem;
    margin-bottom: -0.4rem;
    background-color: white;
  }
`;

export const ListItemAvatarImg = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
`;

export const ListItemDivider = styled.div`
  opacity: 50%;
  height: 0.1rem;
  background-color: #dbdbdb;
`;
