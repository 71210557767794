import i18n from 'i18n/i18n';
import { GENDERS } from 'Services/Utils/types';

export const gendersAvailableValues = [
  {
    value: GENDERS.MALE,
    label: i18n.t('userProfile.male'),
  },
  {
    value: GENDERS.FEMALE,
    label: i18n.t('userProfile.female'),
  },
  {
    value: GENDERS.OTHER,
    label: i18n.t('userProfile.other'),
  },
];

export const categoriesAvailableValues = [
  { value: 'BRAND', label: i18n.t('discover.categories.BRAND') },
  { value: 'BLOG', label: i18n.t('discover.categories.BLOG') },
  { value: 'INFLUENCER', label: i18n.t('discover.categories.INFLUENCER') },
  { value: 'SOCIALMEDIA', label: i18n.t('discover.categories.SOCIALMEDIA') },
];
