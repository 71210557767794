import styled from 'styled-components';

export const CardContainer = styled.div`
  padding: 1rem;
  margin-top: 3rem;
  border-radius: 2rem;
  background-color: white;
`;

export const CardInnerContainer = styled.div`
  width: 100%;
  min-height: 40rem;
`;

export const CardTitle = styled.h2`
  padding: 1rem;
  color: #292929;
`;
