/* eslint-disable no-plusplus */
import moment from 'moment';
import { useGetListByIdWithPublicUsers } from 'pages/PublicUserListMembers/RQCustomHooks';
import { useGetListsByPartnerAndBusinessProfile } from 'pages/PublicUserLists/RQCustomHooks';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SocialNetworks } from 'Services/Utils/types';

import {
  useCreateBenchmarkApi,
  useSearchUserBenchmark,
  useUpdateBenchmark,
} from '../api';
import { Benchmark, SearchUserBenchmarkResponse } from '../types';

const MAX_NUMBER_USERS = 5;

export const useSubmitBenchmark = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location || {};
  const { benchmarkData }: { benchmarkData: Benchmark } = state || {};

  const customLabel = benchmarkData ? 'Update' : 'Create';
  const toastLabel = benchmarkData ? 'updated' : 'created';

  const [username, setUsername] = useState<string | undefined>();
  const [benchmarkName, setBenchmarkName] = useState<string | undefined>();
  const [socialNetwork, setSocialNetwork] = useState(SocialNetworks.INSTAGRAM);
  const [selectedUsers, setSelectedUsers] = useState<
    SearchUserBenchmarkResponse[]
  >([]);
  const [listsAvailable, setListsAvailable] = useState<any[]>([]);
  const [listSelectedId, setListSelectedId] = useState<string | undefined>();

  const { data: lists, isSuccess: isSuccessGetLists } =
    useGetListsByPartnerAndBusinessProfile({
      enabled: true,
    });

  useEffect(() => {
    const availableLists = [];

    if (lists) {
      for (let i = 0; i < lists.length; i++) {
        availableLists.push({
          label: lists[i].name,
          value: lists[i].id,
          data: lists[i],
        });
      }
      setListsAvailable(availableLists);
    }
  }, [lists, isSuccessGetLists]);

  const { data: listWithPublicUsers } = useGetListByIdWithPublicUsers({
    enabled: !!listSelectedId,
    id: listSelectedId!,
  });

  const usersFromList = listWithPublicUsers?.publicUsers.map(user => {
    return {
      username: user.socialNetwork?.username!,
      socialNetwork: user.socialNetwork?.network!,
      displayPicture: user.image!,
      publicUserId: user._id!,
    };
  });

  const handleChangeUsername = useCallback((handler: string) => {
    setUsername(handler);
  }, []);

  const handleChangeSocialNetwork = useCallback((network: SocialNetworks) => {
    setSocialNetwork(network);
  }, []);

  const handleChangeBenchmarkName = useCallback((name: string) => {
    setBenchmarkName(name);
  }, []);

  const {
    data: searchUserData,
    reset: resetSearchUser,
    mutateAsync: searchUserBenchmark,
    isLoading: isLoadingSearchUserBenchmark,
  } = useSearchUserBenchmark();

  const handleSearchUserBenchmark = useCallback(async () => {
    if (username && username.trim().length > 0) {
      await searchUserBenchmark({
        username,
        socialNetwork,
      });
    }
  }, [searchUserBenchmark, username, socialNetwork]);

  useEffect(() => {
    if (username && username.trim().length > 0) {
      handleSearchUserBenchmark();
    } else {
      resetSearchUser();
    }
  }, [username, handleSearchUserBenchmark, resetSearchUser]);

  const handleSelectUsers = useCallback(
    (user: SearchUserBenchmarkResponse) => {
      if (selectedUsers) {
        if (
          selectedUsers.some(item => item.publicUserId === user.publicUserId)
        ) {
          setSelectedUsers(
            selectedUsers.filter(
              item => item.publicUserId !== user.publicUserId,
            ),
          );
        } else if (selectedUsers.length < MAX_NUMBER_USERS) {
          setSelectedUsers([...selectedUsers, user]);
        }
      }
    },
    [selectedUsers],
  );

  const { mutateAsync: updateBenchmark, isSuccess: isSuccessUpdateBenchmark } =
    useUpdateBenchmark();

  const { mutateAsync: createBenchmark, isSuccess: isSuccessCreateBenchmark } =
    useCreateBenchmarkApi();

  const isSuccessSubmit = isSuccessUpdateBenchmark || isSuccessCreateBenchmark;

  const handleSubmitBenchmark = useCallback(async () => {
    const publicUserIds = selectedUsers.map(user => user.publicUserId);

    if (benchmarkData) {
      await updateBenchmark({
        id: benchmarkData.id,
        name:
          benchmarkName === undefined || benchmarkName.trim() === ''
            ? `Benchmark ${moment().format('YYYY-MM-DD HH:mm:ss')}`
            : benchmarkName!,
        list: publicUserIds,
      });
    } else {
      await createBenchmark({
        name:
          benchmarkName === undefined || benchmarkName.trim() === ''
            ? `Benchmark ${moment().format('YYYY-MM-DD HH:mm:ss')}`
            : benchmarkName!,
        list: publicUserIds,
        usersInfo: selectedUsers,
      });
    }
  }, [benchmarkData, benchmarkName, selectedUsers, createBenchmark]);

  useEffect(() => {
    if (isSuccessCreateBenchmark) {
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
    if (isSuccessUpdateBenchmark) {
      setTimeout(() => {
        navigate('/benchmarks-list');
      }, 1000);
    }
  }, [isSuccessCreateBenchmark, isSuccessUpdateBenchmark, navigate]);

  useEffect(() => {
    if (benchmarkData) {
      setBenchmarkName(benchmarkData.name);
      setSelectedUsers(
        benchmarkData.usersBasicInfo.map(user => ({
          username: user.username ?? '',
          socialNetwork: user.network!,
          displayPicture: user.image!,
          publicUserId: user.id,
        })),
      );
    }
  }, [benchmarkData]);

  return {
    username,
    toastLabel,
    customLabel,
    selectedUsers,
    socialNetwork,
    benchmarkName,
    searchUserData: searchUserData ?? usersFromList,
    listsAvailable,
    listSelectedId,
    isSuccessSubmit,
    setListSelectedId,
    handleSelectUsers,
    handleChangeUsername,
    handleSubmitBenchmark,
    handleChangeSocialNetwork,
    handleChangeBenchmarkName,
    isLoadingSearchUserBenchmark,
  };
};
