import { GetHashtagsByFilterResponse } from 'Services/SkorrApi/new/Post';
import { convertNumberToHumanStringfiedFormat } from 'Services/Utils/Utils';

import * as S from './Styles';

type Props = {
  customHook: any;
};

export function ListeningTermOverview({ customHook }: Props) {
  const { term, termData, timeFrame, hashtagsByFilter } = customHook;

  return (
    <S.FullWidthRow>
      <S.ContentContainer>
        <S.ContentInnerContainer>
          <S.ContentHeadRow>
            <S.TermNameContainer>
              <S.TermName>{term}</S.TermName>
            </S.TermNameContainer>
          </S.ContentHeadRow>

          <S.ContentMiddleRow>
            <S.HashtagsRow>
              <S.HashtagsSymbol />
              {hashtagsByFilter
                ?.slice(0, 5)
                .map((tag: GetHashtagsByFilterResponse) => (
                  <span>{tag.hashtag}</span>
                ))}
            </S.HashtagsRow>

            <S.DateTimeframe>
              <span>{timeFrame}</span>
            </S.DateTimeframe>
          </S.ContentMiddleRow>

          <S.TermKpisContainer>
            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  termData?.kpis.avgAudience ?? 0,
                )}
              </S.MetricValue>
              <S.MetricLabel>Avg. Audience</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  Math.round(termData?.numPosts ?? 0),
                )}
              </S.MetricValue>
              <S.MetricLabel>Total Posts</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>{`${(
                (termData?.kpis.avgEngRate ?? 0) * 100
              ).toFixed(3)}%`}</S.MetricValue>
              <S.MetricLabel>Avg. Eng. Rate</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  Math.round(termData?.kpis.avgLikes ?? 0),
                )}
              </S.MetricValue>
              <S.MetricLabel>Avg. Likes</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  Math.round(termData?.kpis.avgComments ?? 0),
                )}
              </S.MetricValue>
              <S.MetricLabel>Avg. Comments</S.MetricLabel>
            </S.MetricContainer>
          </S.TermKpisContainer>
        </S.ContentInnerContainer>
      </S.ContentContainer>
    </S.FullWidthRow>
  );
}
