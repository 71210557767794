/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { AxiosInstance } from 'axios';
import {
  BenchmarkOutputRaw,
  CreateBenchmarkRequest,
  GetAllBenchmarksRequest,
  SearchUserBenchmarkRequest,
  SearchUserBenchmarkResponse,
  UpdateBenchmarkRequest,
} from 'features/Benchmark/types';
import queryString from 'query-string';

import {
  ISendAccountDeletionEmailPayload,
  ISignUpPayload,
  PublicUser,
  SocialNetworks,
} from '../../../Utils/types';
import config from '../../../config';
import * as T from './types';
import {
  AddUsersToListRequest,
  AddUsersToListResponse,
  AllKpisOverviewDaily,
  AudienceAgeDistribution,
  AudienceGenderDistribution,
  AudienceGeo,
  AudienceReachabilityDistribution,
  AudienceTypesDistribution,
  GetAudienceGendersOutput,
  GetAudienceGendersPerAgeOutput,
  GetKpisOverviewDailyInput,
  GetKpisOverviewDailyOutput,
  GetKpisOverviewInput,
  GetKpisOverviewOutput,
  GetPublicUserAudienceGeoOutput,
  GetPublicUserAudienceReachabilityOutput,
  GetPublicUserAudienceTypesOutput,
  GetPublicUsersByFilterRequest,
  GetPublicUsersByFilterResponse,
  GetPublicUsersInfoInput,
  KpisOverviewDailyAggregators,
  PostTypes,
} from './types';

const createUserInstanceApi = (instance: AxiosInstance) => {
  const createBusinessProfile = (params: T.ICreateBusinessProfileParams) =>
    instance.post(config.ENDPOINTS.USER.CREATE_BUSINESS_PROFILE, params);
  const updateBusinessProfile = (params: T.IUpdateBusinessProfileParams) =>
    instance.post(config.ENDPOINTS.USER.UPDATE_BUSINESS_PROFILE, params);
  const deleteBusinessProfile = (businessProfileId: string) =>
    instance.get(
      `${config.ENDPOINTS.USER.DELETE_BUSINESS_PROFILE}/${businessProfileId}`,
    );
  const getBusinessProfiles = () =>
    instance.get(config.ENDPOINTS.USER.GET_BUSINESS_PROFILES);
  const getBusinessProfile = (businessProfileId: string) =>
    instance.get(
      `${config.ENDPOINTS.USER.GET_BUSINESS_PROFILE_BY_ID}/${businessProfileId}`,
    );
  const getBusinessProfilesByPartnerId = async (): Promise<
    T.BusinessProfile[]
  > => {
    const { data } = await instance.get<T.BusinessProfile[]>(
      config.ENDPOINTS.USER.GET_BUSINESS_PROFILE_BY_PARTNER_ID,
    );

    return data;
  };

  const getAlreadyExistingSocialNetworkAccounts = (
    userId: string | undefined | null,
    socialNetworkAccounts: Array<{ socialNetwork: string; profileId: string }>,
  ) => {
    const args: any = { socialNetworkAccounts };
    if (userId != null) {
      args.userId = userId;
    }
    return instance.post(
      config.ENDPOINTS.USER.GET_ALREADY_EXISTING_SOCIAL_NETWORK_ACCOUNTS,
      args,
    );
  };
  const signup = (params: ISignUpPayload) =>
    instance.post(config.ENDPOINTS.AUTH.SIGN_UP, params);
  // const getSocialTokensByUserId = (userId: string) => instance.get(config.ENDPOINTS.USER.GET_SOCIAL_TOKENS_BY_USER_ID + '/' + userId)
  const getSocialTokensByUserId = () =>
    instance.get(config.ENDPOINTS.USER.GET_SOCIAL_TOKENS_BY_USER_ID);
  const isAdditionalDataStepComplete = () =>
    instance.get(config.ENDPOINTS.USER.IS_ADDITIONAL_DATA_STEP_COMPLETE);
  const completeAdditionalDataStep = (
    params: T.ICompleteAdditionalDataStepParams,
  ) =>
    instance.post(config.ENDPOINTS.USER.COMPLETE_ADDITIONAL_DATA_STEP, params);
  const getPartnerWebsite = () =>
    instance.get(config.ENDPOINTS.USER.GET_PARTNER_WEBSITE);
  const isAgentSkorrAdmin = (agentId: string) =>
    instance.get(`${config.ENDPOINTS.USER.IS_AGENT_SKORR_ADMIN}/${agentId}`);
  const getAgentRoleIds = (agentId: string) =>
    instance.get(`${config.ENDPOINTS.USER.GET_AGENT_ROLE_IDS}/${agentId}`);
  const deletePartner = () =>
    instance.post(config.ENDPOINTS.USER.DELETE_PARTNER);
  const deleteAgentById = (agentId: string) =>
    instance.put(`${config.ENDPOINTS.USER.DELETE_AGENT_BY_ID}/${agentId}`);
  const getAgentsByPartnerId = (partnerId: string) =>
    instance.get(
      `${config.ENDPOINTS.USER.GET_AGENTS_BY_PARTNER_ID}/${partnerId}`,
    );
  const getAgentById = (agentId: string) =>
    instance.get(`${config.ENDPOINTS.USER.GET_AGENT_BY_ID}/${agentId}`);
  const getPartnerById = (partnerId: string) =>
    instance.get(`${config.ENDPOINTS.USER.GET_PARTNER_BY_ID}/${partnerId}`);
  const getSelfUser = () => instance.get(config.ENDPOINTS.USER.GET_USER_BY_ID);
  const updateAccount = (params: T.IUpdateAccountParams) =>
    instance.post(config.ENDPOINTS.USER.UPDATE_ACCOUNT, params);
  const sendAccountDeletionEmail = (params: ISendAccountDeletionEmailPayload) =>
    instance.post(config.ENDPOINTS.USER.SEND_ACCOUNT_DELETION_EMAIL, params);
  const getPublicUsersImageById = (params: string[]) =>
    instance.post(config.ENDPOINTS.USER.GET_PUBLIC_USER_IMAGE_BY_ID, params);
  const getPublicUserBasicInformationWithExternalProvider = (
    params: T.GetPublicUserBasicInformationWithExternalProviderParams,
  ) =>
    instance.post(
      config.ENDPOINTS.USER
        .GET_PUBLIC_USER_BASIC_INFORMATION_WITH_EXTERNAL_PROVIDER,
      params,
    );
  const updateUserPublicProfiles = (params: T.UpdateUserPublicProfilesParams) =>
    instance.patch(config.ENDPOINTS.USER.UPDATE_USER_PUBLIC_PROFILES, params);
  const getKpisOverview = async (data: GetKpisOverviewInput) => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startAt:
          String(data.startDate) ??
          new Date().getTime() - 30 * 24 * 3600 * 1000,
        endAt: String(data.endDate) ?? new Date().getTime(),
        types: data.types ?? [
          PostTypes.IMAGE,
          PostTypes.VIDEO,
          PostTypes.TEXT,
          PostTypes.STORY,
        ],
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_KPIS_OVERVIEW}?${query}`;

    const { data: result }: { data: GetKpisOverviewOutput } =
      await instance.get(url);
    return result[0].kpis;
  };

  const getPublicUserById = async (publicUserIds: string[]) => {
    const query = queryString.stringify(
      {
        publicUserIds,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_PUBLIC_USERS_BY_ID}?${query}`;

    const { data }: { data: { publicUsers: PublicUser[] } } =
      await instance.get(url);
    return data.publicUsers;
  };

  const getKpisOverviewDaily = async (data: GetKpisOverviewDailyInput) => {
    const timeIntervalInMilliseconds = data.endDate! - data.startDate!;

    const socialNetwork =
      data.socialNetwork === SocialNetworks.YOUTUBE
        ? SocialNetworks.YOUTUBE
        : SocialNetworks.FACEBOOKPAGEINSTAGRAM;

    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startAt: data.startDate! - timeIntervalInMilliseconds,
        endAt: data.endDate,
        types: data.types ?? [PostTypes.IMAGE, PostTypes.VIDEO, PostTypes.TEXT],
        growthDays: 30,
        kpis: data.kpis ?? [
          AllKpisOverviewDaily.FOLLOWERS,
          AllKpisOverviewDaily.UNFOLLOWERS,
          AllKpisOverviewDaily.LIKES,
          AllKpisOverviewDaily.COMMENTS,
          AllKpisOverviewDaily.SHARES,
          AllKpisOverviewDaily.REACTIONS,
          AllKpisOverviewDaily.IMPRESSIONS,
          AllKpisOverviewDaily.REACH,
          AllKpisOverviewDaily.POSTS,
          AllKpisOverviewDaily.ENG_RATE,
        ],
        aggregator: KpisOverviewDailyAggregators.AVG,
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_KPIS_OVERVIEW_DAILY}?${query}`;

    const { data: result }: { data: GetKpisOverviewDailyOutput } =
      await instance.get(url);

    const kpiDayMap = new Map<number, { [key: string]: number }>();

    for (const kpi of Object.keys(result[0]?.data)) {
      result[0].data[kpi as AllKpisOverviewDaily].forEach(kpiDay => {
        const dayDate = Date.parse(kpiDay.date);

        let kpiKey;

        switch (kpi) {
          case 'followers': {
            kpiKey = 'audience';
            break;
          }
          case 'reactions': {
            kpiKey = 'engagement';
            break;
          }
          case 'engRate': {
            kpiKey = 'engagement_rate';
            break;
          }
          case 'impressions': {
            kpiKey = 'insight_post_impressions';
            break;
          }
          case 'reach': {
            kpiKey = 'insight_post_reach';
            break;
          }
          default: {
            kpiKey = kpi;
          }
        }

        if (kpiDayMap.has(dayDate)) {
          const oldvalue = kpiDayMap.get(dayDate);

          kpiDayMap.set(dayDate, {
            ...oldvalue,

            [`${socialNetwork}.${kpiKey}`]: kpiDay.v,
          });
        } else {
          kpiDayMap.set(dayDate, {
            [`${socialNetwork}.${kpiKey}`]: kpiDay.v,
          });
        }
      });
    }

    const formattedKpisLocal = {
      networkSelectedId: socialNetwork,
      networks: [socialNetwork],
      date: [
        {
          startDate: Number(data.startDate),
          endDate: Number(data.endDate),
        },
      ],
      dataKPIs: Array.from(kpiDayMap.entries()).map(entry => ({
        date: new Date(entry[0]).getTime(),
        ...entry[1],
        networks: [socialNetwork],
      })),
    };

    return formattedKpisLocal;
  };

  const getPublicUserAudienceAgeGroups = async (
    data: GetPublicUsersInfoInput,
  ): Promise<AudienceAgeDistribution> => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startAt: String(data.startDate),
        endAt: String(data.endDate),
        infoType: 'AUDIENCE_GENDERS_PER_AGE',
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_PUBLIC_USER_AUDIENCE_GENDERS_PER_AGE}?${query}`;

    const { data: result }: { data: GetAudienceGendersPerAgeOutput } =
      await instance.get(url);

    const ageGroupPercentages: { [key: string]: number } = {};

    let ageGroupPercentagesSum = 0;

    result[0]?.data.forEach(ageGroup => {
      const ageGroupPercentage = Number(
        (
          ((ageGroup.male + ageGroup.female) / result[0].audience) *
          100
        ).toFixed(2),
      );

      ageGroupPercentages[ageGroup.code] = ageGroupPercentage;

      ageGroupPercentagesSum += ageGroupPercentage;
    });

    for (const ageGroup in ageGroupPercentages) {
      ageGroupPercentages[ageGroup] = Number(
        (
          (ageGroupPercentages[ageGroup] / ageGroupPercentagesSum) *
          100
        ).toFixed(2),
      );
    }

    return ageGroupPercentages as unknown as AudienceAgeDistribution;
  };

  const getPublicUserAudienceGenders = async (
    data: GetPublicUsersInfoInput,
  ): Promise<AudienceGenderDistribution> => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startAt: String(data.startDate),
        endAt: String(data.endDate),
        infoType: 'AUDIENCE_GENDERS',
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_PUBLIC_USER_AUDIENCE_GENDERS}?${query}`;

    const { data: result }: { data: GetAudienceGendersOutput } =
      await instance.get(url);

    const audienceGenders: any = {
      male: 0,
      female: 0,
    };

    let sum = 0;

    result[0]?.data.forEach(gender => {
      const genderValue = Number((gender.weight * 100).toFixed(2));
      audienceGenders[gender.code.toLowerCase()] = genderValue;
      sum += genderValue;
    });

    for (const gender in audienceGenders) {
      audienceGenders[gender] = Number(
        ((audienceGenders[gender] / sum) * 100).toFixed(2),
      );
    }

    return audienceGenders as unknown as AudienceGenderDistribution;
  };

  const getPublicUserAudienceGeo = async (
    data: GetPublicUsersInfoInput,
  ): Promise<AudienceGeo> => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startAt: String(data.startDate),
        endAt: String(data.endDate),
        infoType: 'AUDIENCE_GEO',
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_PUBLIC_USER_AUDIENCE_GEO}?${query}`;

    const { data: result }: { data: GetPublicUserAudienceGeoOutput } =
      await instance.get(url);

    const audienceGeo: AudienceGeo = {
      countries: {},
      cities: {},
    };

    const sum = {
      countries: 0,
      cities: 0,
    };

    for (const key in result[0]?.data) {
      result[0].data[key as 'countries' | 'cities'].forEach((location: any) => {
        const locationWeight = Number(location.weight.toFixed(2));

        if (key === 'countries') {
          audienceGeo.countries[location.name] = {
            weight: locationWeight,
            code: location.code,
          };
        } else {
          audienceGeo.cities[location.name] = locationWeight;
        }

        sum[key as 'countries' | 'cities'] += locationWeight;
      });
    }

    for (const country in audienceGeo.countries) {
      audienceGeo.countries[country] = {
        ...audienceGeo.countries[country],
        weight: Number(
          (
            (audienceGeo.countries[country].weight / sum.countries) *
            100
          ).toFixed(2),
        ),
      };
    }

    for (const city in audienceGeo.cities) {
      audienceGeo.cities[city] = Number(
        ((audienceGeo.cities[city] / sum.cities) * 100).toFixed(2),
      );
    }

    return audienceGeo;
  };

  const getPublicUserAudienceTypes = async (
    data: GetPublicUsersInfoInput,
  ): Promise<AudienceTypesDistribution> => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startAt: String(data.startDate),
        endAt: String(data.endDate),
        infoType: 'AUDIENCE_TYPES',
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_PUBLIC_USER_AUDIENCE_TYPES}?${query}`;

    const { data: result } =
      await instance.get<GetPublicUserAudienceTypesOutput>(url);

    const audienceTypes = {
      massFollowers: 0,
      suspicious: 0,
      influencers: 0,
      real: 0,
    };

    let weightSum = 0;

    (result as unknown as GetPublicUserAudienceTypesOutput)[0]?.data.forEach(
      audienceType => {
        weightSum += audienceType.weight;

        switch (audienceType.code) {
          case 'mass_followers':
            audienceTypes.massFollowers = audienceType.weight;
            break;
          default:
            audienceTypes[audienceType.code] = audienceType.weight;
        }
      },
    );

    Object.keys(audienceTypes).forEach(type => {
      audienceTypes[type as keyof typeof audienceTypes] = Number(
        (
          (audienceTypes[type as keyof typeof audienceTypes] / weightSum) *
          100
        ).toFixed(2),
      );
    });

    return audienceTypes;
  };

  const getPublicUserAudienceReachability = async (
    data: GetPublicUsersInfoInput,
  ): Promise<AudienceReachabilityDistribution> => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startAt: String(data.startDate),
        endAt: String(data.endDate),
        infoType: 'AUDIENCE_REACHABILITY',
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_PUBLIC_USER_AUDIENCE_REACHABILITY}?${query}`;

    const { data: result } =
      await instance.get<GetPublicUserAudienceReachabilityOutput>(url);

    const audienceReachability: AudienceReachabilityDistribution = {};

    let weightSum = 0;

    (
      result as unknown as GetPublicUserAudienceReachabilityOutput
    )[0]?.data.forEach(reachabilityRange => {
      weightSum += reachabilityRange.weight;

      audienceReachability[reachabilityRange.code] = reachabilityRange.weight;
    });

    Object.keys(audienceReachability).forEach(reachability => {
      audienceReachability[reachability as keyof typeof audienceReachability] =
        Number(
          (
            (audienceReachability[
              reachability as keyof typeof audienceReachability
            ] /
              weightSum) *
            100
          ).toFixed(2),
        );
    });

    return audienceReachability;
  };

  const getPublicUsersByFilter = async (
    data: GetPublicUsersByFilterRequest,
  ): Promise<GetPublicUsersByFilterResponse> => {
    const url = config.ENDPOINTS.USER.GET_PUBLIC_USERS_BY_FILTER;

    const { data: result } = await instance.post(url, data);

    return result;
  };

  const createList = (params: T.CreateListInput) =>
    instance.post(config.ENDPOINTS.USER.CREATE_LIST, params);

  const updateListById = (params: T.UpdateListByIdInput) =>
    instance.put(config.ENDPOINTS.USER.UPDATE_LIST_BY_ID, params);

  const getListById = async (
    data: T.GetListByIdInput,
  ): Promise<T.GetListByIdOutput> => {
    const query = queryString.stringify(
      {
        id: data.id,
        memberCount: data.memberCount ?? false,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_LIST_BY_ID}?${query}`;

    const { data: result } = await instance.get(url);

    return result;
  };

  const getListsByPartnerAndBusinessProfile = async (
    data: T.GetListsByPartnerAndBusinessProfileInput,
  ): Promise<T.GetListsByPartnerAndBusinessProfileOutput> => {
    const query = queryString.stringify(
      {
        type: data.type,
        memberCount: data.memberCount ?? false,
        page: data.page,
        limit: data.limit,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_LISTS_BY_PARTNER_AND_BUSINESS_PROFILE}?${query}`;

    const { data: result } = await instance.get(url);

    return result;
  };

  const getListByIdWithPublicUsers = async (
    id: string,
  ): Promise<T.GetListByIdWithPublicUsersOutput> => {
    const query = queryString.stringify(
      {
        id,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_LIST_BY_ID_WITH_PUBLIC_USERS}?${query}`;

    const { data: result } = await instance.get(url);

    return result;
  };

  const deleteListById = async (id: string): Promise<boolean> => {
    const query = queryString.stringify(
      {
        id,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.DELETE_LIST_BY_ID}?${query}`;

    const { data: result } = await instance.put(url);

    return result;
  };

  const deletePublicUserFromList = async (
    listId: string,
    publicUserId: string[],
  ): Promise<number> => {
    const url = `${config.ENDPOINTS.USER.DELETE_PUBLIC_USER_FROM_LIST}`;

    const { data: result } = await instance.put(url, {
      listId,
      publicUserId,
    });

    return result;
  };

  const addUsersToList = async (
    data: AddUsersToListRequest,
  ): Promise<AddUsersToListResponse> => {
    const url = config.ENDPOINTS.USER.ADD_PUBLIC_USER_TO_LIST;

    const { data: result } = await instance.post(url, data);

    return result;
  };

  const getAllBenchmarks = async (
    data: GetAllBenchmarksRequest,
  ): Promise<BenchmarkOutputRaw[]> => {
    const query = queryString.stringify(
      {
        page: data.page,
        limit: data.limit,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.USER.GET_ALL_BENCHMARKS}?${query}`;

    const { data: result } = await instance.get(url);

    return result;
  };

  const getTotalBenchmarks = async (): Promise<number> => {
    const url = config.ENDPOINTS.USER.GET_TOTAL_BENCHMARKS;

    const { data: result } = await instance.get(url);

    return result;
  };

  const createBenchmark = async (
    data: CreateBenchmarkRequest,
  ): Promise<boolean> => {
    const url = config.ENDPOINTS.USER.CREATE_BENCHMARK;

    const { data: result } = await instance.post(url, data);

    return result;
  };

  const updateBenchmark = async (
    data: UpdateBenchmarkRequest,
  ): Promise<boolean> => {
    const url = `${config.ENDPOINTS.USER.UPDATE_BENCHMARK}/${data.id}`;

    const { data: result } = await instance.put(url, {
      name: data.name,
      list: data.list,
    });

    return result;
  };

  const deleteBenchmark = async (id: string): Promise<boolean> => {
    const url = `${config.ENDPOINTS.USER.DELETE_BENCHMARK}/${id}`;

    const { data: result } = await instance.delete(url);

    return result;
  };

  const searchUserBenchmark = async (
    data: SearchUserBenchmarkRequest,
  ): Promise<SearchUserBenchmarkResponse[]> => {
    const url = config.ENDPOINTS.USER.SEARCH_USER_BENCHMARK;

    const { data: result } = await instance.post(url, data);

    return result;
  };

  const updateAgentAndUser = async (
    data: T.UpdateAgentAndUserInput,
  ): Promise<Boolean> => {
    const url = config.ENDPOINTS.USER.UPDATE_AGENT_AND_USER;

    const { data: result } = await instance.put(url, data);

    return result;
  };

  const updatePartner = async (
    data: T.UpdatePartnerInput,
  ): Promise<Boolean> => {
    const url = config.ENDPOINTS.USER.UPDATE_PARTNER;

    const { data: result } = await instance.put(url, data);

    return result;
  };

  const getFacebookAccountsToSync = async (
    data: T.GetFacebookAccountsToSyncInput,
  ): Promise<T.GetFacebookAccountsToSyncOutput> => {
    const url = config.ENDPOINTS.USER.GET_FACEBOOK_ACCOUNTS_TO_SYNC;

    const { data: result } = await instance.post(url, data);

    return result;
  };

  const updateSyncUsers = async (data: T.UpdateSyncUsersInput) => {
    const url = config.ENDPOINTS.USER.UPDATE_SYNC_USERS;

    await instance.put(url, data);
  };

  const getTiktokAccountsToSync = async (
    data: T.GetTiktokAccountsToSyncInput,
  ): Promise<T.GetTiktokAccountsToSyncOutput> => {
    const url = config.ENDPOINTS.USER.GET_TIKTOK_ACCOUNTS_TO_SYNC;

    const { data: result } = await instance.post(url, data);

    return result;
  };

  return {
    createBusinessProfile,
    updateBusinessProfile,
    deleteBusinessProfile,
    getBusinessProfiles,
    getBusinessProfile,
    getAlreadyExistingSocialNetworkAccounts,
    signup,
    getSocialTokensByUserId,
    isAdditionalDataStepComplete,
    completeAdditionalDataStep,
    getPartnerWebsite,
    isAgentSkorrAdmin,
    getAgentRoleIds,
    deletePartner,
    deleteAgentById,
    getAgentsByPartnerId,
    getAgentById,
    getPartnerById,
    sendAccountDeletionEmail,
    getBusinessProfilesByPartnerId,
    getSelfUser,
    updateAccount,
    getPublicUsersImageById,
    getPublicUserBasicInformationWithExternalProvider,
    updateUserPublicProfiles,
    getKpisOverview,
    getPublicUserById,
    getKpisOverviewDaily,
    getPublicUserAudienceAgeGroups,
    getPublicUserAudienceGenders,
    getPublicUserAudienceGeo,
    getPublicUserAudienceTypes,
    getPublicUserAudienceReachability,
    getPublicUsersByFilter,
    createList,
    updateListById,
    getListById,
    getListsByPartnerAndBusinessProfile,
    getListByIdWithPublicUsers,
    deleteListById,
    deletePublicUserFromList,
    addUsersToList,
    getAllBenchmarks,
    getTotalBenchmarks,
    createBenchmark,
    updateBenchmark,
    deleteBenchmark,
    searchUserBenchmark,
    updateAgentAndUser,
    updatePartner,
    getFacebookAccountsToSync,
    updateSyncUsers,
    getTiktokAccountsToSync,
  };
};

export default {
  createUserInstanceApi,
};
