import { BenchmarkOutput } from 'features/Benchmark/types';
import moment from 'moment';
import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getSocialNetworkIcon } from 'Services/Utils/Utils';

import * as S from './Styles';

type Props = {
  data: BenchmarkOutput[];
};

const BenchmarkList = ({ data }: Props) => {
  const navigate = useNavigate();

  const handleGoToViewBenchmark = useCallback(
    (data: BenchmarkOutput) => {
      navigate(`/view-benchmark/${data.benchmarkInfo.id}`, {
        state: {
          benchmarkData: data,
        },
      });
    },
    [navigate],
  );

  return (
    <S.ListContainer>
      {data.map((bench, index) => {
        const lastItem = data.length - 1 === index;

        return (
          <>
            <S.ListItemContainer onClick={() => handleGoToViewBenchmark(bench)}>
              <S.ListItemLeftSide>
                <S.ListItemTitle>{bench.benchmarkInfo.name}</S.ListItemTitle>
                <S.ListItemDate>
                  {moment(bench.benchmarkInfo.createdAt).fromNow()}
                </S.ListItemDate>
              </S.ListItemLeftSide>

              <S.ListItemRightSide>
                {bench.benchmarkInfo.usersBasicInfo.map((user, index) => {
                  const lastUser =
                    bench.benchmarkInfo.usersBasicInfo.length - 1 === index;

                  return (
                    <S.ListItemAvatarContainer key={user.id}>
                      <S.ListItemAvatarImg src={user.image} />
                      {lastUser &&
                        getSocialNetworkIcon({
                          socialNetwork: user.network ?? '',
                          width: '2rem',
                          height: '2rem',
                          gradientColored: true,
                        })}
                    </S.ListItemAvatarContainer>
                  );
                })}
              </S.ListItemRightSide>
            </S.ListItemContainer>

            {!lastItem && <S.ListItemDivider />}
          </>
        );
      })}
    </S.ListContainer>
  );
};

export default withTranslation()(BenchmarkList);
