import { benchmarkColors } from 'features/Benchmark/data/constants';
import { BenchmarkOutput } from 'features/Benchmark/types';
import { withTranslation } from 'react-i18next';
import {
  convertNumberToHumanStringfiedFormat,
  getSocialNetworkIcon,
} from 'Services/Utils/Utils';

import * as S from './Styles';

type Props = {
  benchmarkConfig: BenchmarkOutput;
};

const BenchmarkOverviewCompetitors = ({ benchmarkConfig }: Props) => {
  return (
    <S.MainContainer>
      {benchmarkConfig.kpisOverview.map((user, index) => {
        const isLastUser = benchmarkConfig.kpisOverview.length - 1 === index;

        return (
          <S.UserCardContainer
            isLastItem={isLastUser}
            borderColor={benchmarkColors[index]}
          >
            <S.TopSectionContainer>
              <S.UserAvatarInfoContainer>
                <S.UserAvatarInfoImg src={user.displayPicture} />
                {getSocialNetworkIcon({
                  socialNetwork: user.socialNetwork ?? '',
                  width: '2rem',
                  height: '2rem',
                  gradientColored: true,
                })}
              </S.UserAvatarInfoContainer>

              <S.UserInfoContainer>
                <S.UserInfoName>{user.name}</S.UserInfoName>
                <S.UserInfoNetwork>{user.socialNetwork}</S.UserInfoNetwork>
              </S.UserInfoContainer>
            </S.TopSectionContainer>

            <S.DividerLine />

            <S.UserMetricsContainer>
              <S.UserMetricContainer>
                <S.UserMetricValue>{user.kpis.total.posts}</S.UserMetricValue>
                <S.UserMetricLabel>Posts</S.UserMetricLabel>
              </S.UserMetricContainer>

              <S.UserMetricContainer>
                <S.UserMetricValue>
                  {convertNumberToHumanStringfiedFormat(
                    user.kpis.total.followers,
                  )}
                </S.UserMetricValue>
                <S.UserMetricLabel>Followers</S.UserMetricLabel>
              </S.UserMetricContainer>
            </S.UserMetricsContainer>
          </S.UserCardContainer>
        );
      })}
    </S.MainContainer>
  );
};

export default withTranslation()(BenchmarkOverviewCompetitors);
