/* eslint-disable no-empty */
export const fbInit = () => {
  (window as any).fbAsyncInit = () => {
    (window as any).FB.init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      cookie: false,
      xfbml: false,
      version: 'v21.0',
    });
  };

  (window as any).fbAsyncInit();
};

type fbLoginInput = {
  successCallback?: (loginResponse: any) => void;
  errorCallback?: () => void;
};

export const fbLogin = async ({
  successCallback,
  errorCallback,
}: fbLoginInput) => {
  try {
    await (window as any).FB.login(
      (value: any) => {
        if (value.authResponse) {
          if (successCallback) {
            successCallback(value);
          }
        }

        if (!value.authResponse) {
          if (errorCallback) {
            errorCallback();
          }
        }
      },
      {
        scope:
          'public_profile,user_friends,pages_show_list,instagram_basic,pages_read_engagement,pages_read_user_content,instagram_manage_insights,business_management',
      },
    );
  } catch (e: any) {}
};
