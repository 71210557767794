/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import { useMutation } from 'react-query';

import { logApiRequestError } from '../../Services/Utils/Utils';
import { DEFAULT_USER_AVATAR, IErrorObject } from '../../Services/Utils/types';
import { IUseUpdatePartnerParams } from './types';
import SkorrApi from '../../Services/SkorrApi/new';

const { user } = SkorrApi;
const { files } = SkorrApi;

export const useUpdatePartner = () => {
  return useMutation(
    async (params: IUseUpdatePartnerParams) => {
      try {
        const { data: imageUrl }: { data: string } = params.pictureData
          ? await files.uploadImage(params.pictureData!)
          : { data: '' };
        if (imageUrl) {
          params.updateData.displayPicture = imageUrl;
        } else {
          params.updateData.displayPicture =
            params.initialPicture || DEFAULT_USER_AVATAR;
        }
        params.updateData.img = params.updateData.displayPicture;
        const result = await user.updatePartner(params.updateData);
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
