import styled from 'styled-components';
import { ITitleProps } from 'pages/BusinessProfile/types';
import {
  IAddSocialAccountsInnerContainerProps,
  IConnectInstagramAccountButtonProps,
} from 'pages/SocialAccountsManagement/types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 75%;
  border-radius: 2.4rem;
  height: auto;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1180px) {
    width: 85%;
  }

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const CardInnerContainer = styled.div`
  padding: 0 3.2rem;
`;

export const AddSocialAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const Title = styled.div<ITitleProps>`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  text-align: left;
`;

export const ConnectSocialAccountButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0rem;
  margin: 3rem 0;

  @media (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ConnectInstagramAccountButton = styled.div<IConnectInstagramAccountButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props =>
    props.disabled
      ? '#F4F4F4'
      : 'linear-gradient(90deg, #FFEA00 -29.55%, #FF00E1 128.36%)'};
  background-blend-mode: multiply;
  height: 5.2rem;
  width: 31rem;
  border-radius: 1rem;
  color: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1.8rem 1.55rem;
  cursor: pointer;
  user-select: none;

  svg path {
    fill: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  }

  &:hover {
    background: linear-gradient(90deg, #fff059 -29.55%, #ff47f2 128.36%);
    color: #ffffff;

    svg path {
      fill: #ffffff;
    }
  }

  & > .hidden-element {
    width: 2rem;
    height: 2rem;
  }

  @media (max-width: 1500px) {
    margin-bottom: 1rem;
  }
`;

export const HiddenElement = styled.div`
  visibility: hidden;
`;

export const ConnectYoutubeAccountButton = styled.div<IConnectInstagramAccountButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.disabled ? '#F4F4F4' : '#FF0000')};
  height: 5.2rem;
  width: 31rem;
  border-radius: 1rem;
  color: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1.8rem 1.55rem;
  cursor: pointer;
  user-select: none;

  svg path {
    fill: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  }

  &:hover {
    background-color: #ff6d6d;
    color: #ffffff;

    svg path {
      fill: #ffffff;
    }
  }

  & > .hidden-element {
    width: 2rem;
    height: 2rem;
  }

  @media (max-width: 1500px) {
    margin-bottom: 1rem;
  }
`;

export const ConnectTiktokAccountButton = styled.div<IConnectInstagramAccountButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.disabled ? '#F4F4F4' : '#0C0C0C')};
  height: 5.2rem;
  width: 31rem;
  max-width: 31rem;
  border-radius: 1rem;
  color: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1.8rem 1.55rem;
  user-select: none;
  position: relative;

  & > .hidden-element {
    width: 2rem;
    height: 2rem;
  }
`;

export const TiktokSoonContainer = styled.div`
  position: absolute;
  top: 55%;
  right: 0.8rem;
`;

export const AddSocialAccountsInnerContainer = styled.div<IAddSocialAccountsInnerContainerProps>`
  display: flex;
  margin-bottom: 3rem;
  height: ${props => (props.visible ? '14rem' : 0)};
  overflow: hidden;
  transition: all 0.2s ease-out;
`;

export const UsernameInputContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 70%;
  }

  @media (max-width: 620px) {
    width: 80%;
  }
`;

export const UsernameInputInformationContainer = styled.div`
  display: flex;
  color: #676767;
  font-size: 1.4rem;
  font-weight: 400;

  svg path {
    fill: #bfbfbf;
  }
`;

export const UsernameInputInfoIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const UsernameInputInformationInnerContainer = styled.div`
  word-break: break-word;
`;

export const AddSocialAccountButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: start;
  margin-top: 3rem;
  margin-left: 3rem;

  @media (max-width: 1000px) {
    margin-left: auto;
  }
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
