import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;

  input {
    color: #8492a6;

    &::placeholder {
      color: #8492a6;
    }
  }

  @media (max-width: 635px) {
    width: 100%;
  }
`;

export const InputTitle = styled.div`
  color: #676767;
  text-align: left;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;
