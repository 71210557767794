import styled from 'styled-components';

export const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 2rem;
  flex-direction: column;
`;

export const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const MainTitle = styled.h1`
  color: #292929;
`;

export const ListContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
