import styled from 'styled-components';

export const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 2rem;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GoBackButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 1rem 1rem -1rem;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const MainTitle = styled.h1`
  color: #292929;
`;
