import styled from 'styled-components';

export const SubmitFormContainer = styled.div`
  margin-top: 3rem;
`;

export const SubmitFormTitle = styled.h2`
  color: #292929;
  margin-bottom: 1rem;
`;

export const SubmitFormSubtitle = styled.p`
  color: #696969;
  margin-bottom: 1rem;
`;

export const SubmitFormInputsContainer = styled.div`
  gap: 1rem;
  display: flex;
  margin-top: 3rem;
`;

export const InputContainer = styled.div`
  display: flex;
  padding: 0 1.2rem;
  width: fit-content;
  align-items: center;
  border-radius: 5rem;
  background-color: #ffffff;
  border: 0.1rem solid #cccccc;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  input {
    width: 20rem;
    height: 4rem;
    border: none;
    outline: none;
    color: #8492a6;
    line-height: 18;
    font-weight: 400;
    font-size: 1.4rem;
    background: #ffffff;
    transition: width 0.3s ease, color 0.3s ease;

    &::placeholder {
      color: #8492a6;
    }

    &:focus {
      width: 24rem;
      color: #8492a6;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #8492a6;
      -webkit-box-shadow: 0 0 0px 40rem #ffffff inset;
    }
  }
`;
